import { Score } from '../../../../shared/types/widget-types/response/EventsResponse';
import { IdNamePairWithLocalisation, MarketOdd, MarketOddType } from '../types';

export type CommonMessageData = {
  EId: string;
  SId: string;
  TId: string;
};

export type AltScore = {
  HS: Score['home_score'];
  AS: Score['away_score'];
};

export function mapScore(score: AltScore) {
  return {
    home_score: score.HS,
    away_score: score.AS,
  };
}

export type AltOddType = {
  OId: MarketOddType['id_oddtype'];
  S: MarketOddType['status'];
  MS: MarketOddType['manual_status'];
  O: MarketOddType['odd'];
  SV: MarketOddType['special_value'];
};

export function mapOddType(oddType: AltOddType) {
  return {
    id_oddtype: oddType.OId,
    status: oddType.S,
    manual_status: oddType.MS,
    odd: oddType.O,
    special_value: oddType.SV,
  };
}

export type AltMarket = {
  MId: MarketOdd['id_market'];
  S: MarketOdd['status'];
  OT: AltOddType[];
};

export function mapMarket(market: AltMarket) {
  return {
    id_market: market.MId,
    status: market.S,
    oddtypes: market.OT.map(mapOddType),
  };
}

export type AltIdNamePairWithLocalisation = {
  Id: IdNamePairWithLocalisation['id'];
  N: IdNamePairWithLocalisation['name'];
  Lz: IdNamePairWithLocalisation['localisation'];
};

export function mapIdNamePairWithLocalisation(pair: AltIdNamePairWithLocalisation) {
  return {
    id: pair.Id,
    name: pair.N,
    localisation: pair.Lz,
  };
}
