import { Dispatch, useCallback } from 'react';

import { Betshop } from '../../../../shared-types/graphql';
import {
  ADD_TO_BETSHOP_NAVIGATION_ITEMS,
  AddToBetshopNavigationItems,
  BetshopEditAction,
  BetshopEditActions,
  REMOVE_FROM_BETSHOP_NAVIGATION_ITEMS,
  RESORT_NAVIGATION,
  RemoveFromBetshopNavigationItems,
  ResortNavigation,
  SET_BASE_COLORS,
  SET_BETSHOP_DATA,
  SET_BETSHOP_LOGO,
  SET_BETSHOP_THEME_DATA,
  SET_BETSHOP_TITLE,
  SET_FOOTER_COLORS,
  SET_HEADER_COLORS,
  SetBaseColors,
  SetBetshopData,
  SetBetshopLogo,
  SetBetshopThemeData,
  SetBetshopTitle,
  SetFooterColors,
  SetHeaderColors,
  TOGGLE_BETSHOP_HEADER_ITEM_HIGHLIGHT,
  ToggleBetshopHeaderItemHighlight,
} from './types';

type UseBetshopEditActions = (state: Betshop, dispatch: Dispatch<BetshopEditAction>) => BetshopEditActions;
export const useBetshopEditActions: UseBetshopEditActions = (_state, dispatch) => {
  const setBetshopData: SetBetshopData = useCallback(
    betshopData => {
      dispatch({
        type: SET_BETSHOP_DATA,
        payload: betshopData,
      });
    },
    [dispatch],
  );

  const setBetshopTitle: SetBetshopTitle = useCallback(
    title => {
      dispatch({
        type: SET_BETSHOP_TITLE,
        payload: title,
      });
    },
    [dispatch],
  );

  const setBetshopThemeData: SetBetshopThemeData = useCallback(
    betshopThemeData => {
      dispatch({
        type: SET_BETSHOP_THEME_DATA,
        payload: betshopThemeData,
      });
    },
    [dispatch],
  );

  const setBaseColors: SetBaseColors = useCallback(
    baseColors => {
      dispatch({
        type: SET_BASE_COLORS,
        payload: baseColors,
      });
    },
    [dispatch],
  );

  const setHeaderColors: SetHeaderColors = useCallback(
    headerColors => {
      dispatch({
        type: SET_HEADER_COLORS,
        payload: headerColors,
      });
    },
    [dispatch],
  );

  const setFooterColors: SetFooterColors = useCallback(
    footerColors => {
      dispatch({
        type: SET_FOOTER_COLORS,
        payload: footerColors,
      });
    },
    [dispatch],
  );

  const setBetshopLogo: SetBetshopLogo = useCallback(
    logoData => {
      dispatch({
        type: SET_BETSHOP_LOGO,
        payload: logoData,
      });
    },
    [dispatch],
  );

  const addToBetshopNavigationItems: AddToBetshopNavigationItems = useCallback(
    itemData => {
      dispatch({
        type: ADD_TO_BETSHOP_NAVIGATION_ITEMS,
        payload: itemData,
      });
    },
    [dispatch],
  );

  const removeFromBetshopNavigationItems: RemoveFromBetshopNavigationItems = useCallback(
    itemData => {
      dispatch({
        type: REMOVE_FROM_BETSHOP_NAVIGATION_ITEMS,
        payload: itemData,
      });
    },
    [dispatch],
  );

  const toggleBetshopHeaderItemHighlight: ToggleBetshopHeaderItemHighlight = useCallback(
    itemData => {
      dispatch({
        type: TOGGLE_BETSHOP_HEADER_ITEM_HIGHLIGHT,
        payload: itemData,
      });
    },
    [dispatch],
  );

  const resortNavigation: ResortNavigation = useCallback(
    resortData => {
      dispatch({
        type: RESORT_NAVIGATION,
        payload: resortData,
      });
    },
    [dispatch],
  );

  return {
    setBetshopData,
    setBetshopTitle,
    setBetshopThemeData,
    setBaseColors,
    setHeaderColors,
    setFooterColors,
    setBetshopLogo,
    addToBetshopNavigationItems,
    removeFromBetshopNavigationItems,
    toggleBetshopHeaderItemHighlight,
    resortNavigation,
  };
};
