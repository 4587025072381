import { MutationHookOptions, useMutation } from '@apollo/react-hooks';

import { UPDATE_PAGES } from '../../betshop-page-edit/graphql/queries';
import { AddPage, RemovePage, UpdatePages, UpdatePagesMutation } from '../types';
import { ADD_PAGE, REMOVE_PAGE } from './queries';

type UseBetshopPagesGraphQL = (params: {
  addPageOptions?: MutationHookOptions;
  removePageOptions?: MutationHookOptions;
  updatePagesOptions?: MutationHookOptions;
}) => {
  addPage: AddPage;
  removePage: RemovePage;
  updatePages: UpdatePages;
};
export const useBetshopPagesGraphQL: UseBetshopPagesGraphQL = ({
  addPageOptions,
  removePageOptions,
  updatePagesOptions,
}) => {
  const [addPageMutation] = useMutation(ADD_PAGE, addPageOptions);
  const [removePageMutation] = useMutation(REMOVE_PAGE, removePageOptions);
  const [updatePagesMutation] = useMutation<UpdatePagesMutation>(UPDATE_PAGES, updatePagesOptions);

  const addPage: AddPage = (betshopId, name) => {
    addPageMutation({ variables: { name, betshopId } });
  };

  const removePage: RemovePage = (betshopId, slug) => {
    removePageMutation({ variables: { betshopId, slug } });
  };

  const updatePages: UpdatePages = async betshopPagesData => {
    updatePagesMutation({
      variables: { betshopPagesData: { ...betshopPagesData } },
      fetchPolicy: 'no-cache',
    });
  };

  return {
    addPage,
    removePage,
    updatePages,
  };
};
