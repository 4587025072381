import { SET_TICKET_META, TicketMetaReducer } from './types';

export const ticketMetaReducer: TicketMetaReducer = (state, action) => {
  switch (action.type) {
    case SET_TICKET_META:
      return action.payload;
    default:
      return state;
  }
};
