export enum Gender {
  Male = 'Male',
  Female = 'Female',
}
export type User = {
  id?: number;
  email: string;
  firstName: string;
  lastName: string;
  gender: Gender | null;
  nationalId: string | null;
  idCard: string | null;
  passport: string | null;
  telephone: string | null;
  birthDate: Date | null;
  idGeolocation: string | null;
  city: string | null;
  address: string | null;
  addressNum: string | null;
  zip: string | null;
  code: string | null;
  country: string | null;
  emailVerified: boolean;
};
export type Wallet = { currency: string; balance: number; freeBetBalance: number };
type GeoDataParams = {
  MAX_PAYOUT_PER_TICKET: number | null;
  MAX_DEPOSIT_FOR_MULTI_TICKET: number | null;
  MIN_DEPOSIT_FOR_MULTI_TICKET: number | null;
  MAX_DEPOSIT_FOR_SINGLE_TICKET: number | null;
  MAX_DEPOSIT_FOR_SYSTEM_TICKET: number | null;
  MIN_DEPOSIT_FOR_SINGLE_TICKET: number | null;
  MIN_DEPOSIT_FOR_SYSTEM_TICKET: number | null;
  MAX_NUM_OF_EVENTS_FOR_MULTI_TICKET: number | null;
  MAX_NUM_OF_COMBOS_FOR_SYSTEM_TICKET: number | null;
  MAX_NUM_OF_EVENTS_FOR_SYSTEM_TICKET: number | null;
  MIN_NUM_OF_EVENTS_FOR_SYSTEM_TICKET: number | null;
  MAX_DEPOSIT_PER_EVENT_FOR_MULTI_TICKET: number | null;
  MIN_DEPOSIT_PER_EVENT_FOR_MULTI_TICKET: number | null;
  MAX_DEPOSIT_PER_COMBO_FOR_SYSTEM_TICKET: number | null;
  MIN_DEPOSIT_PER_COMBO_FOR_SYSTEM_TICKET: number | null;
};
type GeoData = {
  coordinates: [number, number];
  id_location: string | null;
  params: GeoDataParams;
  id_device: number | null;
  entity_type: string;
  id_client: string;
  id_company: string;
  name: string;
  id_region: string;
  id: string;
  address: string;
  id_group: string;
  telephone_num: string;
};

export type UserData = {
  user: User;
  wallet: Wallet;
  geo: GeoData;
};

export type AuthDependency = {
  getUserData: () => void;
};
