import { Observable } from 'rxjs';

import { ActivateAlertModal, AlertModalStateSet, DeactivateAlertModal } from './alert-modal/types';
import { DeviceTypeSet, SetDeviceType } from './event-presenter/types';
import { MarketsSelected, SetSelectedMarkets } from './markets/types';
import {
  IsDeviceBlockedSet,
  IsNavigationBlockedSet,
  PreviousPageSet,
  SelectedEventSet,
  SetIsDeviceBlocked,
  SetIsNavigationBlocked,
  SetPreviousPage,
  SetSelectedEvent,
} from './navigation/types';
import { HideRegistrationModal, RegistrationModalStateSet, ShowRegistrationModal } from './registration/types';
import { LiveEventsReloaded, ReloadLiveEvents } from './sidebar-label/types';
import { ActivateSpinner, DeactivateSpinner, ResetSpinner, SpinnerStateSet } from './spinner/types';
import {
  EventsSelected,
  FavoriteLiveEventsSet,
  SetBookmarkedSports,
  SetBookmarkedTournaments,
  SetFavoriteLiveEvents,
  SetSelectedEvents,
  SetSelectedSport,
  SetSelectedSports,
  SetSelectedTournamentsBroadcast,
  SetSportFilterLiveStatus,
  SetSportsOrder,
  SportFilterLiveStatusSet,
  SportSelected,
  SportsBookmarked,
  SportsOrdered,
  SportsSelected,
  TournamentsBookmarked,
  TournamentsSelectedBroadcast,
} from './sport-filter/types';
import {
  AddToTicket,
  CashBackDisable,
  ClearMarginChanges,
  ClearTicket,
  EnableMaxButton,
  RemoveFromTicket,
  RemoveOddFromTicket,
  SetBalanceToBetAmount,
  TicketItems,
  TicketMessage,
  TicketSubmitSuccess,
  ToggleOdd,
} from './ticket-items/types';
import { MatchTrackerDataSet, SetMatchTrackerData } from './ticket-match-tracker/types';
import { SetTicketMeta, TicketMeta } from './ticket-meta/types';
import { SetTicketParams, TicketParamsSet } from './ticket-params/types';
import { TimeFilterStreamMessage } from './time-filter';
import { SetTimeFilterValue } from './time-filter/types';
import {
  SetViewSelectorFilter,
  SetViewSelectorView,
  ViewSelectorFilterSelected,
  ViewSelectorViewSelected,
} from './view-selector/types';
import { SetWebTicketItems, WebTicketItems } from './web-ticket-items/types';

export type PublisherStreamMessage =
  | SetSelectedSport
  | SetSelectedSports
  | TicketMessage
  | SetWebTicketItems
  | SetTicketMeta
  | SetTimeFilterValue
  | SetSelectedTournamentsBroadcast
  | SetBookmarkedTournaments
  | SetBookmarkedSports
  | SetSportsOrder
  | SetSportFilterLiveStatus
  | SetSelectedMarkets
  | ReloadLiveEvents
  | ActivateSpinner
  | DeactivateSpinner
  | ResetSpinner
  | SetPreviousPage
  | SetSelectedEvent
  | SetIsNavigationBlocked
  | ShowRegistrationModal
  | HideRegistrationModal
  | ActivateAlertModal
  | DeactivateAlertModal
  | SetViewSelectorView
  | SetViewSelectorFilter
  | SetFavoriteLiveEvents
  | SetTicketParams
  | SetSelectedEvents
  | SetMatchTrackerData
  | CashBackDisable
  | SetIsDeviceBlocked
  | SetBalanceToBetAmount
  | EnableMaxButton
  | SetDeviceType;

export type ConsumerStreamMessage =
  | SportSelected
  | SportsSelected
  | AddToTicket
  | RemoveFromTicket
  | ClearTicket
  | TicketSubmitSuccess
  | TicketItems
  | WebTicketItems
  | TicketMeta
  | TimeFilterStreamMessage
  | TournamentsSelectedBroadcast
  | TournamentsBookmarked
  | SportsBookmarked
  | SportsOrdered
  | MarketsSelected
  | LiveEventsReloaded
  | SpinnerStateSet
  | PreviousPageSet
  | SelectedEventSet
  | IsNavigationBlockedSet
  | RegistrationModalStateSet
  | AlertModalStateSet
  | SportFilterLiveStatusSet
  | ViewSelectorViewSelected
  | ViewSelectorFilterSelected
  | FavoriteLiveEventsSet
  | TicketParamsSet
  | EventsSelected
  | MatchTrackerDataSet
  | ToggleOdd
  | RemoveOddFromTicket
  | ClearMarginChanges
  | IsDeviceBlockedSet
  | CashBackDisable
  | SetBalanceToBetAmount
  | EnableMaxButton
  | DeviceTypeSet;

export type Consume<StreamType> = Observable<StreamType>;
export type Publish = (message: PublisherStreamMessage) => void;
export type Messenger<StreamType> = { consume: Consume<StreamType>; publish: Publish };

export const BROADCAST_MESSAGE = 'BROADCAST_MESSAGE';
