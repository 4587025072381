import { OperatorFunction, merge, of } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { environment } from '../../shared/environment';

export const catchAndLogError = <T>(
  label: string,
  defaultValue: T,
  reconnectFunction?: Function,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): OperatorFunction<any, T> =>
  catchError((e, caught) => {
    console.error(`--- ${label} --- `);
    console.error(e);
    if (reconnectFunction) {
      reconnectFunction(environment.game, 'SessionExpired');
    }

    return merge(of(defaultValue), caught);
  });
