import { BetshopType } from '../../../shared-types/BetshopType';
import { MARKET_STATUS, ODD_TYPE_STATUS } from '../../../store/dependencies/socket/types';

export enum OddTrend {
  Neutral = 'neutral',
  Up = 'Up',
  Down = 'Down',
}
export type OddType = {
  id: string;
  name: string;
  order: number;
  description: string;
  value: number;
  specialValue?: number | string;
};

export type ExtendedOddType = OddType & {
  status: ODD_TYPE_STATUS;
  manual_status: MARKET_STATUS | null;
  trend?: OddTrend;
};

export type OddTypes = {
  [oddTypeId: string]: ExtendedOddType;
};

export type OddTypesWithStatus = {
  [oddTypeId: string]: ExtendedOddType;
};

export type Market = {
  id: string;
  name: string;
  order: number;
  default: boolean;
  hasBoundaries: boolean | null;
  splitTypes: boolean | null;
  live: boolean;
  pre: boolean;
  oddtypes: OddTypesWithStatus;
};

export type MarketWithStatus = Market & {
  status: MARKET_STATUS;
  allowedForCashout?: boolean;
};

export type EventPresenterMarket = Omit<MarketWithStatus, 'oddtypes'> & {
  oddtypes: ExtendedOddType[];
};

export type SportMarkets = {
  [marketId: string]: Market;
};

export type SportMarketsAndFavorites = {
  markets: SportMarkets;
  favorites: {
    prematch: string[];
    live: string[];
  };
};

export type SportMarketsWithStatus = {
  [marketId: string]: MarketWithStatus;
};

export type Markets = {
  type: BetshopType;
  sports: { [sportId: string]: SportMarketsAndFavorites };
};

export type OddEvenMarkets = { odd: MarketWithStatus[]; even: MarketWithStatus[] };
