import { FC } from 'react';
import HelmetProvider from 'react-navi-helmet-async';

import { AuthContextProvider } from '../../features/authentication/state/auth/context';
import { UserContextProvider } from '../../features/authentication/state/user/context';
import { BetshopContextProvider } from '../../features/betshop/context';
import { ConfigContextProvider } from '../../features/config/state/context';
import { DnDContextProvider } from '../../features/dnd/state/context';
import RouterContextProvider from '../../routing/context';
import { combineComponents } from '../../utils/combineComponents';
import { environment } from '../environment';
import ApolloContextProvider from './ApolloContextProvider';
import ToastProvider from './ToastContextProvider';
import { UIContextProvider } from './ui/context';

const cmsProviders: FC[] = [
  DnDContextProvider,
  HelmetProvider as FC,
  UIContextProvider,
  ConfigContextProvider,
  RouterContextProvider,
  ToastProvider,
  AuthContextProvider,
  ApolloContextProvider,
  UserContextProvider,
  BetshopContextProvider,
];

const betshopProviders: FC[] = [
  HelmetProvider as FC,
  ConfigContextProvider,
  RouterContextProvider,
  AuthContextProvider,
];

const providers = environment.isCms ? cmsProviders : betshopProviders;

export const AppContextProvider = combineComponents(...providers);
