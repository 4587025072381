import axios from 'axios';
import fileDownload from 'js-file-download';
import React, { FC, createContext, useCallback, useContext, useEffect, useReducer, useState } from 'react';
import { useToasts } from 'react-toast-notifications';
import openSocket from 'socket.io-client';

import { Betshop } from '../../../../shared-types/graphql';
import { environment } from '../../../../shared/environment';
import {
  getValueForKeyInBrowserStorage,
  removeFromBrowserStorage,
  storeInBrowserStorage,
} from '../../../../utils/browserStorage';
import { useBetshopBuildActions } from './actions';
import { betshopBuildInitialState, betshopBuildReducer } from './reducer';
import { BetshopBuildState, BuildNotification, OnBuildNotification } from './types';

type PublishBetshop = (betshop: Betshop) => Promise<void>;

export type BetshopBuildContext = {
  state: BetshopBuildState;
  publish: PublishBetshop;
};

const BetshopBuildContext = createContext({} as BetshopBuildContext);

export const useBetshopBuildContext = () => useContext(BetshopBuildContext);
export const BetshopBuildContextProvider: FC = ({ children }) => {
  const [state, dispatch] = useReducer(betshopBuildReducer, betshopBuildInitialState);
  const { addToOpenedRooms, removeFromOpenedRooms } = useBetshopBuildActions(state, dispatch);
  const [socket] = useState(openSocket(environment.apiUrl));
  const { addToast } = useToasts();

  const getFile = async (fileName: string) => {
    return axios({
      method: 'GET',
      url: `${environment.apiUrl}/betshop/zip/${fileName}`,
      responseType: 'blob',
    });
  };

  const removeSocketListener = useCallback(
    (roomName: string) => {
      socket.removeListener(roomName);
      removeFromOpenedRooms(roomName);
      removeFromBrowserStorage('publishRoom');
    },
    [removeFromOpenedRooms, socket],
  );

  const subscribeToPublishRoom = useCallback(
    (roomName: string, zipName: string) => {
      socket.on(roomName, async (notification: BuildNotification) => {
        if (notification.done) {
          const { data } = await getFile(notification.message);

          fileDownload(data, `${zipName}.zip`);
          removeSocketListener(roomName);

          return;
        }

        addToast(notification.message, {
          appearance: notification.failed ? 'error' : 'info',
        });
      });
    },
    [addToast, removeSocketListener, socket],
  );

  const onBuildNotification: OnBuildNotification = (roomName, zipName) => {
    if (state.openedRooms.includes(roomName)) {
      return;
    }
    storeInBrowserStorage('publishRoom', [roomName, zipName]);
    addToOpenedRooms(roomName);
    subscribeToPublishRoom(roomName, zipName);
  };

  useEffect(() => {
    const publishRoom: [string, string] | null = getValueForKeyInBrowserStorage('publishRoom');
    if (publishRoom?.length === 2) {
      subscribeToPublishRoom(...publishRoom);
      addToOpenedRooms(publishRoom[0]);
    }
  }, [addToOpenedRooms, subscribeToPublishRoom]);

  const publish: PublishBetshop = async betshop => {
    addToast('forge.publishStarted', { appearance: 'info' });
    onBuildNotification(betshop._id, betshop.name);

    try {
      await axios({
        method: 'POST',
        url: `${environment.apiUrl}/betshop/publish`,
        data: {
          betshopId: betshop._id,
        },
        headers: { 'Content-Type': 'application/json' },
      });
    } catch (error) {
      addToast('forge.publishFailed', { appearance: 'error' });
    }
  };

  return (
    <BetshopBuildContext.Provider
      value={{
        state,
        publish,
      }}
    >
      {children}
    </BetshopBuildContext.Provider>
  );
};
