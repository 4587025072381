import { Dispatch, useEffect, useMemo } from 'react';
import { useLazyQuery, useMutation } from 'react-apollo';
import { useCurrentRoute, useNavigation } from 'react-navi';

import { UpdateBetshopInput } from '../../../../graphql';
import { cmsDashboardRoute } from '../../../../routing/routes/cms';
import { Betshop } from '../../../../shared-types/graphql';
import { ReFetchQuery } from '../../../../shared/types/RefetchQuery';
import { useBetshopEditActions } from '../state/actions';
import { BetshopEditAction, BetshopEditActions } from '../state/types';
import { UpdateBetshopLogo } from '../types';
import { GET_BETSHOP, UPDATE_BETSHOP, UPLOAD_LOGO } from './queries';

type GetBetshop = {
  betshop: Betshop;
};

type UpdateBetshop = {
  updateBetshop: Betshop;
};

type UploadLogo = {
  uploadLogo: {
    logo: string;
  };
};

type UpdateBetshopAndRefetch = (betshopData: Partial<UpdateBetshopInput>) => void;

export type BetshopEditActionsWithGql = {
  updateBetshopAndRefetch: UpdateBetshopAndRefetch;
  updateBetshopLogo: UpdateBetshopLogo;
  refetchBetshop: ReFetchQuery;
} & BetshopEditActions;

type UseBetshopGraphQL = (state: Betshop, dispatch: Dispatch<BetshopEditAction>) => BetshopEditActionsWithGql;
export const useBetshopGraphQL: UseBetshopGraphQL = (state, dispatch) => {
  const {
    lastChunk: { request },
  } = useCurrentRoute();

  const betshopId = useMemo(() => request && request.params.id, [request]);

  const {
    setBetshopData,
    setBetshopTitle,
    setBetshopThemeData,
    setBaseColors,
    setHeaderColors,
    setFooterColors,
    setBetshopLogo,
    addToBetshopNavigationItems,
    removeFromBetshopNavigationItems,
    toggleBetshopHeaderItemHighlight,
    resortNavigation,
  } = useBetshopEditActions(state, dispatch);

  const refetchBetshop: ReFetchQuery = useMemo(
    () => ({
      refetchQueries: [{ query: GET_BETSHOP, variables: { _id: betshopId } }],
      awaitRefetchQueries: true,
    }),
    [betshopId],
  );

  const [getBetshop, { data }] = useLazyQuery<GetBetshop>(GET_BETSHOP, {
    fetchPolicy: 'network-only',
  });
  const [updateBetshop] = useMutation<UpdateBetshop>(UPDATE_BETSHOP, refetchBetshop);
  const [uploadLogo, { data: updateBetshopLogoData }] = useMutation<UploadLogo>(UPLOAD_LOGO);
  const { navigate } = useNavigation();

  useEffect(() => {
    betshopId && getBetshop({ variables: { _id: betshopId } });
  }, [betshopId, getBetshop]);

  useEffect(() => {
    if (data) {
      data.betshop ? setBetshopData(data.betshop) : navigate(cmsDashboardRoute.path);
    }
  }, [data, setBetshopData, navigate]);

  useEffect(() => {
    setBetshopLogo({
      logo: (updateBetshopLogoData && updateBetshopLogoData.uploadLogo.logo) || '',
    });
  }, [setBetshopLogo, updateBetshopLogoData]);

  const updateBetshopAndRefetch: UpdateBetshopAndRefetch = async betshopData => {
    updateBetshop({ variables: { betshopData: { ...betshopData } } });
  };

  const updateBetshopLogo: UpdateBetshopLogo = async (betshopId, file) => {
    uploadLogo({ variables: { betshopId, file } });
  };

  return {
    setBetshopData,
    setBetshopTitle,
    setBetshopThemeData,
    setBaseColors,
    setHeaderColors,
    setFooterColors,
    setBetshopLogo,
    addToBetshopNavigationItems,
    removeFromBetshopNavigationItems,
    toggleBetshopHeaderItemHighlight,
    updateBetshopAndRefetch,
    updateBetshopLogo,
    refetchBetshop,
    resortNavigation,
  };
};
