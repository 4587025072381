import { useCallback } from 'react';

import { removeFromBrowserStorage } from '../../../../utils/browserStorage';
import { AuthBrowserStorageKeys } from '../../types';
import { CLEAR_USER, SET_USER, SetUser, UseUserActions } from './types';

export const useUserActions: UseUserActions = (_state, dispatch) => {
  const setUser: SetUser = useCallback(
    (user): void => {
      dispatch({
        type: SET_USER,
        payload: user,
      });
    },
    [dispatch],
  );

  const clearUser = useCallback((): void => {
    removeFromBrowserStorage(AuthBrowserStorageKeys.token);
    removeFromBrowserStorage(AuthBrowserStorageKeys.refreshToken);
    dispatch({
      type: CLEAR_USER,
    });
  }, [dispatch]);

  return { setUser, clearUser };
};
