import React, { FC, createContext, useContext, useReducer } from 'react';

import { UIActions, useUIActions } from './actions';
import { initalUIState, uiReducer } from './reducer';
import { UIContextState } from './types';

type UIContext = UIActions & UIContextState;
const UIContext = createContext({} as UIContext);

export const useUIContext = () => useContext(UIContext);

export const UIContextProvider: FC = ({ children }) => {
  const [state, dispatch] = useReducer(uiReducer, initalUIState);
  const uiActions = useUIActions(state, dispatch);

  return <UIContext.Provider value={{ ...state, ...uiActions }}>{children}</UIContext.Provider>;
};
