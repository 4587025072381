import { ActionWithIdentity } from '../../../../shared/types/ActionWithIdentity';

export const SET_TICKET_PARAMS = 'SET_TICKET_PARAMS';
export const TICKET_PARAMS_SET = 'TICKET_PARAMS_SET';

export type TicketParams = {
  MAX_PAYOUT_PER_TICKET: number;
  MAX_PAYOUT_PER_LIVE_TICKET: number;
  MAX_DEPOSIT_FOR_MULTI_TICKET: number;
  MIN_DEPOSIT_FOR_MULTI_TICKET: number;
  MAX_DEPOSIT_FOR_SINGLE_TICKET: number;
  MAX_DEPOSIT_FOR_SYSTEM_TICKET: number;
  MAX_LIVE_DEPOSIT_FOR_MULTI_TICKET: number;
  MAX_LIVE_DEPOSIT_FOR_SINGLE_TICKET: number;
  MAX_LIVE_DEPOSIT_FOR_SYSTEM_TICKET: number;
  MAX_NUM_OF_EVENTS_FOR_MULTI_TICKET: number;
  MAX_NUM_OF_COMBOS_FOR_SYSTEM_TICKET: number;
  MAX_NUM_OF_EVENTS_FOR_SYSTEM_TICKET: number;
  MIN_DEPOSIT_FOR_SINGLE_TICKET: number;
  MAX_DEPOSIT_PER_EVENT_FOR_MULTI_TICKET: number;
  MAX_DEPOSIT_PER_COMBO_FOR_SYSTEM_TICKET: number;
  MAX_LIVE_NUM_OF_EVENTS_FOR_MULTI_TICKET: number;
  MAX_LIVE_NUM_OF_COMBOS_FOR_SYSTEM_TICKET: number;
  MAX_LIVE_DEPOSIT_PER_EVENT_FOR_MULTI_TICKET: number;
  MAX_LIVE_NUM_OF_EVENTS_FOR_SYSTEM_TICKET: number;
  MAX_LIVE_DEPOSIT_PER_COMBO_FOR_SYSTEM_TICKET: number;
  MIN_DEPOSIT_FOR_SYSTEM_TICKET: number;
  MIN_LIVE_DEPOSIT_FOR_MULTI_TICKET: number;
  MIN_LIVE_DEPOSIT_FOR_SINGLE_TICKET: number;
  MIN_LIVE_DEPOSIT_FOR_SYSTEM_TICKET: number;
  MIN_NUM_OF_EVENTS_FOR_SYSTEM_TICKET: number;
  MIN_DEPOSIT_PER_EVENT_FOR_MULTI_TICKET: number;
  MIN_DEPOSIT_PER_COMBO_FOR_SYSTEM_TICKET: number;
  MIN_LIVE_NUM_OF_EVENTS_FOR_SYSTEM_TICKET: number;
  MIN_LIVE_DEPOSIT_PER_EVENT_FOR_MULTI_TICKET: number;
  MIN_LIVE_DEPOSIT_PER_COMBO_FOR_SYSTEM_TICKET: number;
  PAY_IN_TAX_LEVELS: string;
  DEVICE_TYPE: DeviceType;
  CURRENCY: string;
} | null;

export enum DeviceType {
  PRESENTATION = 0,
  POS = 1,
  TERMINAL = 2,
  E_TERMINAL = 3,
  WEB = 10,
}

export type TicketParamsState = TicketParams;

export type SetTicketParams = ActionWithIdentity<typeof SET_TICKET_PARAMS, TicketParamsState>;
export type TicketParamsSet = ActionWithIdentity<typeof TICKET_PARAMS_SET, TicketParamsState>;

type TicketParamsAction = SetTicketParams;

export type TicketParamsReducer = (state: TicketParamsState, action: TicketParamsAction) => TicketParamsState;
