import { ActionWithIdentity } from '../../../../shared/types/ActionWithIdentity';
import { TicketItem } from '../../../../shared/types/widget-types/TicketItem';
import { TicketItems as WebTicketItems } from '../../../../themes/maxbet/widgets/Ticket/store/types';
import { TOGGLE_ODD } from '../../../../themes/orion-tip/widgets/Ticket/store/types';

export const ADD_TO_TICKET = 'ADD_TO_TICKET';
export const REMOVE_FROM_TICKET = 'REMOVE_FROM_TICKET';
export const TOGGLE_ODD_TYPE = 'TOGGLE_ODD_TYPE';
export const SET_TICKET_ITEMS = 'SET_TICKET_ITEMS';
export const SET_WEB_TICKET_ITEMS = 'SET_WEB_TICKET_ITEMS';
export const TICKET_ITEMS = 'TICKET_ITEMS';
export const CLEAR_TICKET = 'CLEAR_TICKET';
export const TICKET_SUBMIT_SUCCESS = 'TICKET_SUBMIT_SUCCESS';
export const REMOVE_ODD_FROM_TICKET = 'REMOVE_ODD_FROM_TICKET';
export const CLEAR_MARGIN_CHANGES = 'CLEAR_MARGIN_CHANGES';
export const CASHBACK_DISABLE = 'CASHBACK_DISABLE';
export const SET_BALANCE_TO_BET_AMOUNT = 'SET_BALANCE_TO_BET_AMOUNT';
export const ENABLE_MAX_BUTTON = 'ENABLE_MAX_BUTTON';

export type TicketItemsStreamState = TicketItem[];
export type AddToTicket = ActionWithIdentity<typeof ADD_TO_TICKET, TicketItem>;
export type RemoveFromTicket = ActionWithIdentity<typeof REMOVE_FROM_TICKET, TicketItem>;
export type ToggleOddType = ActionWithIdentity<typeof TOGGLE_ODD_TYPE, TicketItem>;
export type ClearTicket = ActionWithIdentity<typeof CLEAR_TICKET, null>;
export type TicketSubmitSuccess = ActionWithIdentity<typeof TICKET_SUBMIT_SUCCESS, typeof TICKET_SUBMIT_SUCCESS>; // forcing non-empty string because of stream message filtering
export type SetTicketItems = ActionWithIdentity<typeof SET_TICKET_ITEMS, TicketItem[]>;
export type SetWebTicketItems = ActionWithIdentity<typeof SET_WEB_TICKET_ITEMS, WebTicketItems>;
export type TicketItems = ActionWithIdentity<typeof TICKET_ITEMS, TicketItem[]>;
export type ToggleOdd = ActionWithIdentity<typeof TOGGLE_ODD, TicketItem>;
export type RemoveOddFromTicket = ActionWithIdentity<typeof REMOVE_ODD_FROM_TICKET, TicketItem>;
export type ClearMarginChanges = ActionWithIdentity<typeof CLEAR_MARGIN_CHANGES>;
export type CashBackDisable = ActionWithIdentity<typeof CASHBACK_DISABLE, null>;
export type SetBalanceToBetAmount = ActionWithIdentity<typeof SET_BALANCE_TO_BET_AMOUNT>;
export type EnableMaxButton = ActionWithIdentity<typeof ENABLE_MAX_BUTTON>;

export type TicketMessage =
  | AddToTicket
  | RemoveFromTicket
  | ToggleOddType
  | ClearTicket
  | SetTicketItems
  | TicketSubmitSuccess
  | ToggleOdd
  | RemoveOddFromTicket
  | ClearMarginChanges
  | CashBackDisable
  | SetBalanceToBetAmount
  | EnableMaxButton;

export type TicketItemsReducer = (state: TicketItemsStreamState, action: TicketMessage) => TicketItemsStreamState;
