import {
  CLEAR_WIDGET_FROM_SETTINGS,
  PageWidgetsReducer,
  PageWidgetsState,
  SET_ALL_PAGE_WIDGETS,
  SET_WIDGET_FOR_SETTINGS,
} from './types';

export const initialPageWidgetsState: PageWidgetsState = {
  widget: null,
  positionInfo: null,
  allWidgets: [],
  widgetCanConnectWith: [],
};

export const pageWidgetsReducer: PageWidgetsReducer = (state, action) => {
  switch (action.type) {
    case SET_WIDGET_FOR_SETTINGS:
      return {
        ...state,
        ...action.payload,
        widgetCanConnectWith: state.allWidgets.filter(w =>
          action.payload.widget.settings.canBeConnectedWith?.includes(w.component),
        ),
      };
    case CLEAR_WIDGET_FROM_SETTINGS:
      return { ...initialPageWidgetsState, allWidgets: state.allWidgets };
    case SET_ALL_PAGE_WIDGETS:
      return { ...state, allWidgets: action.payload };
    default:
      return state;
  }
};
