import { Dispatch, useCallback } from 'react';

import { Betshop } from '../../../../shared-types/graphql';
import { BetshopListingAction, BetshopListingState, SET_BETSHOPS } from './types';

export type SetBetshops = (betshops: Betshop[]) => void;
type BetshopListingActions = {
  setBetshops: SetBetshops;
};

type UseBetshopListingActions = (
  state: BetshopListingState,
  dispatch: Dispatch<BetshopListingAction>,
) => BetshopListingActions;
export const useBetshopListingActions: UseBetshopListingActions = (_state, dispatch) => {
  const setBetshops: SetBetshops = useCallback(
    (betshops): void => {
      dispatch({
        type: SET_BETSHOPS,
        payload: betshops,
      });
    },
    [dispatch],
  );

  return { setBetshops };
};
