import { ActionWithIdentity } from '../../../../shared/types/ActionWithIdentity';

export const SET_PREVIOUS_PAGE = 'SET_PREVIOUS_PAGE';
export const SET_SELECTED_EVENT = 'SET_SELECTED_EVENT';
export const SET_IS_NAVIGATION_BLOCKED = 'SET_IS_NAVIGATION_BLOCKED';
export const SET_IS_DEVICE_BLOCKED = 'SET_IS_DEVICE_BLOCKED';

export const PREVIOUS_PAGE_SET = 'PREVIOUS_PAGE_SET';
export const SELECTED_EVENT_SET = 'SELECTED_EVENT_SET';
export const IS_NAVIGATION_BLOCKED_SET = 'IS_NAVIGATION_BLOCKED_SET';
export const IS_DEVICE_BLOCKED_SET = 'IS_DEVICE_BLOCKED_SET';

export type SelectedEvent = {
  eventId: string;
  sportId: string;
  hasLiveTracker: boolean;
};

export type NavigationState = {
  previousPage: string | null;
  selectedEvent: SelectedEvent | null;
  isNavigationBlocked: boolean;
  isDeviceBlocked: boolean;
};
export const initialNavigationState: NavigationState = {
  previousPage: null,
  selectedEvent: null,
  isNavigationBlocked: false,
  isDeviceBlocked: false,
};

type NavigationMessage<T> = ActionWithIdentity<T, NavigationState>;

export type SetPreviousPage = ActionWithIdentity<typeof SET_PREVIOUS_PAGE, NavigationState['previousPage']>;
export type SetSelectedEvent = ActionWithIdentity<typeof SET_SELECTED_EVENT, NavigationState['selectedEvent']>;
export type SetIsNavigationBlocked = ActionWithIdentity<
  typeof SET_IS_NAVIGATION_BLOCKED,
  NavigationState['isNavigationBlocked']
>;
export type SetIsDeviceBlocked = ActionWithIdentity<typeof SET_IS_DEVICE_BLOCKED, NavigationState['isDeviceBlocked']>;

export type PreviousPageSet = NavigationMessage<typeof PREVIOUS_PAGE_SET>;
export type SelectedEventSet = NavigationMessage<typeof SELECTED_EVENT_SET>;
export type IsNavigationBlockedSet = NavigationMessage<typeof IS_NAVIGATION_BLOCKED_SET>;
export type IsDeviceBlockedSet = NavigationMessage<typeof IS_DEVICE_BLOCKED_SET>;

type NavigationAction = SetPreviousPage | SetSelectedEvent | SetIsNavigationBlocked | SetIsDeviceBlocked;

export type NavigationReducer = (state: NavigationState, action: NavigationAction) => NavigationState;
