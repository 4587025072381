import { SET_TIME_FILTER_VALUE, TimeFilterReducer, TimeFilterState } from './types';

export const initialTimeFilterMessage: TimeFilterState = {} as TimeFilterState;

export const timeFilterReducer: TimeFilterReducer = (state, action) => {
  switch (action.type) {
    case SET_TIME_FILTER_VALUE:
      return {
        ...state,
        [action.identity]: {
          ...(state[action.identity] ? state[action.identity] : {}),
          timeFilterValue: action.payload,
        },
      };
    default:
      return state;
  }
};
