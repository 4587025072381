import { ReactNode } from 'react';

import { ActionWithIdentity } from '../../../../shared/types/ActionWithIdentity';

export const ACTIVATE_ALERT_MODAL = 'ACTIVATE_ALERT_MODAL';
export const DEACTIVATE_ALERT_MODAL = 'DEACTIVATE_ALERT_MODAL';
export const ALERT_MODAL_STATE_SET = 'ALERT_MODAL_STATE_SET';

export type AlertModalOptions = { isStatistics?: boolean; shouldStayOpen?: boolean };

export type AlertModalState = {
  message: AlertModalMessage;
  options: AlertModalOptions;
};

export type ActivateAlertModal = ActionWithIdentity<
  typeof ACTIVATE_ALERT_MODAL,
  { message: AlertModalMessage; options: AlertModalOptions }
>;
export type DeactivateAlertModal = ActionWithIdentity<typeof DEACTIVATE_ALERT_MODAL, null>;
export type AlertModalStateSet = ActionWithIdentity<typeof ALERT_MODAL_STATE_SET, AlertModalState>;

type AlertModalAction = ActivateAlertModal | DeactivateAlertModal;

export type AlertModalReducer = (state: AlertModalState, action: AlertModalAction) => AlertModalState;

export type AlertModalMessage = string | ReactNode | null;
