import { useRef } from 'react';
import { applyMiddleware } from 'redux';
import { createStore } from 'redux-dynamic-modules-core';
import { getObservableExtension } from 'redux-dynamic-modules-observable';
import { createEpicMiddleware } from 'redux-observable';

import { environment } from '../shared/environment';
import asm from './dependencies/app-messaging';
import http from './dependencies/http';
import locale from './dependencies/locale';
import marketsDependency from './dependencies/markets';
import settings from './dependencies/settings';
import socketDependency, {
  createSocketConnection,
  listenEvents,
  listenJackpot,
  listenTicket,
} from './dependencies/socket';
import { SOCKET_ACTIONS, SOCKET_MESSAGE_TYPE } from './dependencies/socket/types';
import userDependency from './dependencies/user';
import worker from './dependencies/worker';
import { StoreDependencies } from './types';

function onStopBetting(message) {
  const stopBettingText = {
    en: 'Betting is currently disabled',
    sk: 'Stávkovanie je momentálne zakázané',
    sr: 'Klađenje je trenutno onemogućeno',
    ba: 'Klađenje je trenutno onemogućeno',
  };

  let modal: HTMLDivElement;

  if (message.data.blockBetting) {
    const existingModal = document.querySelector('#stop_betting');
    if (existingModal) return;
    const language = localStorage.getItem('i18nextLng') || 'en';
    modal = document.createElement('div');
    modal.id = 'stop_betting';
    modal.classList.add('stop-betting-modal');
    modal.textContent = stopBettingText[language] || stopBettingText.en;
    document.body.appendChild(modal);
  } else {
    const existingModal = document.querySelector('#stop_betting');
    existingModal && document.body.removeChild(existingModal);
  }
}

const markets = marketsDependency(http, locale, settings);
const user = userDependency(http);
const socket = socketDependency(
  settings,
  provider => {
    const url = environment.socketUrls[provider];
    console.log(`Events socket url: ${url}`);

    const socket = createSocketConnection(url);

    socket.emit(SOCKET_ACTIONS.LEAVE, 'alea.betting');
    socket.removeEventListener('stop_betting', onStopBetting);
    socket.emit(SOCKET_ACTIONS.JOIN, 'alea.betting');
    socket.on('stop_betting', onStopBetting);

    return socket;
  },
  listenEvents,
  [
    SOCKET_MESSAGE_TYPE.NEW_EVENT,
    SOCKET_MESSAGE_TYPE.EVENT_CHANGE,
    SOCKET_MESSAGE_TYPE.EVENT_CHANGE_LIVE,
    SOCKET_MESSAGE_TYPE.EVENT_CHANGE_PREMATCH,
    SOCKET_MESSAGE_TYPE.ODDS_CHANGE,
    SOCKET_MESSAGE_TYPE.BET_ODDS,
    SOCKET_MESSAGE_TYPE.EVENT_RESULT,
    SOCKET_MESSAGE_TYPE.SPORT_FILTER_UPDATE,
    SOCKET_MESSAGE_TYPE.STOP_BETTING,
  ],
);

const ticketSocket = socketDependency(
  settings,
  provider => {
    const url = environment.ticketSocketUrls[provider] || environment.socketUrls[provider];
    console.log(`Ticket socket url: ${url}`);
    return createSocketConnection(url);
  },
  listenTicket,
  [
    SOCKET_MESSAGE_TYPE.INVALID,
    SOCKET_MESSAGE_TYPE.PLACED,
    SOCKET_MESSAGE_TYPE.REFUNDED,
    SOCKET_MESSAGE_TYPE.WINNING,
    SOCKET_MESSAGE_TYPE.REQUESTED,
    SOCKET_MESSAGE_TYPE.LOSING,
    SOCKET_MESSAGE_TYPE.CASHOUT,
    SOCKET_MESSAGE_TYPE.DIGITAIN_MAX_WIN_AMOUNT,
    SOCKET_MESSAGE_TYPE.DIGITAIN_LAST_TICKETS,
    SOCKET_MESSAGE_TYPE.LAST_TICKETS,
    SOCKET_MESSAGE_TYPE.WITHDRAWN,
  ],
);

const jackpotSocket = socketDependency(
  settings,
  provider => {
    const url = environment.socketUrls[provider];
    console.log(`Jackpot socket url: ${url}`);

    const socket = createSocketConnection(url);
    socket.emit(SOCKET_ACTIONS.LEAVE, 'alea.jackpot');
    socket.emit(SOCKET_ACTIONS.JOIN, 'alea.jackpot');

    return socket;
  },
  listenJackpot,
  [SOCKET_MESSAGE_TYPE.JACKPOT_CHANGE, SOCKET_MESSAGE_TYPE.JACKPOT_WON],
);

export const dependencies: StoreDependencies = {
  asm,
  http,
  locale,
  settings,
  worker,
  socket,
  ticketSocket,
  jackpotSocket,
  markets,
  user,
};

const epicMiddleware = createEpicMiddleware({ dependencies });

export const store = createStore({
  enhancers: [applyMiddleware(epicMiddleware)],
  extensions: [getObservableExtension()],
});

export const useStore = () => {
  return useRef(store);
};
