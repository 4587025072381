import { mapIdNamePairWithLocalisation } from './common';
import { AltNewEventMessage, mapSocketMessage } from './map-socket-message';

function mapTournament(message: AltNewEventMessage) {
  return {
    ...mapIdNamePairWithLocalisation(message.data.To),
    order: message.data.To.O,
  };
}

export function mapNewEvent(message: AltNewEventMessage) {
  return {
    ...mapSocketMessage(message),
    data: {
      id_sport: message.data.SId,
      id_tournament: message.data.TId,
      id_event: message.data.EId,
      active: message.data.A,
      current_time: message.data.CT,
      remaining_time: message.data.ReT,
      time_stopped: message.data.TS,
      stoppage_time: message.data.StT,
      live_status: message.data.LS,
      period: message.data.CP,
      order: message.data.O,
      bet_count: message.data.BC,
      status: message.data.S,
      listCode: message.data.LC,
      utc_scheduled: message.data.UTC,
      live: message.data.L,
      active_market_count: message.data.AMC,
      sport: mapIdNamePairWithLocalisation(message.data.Sp),
      category: mapIdNamePairWithLocalisation(message.data.Ca),
      tournament: mapTournament(message),
      competitors: message.data.Co.map(mapIdNamePairWithLocalisation),
      live_provider_data: message.data.PD,
    },
  };
}
