import { KeyObjectPair } from '../shared/types/widget-types/KeyObjectPair';

type GroupBy = <T>(arr: T[], getter: (e: T) => string) => { [key: string]: T[] };
export const groupBy: GroupBy = (arr, getter) =>
  arr
    .map(val => getter(val))
    .reduce((acc, val, i) => {
      acc[val] = (acc[val] || []).concat(arr[i]);
      return acc;
    }, {});

export function groupIntoArrayOfKeyObjectPairsBy<T>(
  array: T[],
  groupingGetter: (e: T) => string,
  objectKeyGetter: (e: T) => string,
): KeyObjectPair<T>[] {
  return array
    .map(value => groupingGetter(value))
    .reduce((arrayOfPairs, key, indexOfValue) => {
      const indexOfPair = arrayOfPairs.findIndex(([tuppleKey]) => tuppleKey === key);
      if (indexOfPair >= 0) {
        const [pairKey, pairValue] = arrayOfPairs[indexOfPair];
        const item = array[indexOfValue];
        arrayOfPairs[indexOfPair] = [pairKey, { ...pairValue, [objectKeyGetter(item)]: item }];
      } else {
        const item = array[indexOfValue];
        arrayOfPairs.push([key, { [objectKeyGetter(item)]: item }]);
      }
      return arrayOfPairs;
    }, [] as KeyObjectPair<T>[]);
}
