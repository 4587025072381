import { SET_WEB_TICKET_ITEMS, WebTicketItemsReducer } from './types';

export const webTicketItemsReducer: WebTicketItemsReducer = (state, action) => {
  switch (action.type) {
    case SET_WEB_TICKET_ITEMS:
      return action.payload;
    default:
      return state;
  }
};
