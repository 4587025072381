import { WidgetGraphql } from '../../../../graphql';
import { ContextAction } from '../../../../shared/types/ContextAction';
import { ContextReducer } from '../../../../shared/types/ContextReducer';
import { DropAreaInfo } from './context';

export const SET_WIDGET_FOR_SETTINGS = 'SET_WIDGET_FOR_SETTINGS';
export const CLEAR_WIDGET_FROM_SETTINGS = 'CLEAR_WIDGET_FROM_SETTINGS';
export const SET_ALL_PAGE_WIDGETS = 'SET_ALL_PAGE_WIDGETS';

export type PageWidgetsState = {
  widget: WidgetGraphql | null;
  positionInfo: DropAreaInfo | null;
  allWidgets: WidgetGraphql[];
  widgetCanConnectWith: WidgetGraphql[];
};

export type SetWidgetForSettingsActionPayload = {
  widget: WidgetGraphql;
  positionInfo: DropAreaInfo;
};
export type SetWidgetForSettingsAction = ContextAction<
  typeof SET_WIDGET_FOR_SETTINGS,
  SetWidgetForSettingsActionPayload
>;
export type ClearWidgetFromSettingsAction = ContextAction<typeof CLEAR_WIDGET_FROM_SETTINGS, undefined>;
export type SetAllPageWidgets = ContextAction<typeof SET_ALL_PAGE_WIDGETS, WidgetGraphql[]>;

export type PageWidgetsAction = SetWidgetForSettingsAction | ClearWidgetFromSettingsAction | SetAllPageWidgets;

export type PageWidgetsReducer = ContextReducer<PageWidgetsState, PageWidgetsAction>;
