import { BehaviorSubject } from 'rxjs';

import { BROADCAST_MESSAGE } from '../types';
import { SPINNER_STATE_SET, SpinnerState, SpinnerStateSet } from './types';

export const initialSpinnerMessage: SpinnerState = { activeWidgetsIds: [] };

export type SpinnerStream = BehaviorSubject<SpinnerStateSet>;
export const SpinnerStream: SpinnerStream = new BehaviorSubject<SpinnerStateSet>({
  type: SPINNER_STATE_SET,
  payload: initialSpinnerMessage,
  identity: BROADCAST_MESSAGE,
});
