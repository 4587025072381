import { Subject, merge } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';

import { AlertModalStream } from './alert-modal';
import { DeviceStreams } from './event-presenter';
import { MarketsSelectedStream } from './markets';
import { NavigationStreams } from './navigation';
import { RegistrationModalStream } from './registration';
import { routeMessage } from './router';
import { SidebarLabelReloadLive } from './sidebar-label';
import { SpinnerStream } from './spinner';
import { SportFilterStreams } from './sport-filter';
import {
  DisableCashBackStream,
  EnableMaxButtonStream,
  RemoveOddFromTicketStream,
  SetBalanceToBetAmountStream,
  TicketItemAddRemoveStream,
  TicketItemsStream,
  ToggleOddStream,
} from './ticket-items';
import { MatchTrackerStream } from './ticket-match-tracker';
import { TicketMetaStream } from './ticket-meta';
import { TicketParamsStream } from './ticket-params';
import { TimeFilterStream } from './time-filter';
import { ConsumerStreamMessage, Messenger, PublisherStreamMessage } from './types';
import { ViewSelectorStreams } from './view-selector';
import { WebTicketItemsStream } from './web-ticket-items';

export type ASM = Messenger<ConsumerStreamMessage>;
const ASM = ((): ASM => {
  const Publisher = new Subject<PublisherStreamMessage>();
  const messengers = [
    ...SportFilterStreams,
    TicketItemAddRemoveStream,
    TicketItemsStream,
    TicketMetaStream,
    WebTicketItemsStream,
    TimeFilterStream,
    MarketsSelectedStream,
    SidebarLabelReloadLive,
    SpinnerStream,
    AlertModalStream,
    RegistrationModalStream,
    ...NavigationStreams,
    ...ViewSelectorStreams,
    MatchTrackerStream,
    TicketParamsStream,
    ToggleOddStream,
    RemoveOddFromTicketStream,
    DisableCashBackStream,
    SetBalanceToBetAmountStream,
    EnableMaxButtonStream,
    ...DeviceStreams,
  ];

  const messages$ = Publisher.asObservable();

  const consume = merge<ConsumerStreamMessage>(...messengers.map(m => m.asObservable()));
  const publish = (message: PublisherStreamMessage) => Publisher.next(message);

  messages$.pipe(distinctUntilChanged()).subscribe(routeMessage);

  return { consume, publish };
})();

export const useASM = (): ASM => ASM;
export default ASM;
