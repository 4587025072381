import { TimeFilterStreamMessage } from './index';
import { SET_TIME_FILTER_VALUE, SetTimeFilterValue, TimeFilterState, TimeFilterValue } from './types';

export const dispatchSetTimeFilterValue: (identity: string, value: TimeFilterValue) => SetTimeFilterValue = (
  identity,
  value,
) => ({
  type: SET_TIME_FILTER_VALUE,
  payload: value,
  identity,
});

export const dispatchTimeFilterStreamMessage: (identity: string, value: TimeFilterState) => TimeFilterStreamMessage = (
  identity,
  value,
) => ({
  type: SET_TIME_FILTER_VALUE,
  payload: value,
  identity,
});
