import { BetshopGraphql } from '../../../graphql';
import { ConfigReducer, ConfigState, SET_BETSHOP_CONFIG } from '../types';

export const initialConfigState: ConfigState = {
  betshopConfig: new BetshopGraphql(),
};

export const configReducer: ConfigReducer = (state, action) => {
  switch (action.type) {
    case SET_BETSHOP_CONFIG:
      return {
        ...state,
        betshopConfig: { ...state.betshopConfig, ...action.payload },
      };
    default:
      return state;
  }
};
