import { ReactNode } from 'react';

import { BROADCAST_MESSAGE } from '../types';
import {
  ACTIVATE_ALERT_MODAL,
  ALERT_MODAL_STATE_SET,
  ActivateAlertModal,
  AlertModalOptions,
  AlertModalState,
  AlertModalStateSet,
  DEACTIVATE_ALERT_MODAL,
  DeactivateAlertModal,
} from './types';

export type DispatchActivateAlertModal = (
  message: string | ReactNode,
  options?: AlertModalOptions,
) => ActivateAlertModal;
export const dispatchActivateAlertModal: DispatchActivateAlertModal = (message, options = {}) => ({
  type: ACTIVATE_ALERT_MODAL,
  payload: { message, options },
  identity: BROADCAST_MESSAGE,
});

export type DispatchDeactivateAlertModal = () => DeactivateAlertModal;
export const dispatchDeactivateAlertModal: DispatchDeactivateAlertModal = () => ({
  type: DEACTIVATE_ALERT_MODAL,
  payload: null,
  identity: BROADCAST_MESSAGE,
});

export type DispatchAlertModalStateSet = (state: AlertModalState) => AlertModalStateSet;
export const dispatchAlertModalStateSet: DispatchAlertModalStateSet = state => ({
  type: ALERT_MODAL_STATE_SET,
  payload: state,
  identity: BROADCAST_MESSAGE,
});
