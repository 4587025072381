import { Observable } from 'rxjs';

export const CALCULATE_FIBONACCI = 'CALCULATE_FIBONACCI';
export const FIBONACCI_CALCULATED = 'FIBONACCI_CALCULATED';

export type CalculateFibonacciAction = {
  type: typeof CALCULATE_FIBONACCI;
  payload: number;
};
export type FibonacciCalculatedAction = {
  type: typeof FIBONACCI_CALCULATED;
  payload: number;
};

export type CalculateFibonacci = (n: number) => Observable<FibonacciCalculatedAction>;
