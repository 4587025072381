import { BehaviorSubject } from 'rxjs';

import { BROADCAST_MESSAGE } from '../types';
import {
  IS_DEVICE_BLOCKED_SET,
  IS_NAVIGATION_BLOCKED_SET,
  IsDeviceBlockedSet,
  IsNavigationBlockedSet,
  PREVIOUS_PAGE_SET,
  PreviousPageSet,
  SELECTED_EVENT_SET,
  SelectedEventSet,
  initialNavigationState,
} from './types';

const initialPreviousPageStreamMessage: PreviousPageSet = {
  type: PREVIOUS_PAGE_SET,
  payload: initialNavigationState,
  identity: BROADCAST_MESSAGE,
};

const initialSelectedEventStreamMessage: SelectedEventSet = {
  type: SELECTED_EVENT_SET,
  payload: initialNavigationState,
  identity: BROADCAST_MESSAGE,
};

const initialIsNavigationBlockedMessage: IsNavigationBlockedSet = {
  type: IS_NAVIGATION_BLOCKED_SET,
  payload: initialNavigationState,
  identity: BROADCAST_MESSAGE,
};

const initialIsDeviceBlockedMessage: IsDeviceBlockedSet = {
  type: IS_DEVICE_BLOCKED_SET,
  payload: initialNavigationState,
  identity: BROADCAST_MESSAGE,
};

export const PreviousPageStream = new BehaviorSubject(initialPreviousPageStreamMessage);
export const SelectedEventStream = new BehaviorSubject(initialSelectedEventStreamMessage);
export const IsNavigationBlockedStream = new BehaviorSubject(initialIsNavigationBlockedMessage);
export const IsDeviceBlockedStream = new BehaviorSubject(initialIsDeviceBlockedMessage);

export const NavigationStreams = [
  PreviousPageStream,
  SelectedEventStream,
  IsNavigationBlockedStream,
  IsDeviceBlockedStream,
];
