import { lazy } from 'navi';

import { PageGraphql } from '../../graphql';
import { Betshop } from '../../shared-types/graphql';

const getBetshopViewRoute = (page: PageGraphql) => ({
  path: `/${page.slug}`,
  component: () => lazy(() => import('../../pages/BetshopView/BetshopView')),
  title: page.name,
});

export const generateBethopRoutes = (betshop: Betshop) => {
  return [
    ...betshop.pages.map(getBetshopViewRoute),
    {
      path: '/activate',
      component: () => lazy(() => import('../../themes/maxbet/pages/Activation')),
      title: 'User Activation',
    },
  ];
};
