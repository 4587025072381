import { getValueForKeyInBrowserStorage } from '../../../../utils/browserStorage';
import { AuthBrowserStorageKeys } from '../../types';
import { AuthReducer, AuthState, SET_ACCESS_TOKEN, SET_REFRESH_TOKEN } from './types';

export const authInitialState: AuthState = {
  token: getValueForKeyInBrowserStorage(AuthBrowserStorageKeys.token) || '',
  refreshToken: getValueForKeyInBrowserStorage(AuthBrowserStorageKeys.refreshToken) || '',
};

export const authReducer: AuthReducer = (state = authInitialState, action) => {
  switch (action.type) {
    case SET_ACCESS_TOKEN:
      return { ...state, ...{ token: action.payload } };
    case SET_REFRESH_TOKEN:
      return { ...state, ...{ refreshToken: action.payload } };
    default:
      return state;
  }
};
