import { BehaviorSubject } from 'rxjs';

import { BROADCAST_MESSAGE } from '../types';
import { TICKET_PARAMS_SET, TicketParamsSet, TicketParamsState } from './types';

export const initialTicketParamsMessage: TicketParamsState = null;

export type TicketParamsStream = BehaviorSubject<TicketParamsSet>;
export const TicketParamsStream: TicketParamsStream = new BehaviorSubject<TicketParamsSet>({
  type: TICKET_PARAMS_SET,
  payload: initialTicketParamsMessage,
  identity: BROADCAST_MESSAGE,
});
