import { Dispatch } from 'react';

export const SET_ACCESS_TOKEN = 'SET_ACCESS_TOKEN';
export const SET_REFRESH_TOKEN = 'SET_REFRESH_TOKEN';

export type SetAccessToken = (token: string) => void;
export type SetRefreshToken = (token: string) => void;

type AuthActions = {
  setAccessToken: SetAccessToken;
  setRefreshToken: SetRefreshToken;
};

export type SetAccessTokenAction = {
  type: typeof SET_ACCESS_TOKEN;
  payload: string;
};

export type SetRefreshTokenAction = {
  type: typeof SET_REFRESH_TOKEN;
  payload: string;
};

export type AuthState = {
  token: string;
  refreshToken: string;
};

type AuthAction = SetAccessTokenAction | SetRefreshTokenAction;
export type AuthReducer = (state: AuthState, action: AuthAction) => AuthState;
export type UseAuthActions = (state: AuthState, dispatch: Dispatch<AuthAction>) => AuthActions;
