import { AltEventChangeMessage, mapSocketMessage } from './map-socket-message';

export function mapEventChange(message: AltEventChangeMessage) {
  return {
    ...mapSocketMessage(message),
    data: {
      id_sport: message.data.SId,
      id_tournament: message.data.TId,
      id_event: message.data.EId,
      active: message.data.A,
      current_time: message.data.CT,
      remaining_time: message.data.ReT,
      time_stopped: message.data.TS,
      stoppage_time: message.data.StT,
      period: message.data.CP,
      status: message.data.S,
      live_status: message.data.LS,
      active_oddtype_count: message.data.AOC,
      live: message.data.L,
      utc_scheduled: message.data.UTC,
    },
  };
}
