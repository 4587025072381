import { MatchTrackerReducer, SET_MATCH_TRACKER_DATA } from './types';

export const matchTrackerReducer: MatchTrackerReducer = (state, action) => {
  switch (action.type) {
    case SET_MATCH_TRACKER_DATA:
      const { eventId, hasLiveTracker } = action.payload;

      return {
        ...state,
        eventId,
        hasLiveTracker,
      };
    default:
      return state;
  }
};
