import gql from 'graphql-tag';

import { User } from '../../shared/types/User';

export type GetUserDataQuery = { me: User };

export const GET_USER_DATA = gql`
  query {
    me {
      _id
      username
    }
  }
`;
