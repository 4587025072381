import React, { FC, createContext, useCallback, useContext, useReducer } from 'react';

import { PageGraphql } from '../../../../graphql';
import { Betshop } from '../../../../shared-types/graphql';
import { clone } from '../../../../utils/clone';
import { BetshopPageEditActions, useBetshopPageEditGraphQL } from '../graphql/useBetshopPageEditGraphql';
import { useBetshopPageEditActions } from './actions';
import { betshopPageEditReducer, initialBetshopPageEditState } from './reducer';

type BetshopPageEditContext = {
  state: Betshop;
  updateBetshopPages: (betshop: Partial<Betshop>) => void;
  changePageLayoutType: (name: string, layoutType: string) => void;
} & Omit<BetshopPageEditActions, 'updatePagesAndRefetch'>;

const BetshopPageEditContext = createContext({} as BetshopPageEditContext);
export const useBetshopPageEditContext = () => useContext(BetshopPageEditContext);

export const BetshopPageEditContextProvider: FC = ({ children }) => {
  const [state, dispatch] = useReducer(betshopPageEditReducer, initialBetshopPageEditState);
  const { updatePagesAndRefetch, ...betshopPageEditActions } = useBetshopPageEditGraphQL(state, dispatch);
  const { setBetshopPagesData } = useBetshopPageEditActions(state, dispatch);

  const updateBetshopPages = useCallback(
    (betshop: Partial<Betshop>) => {
      if (betshop._id) {
        const { _id, pages, header, footer } = betshop;
        updatePagesAndRefetch({ _id, pages, header, footer });
      }
    },
    [updatePagesAndRefetch],
  );

  const changePageLayoutType = useCallback(
    (name: string, layoutType: string) => {
      const pages: PageGraphql[] = clone(state.pages);
      const page = pages.find(p => p.name === name);
      if (!page) return;

      page.layout.name = layoutType;
      setBetshopPagesData({ ...state, pages });
    },
    [state, setBetshopPagesData],
  );

  return (
    <BetshopPageEditContext.Provider
      value={{
        state,
        updateBetshopPages,
        changePageLayoutType,
        ...betshopPageEditActions,
      }}
    >
      {children}
    </BetshopPageEditContext.Provider>
  );
};
