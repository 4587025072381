import React, { FC, createContext, useContext, useReducer } from 'react';

import { BetshopGraphqlActions, useBetshopsGraphQL } from '../graphql/useBetshopsGraphQL';
import { betshopListingInitialState, betshopListingReducer } from './reducer';
import { BetshopListingState } from './types';

export type BetshopListingContext = {
  state: BetshopListingState;
} & BetshopGraphqlActions;

const BetshopListingContext = createContext({} as BetshopListingContext);

export const useBetshopListingContext = () => useContext(BetshopListingContext);
export const BetshopListingContextProvider: FC = ({ children }) => {
  const [state, dispatch] = useReducer(betshopListingReducer, betshopListingInitialState);
  const betshopsGraphql = useBetshopsGraphQL(state, dispatch);

  return (
    <BetshopListingContext.Provider
      value={{
        state,
        ...betshopsGraphql,
      }}
    >
      {children}
    </BetshopListingContext.Provider>
  );
};
