import { PageGraphql, ThemesGraphql } from '../../../../graphql';
import { BaseColorScheme } from '../../../../shared-types/color-scheme';
import { Betshop } from '../../../../shared-types/graphql';
import { NavigationRow } from '../../../../shared-types/header-footer';
import { clone } from '../../../../utils/clone';
import {
  BetshopPageEditReducer,
  SET_BETSHOP_NAVI_ITEMS_TEXT,
  SET_BETSHOP_PAGES_DATA,
  SET_BETSHOP_PAGE_NAME,
} from './types';

export const initialBetshopPageEditState: Betshop = {
  _id: '',
  logo: '',
  name: '',
  type: '',
  provider: '',
  colorScheme: {} as BaseColorScheme,
  activeTheme: new ThemesGraphql(),
  availableThemes: [],
  pages: [],
};

const updateNavigationItemsText = (navigation: NavigationRow[], page: PageGraphql): NavigationRow[] => {
  return navigation.map(naviRow => {
    naviRow.items = naviRow.items.map(item => {
      if (item.link === page.slug) {
        item.text = page.name;
      }

      return item;
    }, []);

    return naviRow;
  });
};

const changeBetshopNaviItemsText = (state: Betshop, page: PageGraphql): Betshop => {
  const newState = clone(state);

  if (newState.header) {
    const headerNavi = updateNavigationItemsText(newState.header.navigation, page);
    newState.header = { ...newState.header, navigation: headerNavi };
  }

  if (newState.footer) {
    const footerNavi = updateNavigationItemsText(newState.footer.navigation, page);
    newState.footer = { ...newState.footer, navigation: footerNavi };
  }

  return newState;
};

export const betshopPageEditReducer: BetshopPageEditReducer = (state, action) => {
  switch (action.type) {
    case SET_BETSHOP_PAGES_DATA:
      return { ...state, ...action.payload };
    case SET_BETSHOP_PAGE_NAME:
      return {
        ...state,
        pages: [...state.pages.filter(p => p.slug !== action.payload.slug), action.payload],
      };
    case SET_BETSHOP_NAVI_ITEMS_TEXT:
      return changeBetshopNaviItemsText(state, action.payload);
    default:
      return state;
  }
};
