import { DefaultTheme, keyframes } from 'styled-components/macro';

import { ColorOption } from '../shared-types/color-scheme';
import { c } from '../utils/color';
import { OddTrend } from './types/widget-types/Markets';

type Tint = (hex: string, amount: number) => string;
export const tint: Tint = (hex, amount) => {
  let R = parseInt(hex.substring(1, 3), 16);
  let G = parseInt(hex.substring(3, 5), 16);
  let B = parseInt(hex.substring(5, 7), 16);

  const getSingle = (number: number) => parseInt(((number * (100 + amount)) / 100).toString(), 10);

  R = getSingle(R);
  G = getSingle(G);
  B = getSingle(B);

  R = R < 255 ? R : 255;
  G = G < 255 ? G : 255;
  B = B < 255 ? B : 255;

  const getDouble = (number: number) =>
    number.toString(16).length === 1 ? `0${number.toString(16)}` : number.toString(16);

  const RR = getDouble(R);
  const GG = getDouble(G);
  const BB = getDouble(B);

  return `#${RR}${GG}${BB}`;
};

type Hexa = (hex: string, alpha: number) => string;
export const hexa: Hexa = (hex, alpha) => {
  if (hex.length === 4) {
    hex = hex
      .slice(1)
      .split('')
      .reduce<string[]>((acc, curr) => {
        return [...acc, curr + curr];
      }, [])
      .join('');
  }

  const r = parseInt(hex.slice(1, 3), 16),
    g = parseInt(hex.slice(3, 5), 16),
    b = parseInt(hex.slice(5, 7), 16);

  if (alpha >= 0) {
    return `rgba(${r}, ${g}, ${b}, ${alpha})`;
  } else {
    return `rgb(${r}, ${g}, ${b})`;
  }
};

type NeutralTrendColors = {
  color: string;
  backgroundColor: string;
  borderColor: string;
};
export const fade = (neutral: NeutralTrendColors, theme: DefaultTheme, trend?: OddTrend) => keyframes`{
  0% {
    color: ${
      trend === OddTrend.Up
        ? c(theme, { kind: ColorOption.MaxbetBase, color: 'success' })
        : trend === OddTrend.Down
        ? c(theme, { kind: ColorOption.MaxbetBase, color: 'error' })
        : neutral.color
    };
    background-color: ${
      trend === OddTrend.Up
        ? hexa(tint(c(theme, { kind: ColorOption.MaxbetBase, color: 'success' }), 123), 0.2)
        : trend === OddTrend.Down
        ? hexa(tint(c(theme, { kind: ColorOption.MaxbetBase, color: 'accent' }), 34), 0.2)
        : neutral.backgroundColor
    };
    border-color: ${
      trend === OddTrend.Up
        ? hexa(tint(c(theme, { kind: ColorOption.MaxbetBase, color: 'success' }), 62), 0.4)
        : trend === OddTrend.Down
        ? hexa(tint(c(theme, { kind: ColorOption.MaxbetBase, color: 'error' }), 14), 0.4)
        : neutral.borderColor
    };
  }
  75% {
    color: ${
      trend === OddTrend.Up
        ? c(theme, { kind: ColorOption.MaxbetBase, color: 'success' })
        : trend === OddTrend.Down
        ? c(theme, { kind: ColorOption.MaxbetBase, color: 'error' })
        : neutral.color
    };
    background-color: ${
      trend === OddTrend.Up
        ? hexa(tint(c(theme, { kind: ColorOption.MaxbetBase, color: 'success' }), 123), 0.1)
        : trend === OddTrend.Down
        ? hexa(tint(c(theme, { kind: ColorOption.MaxbetBase, color: 'accent' }), 34), 0.1)
        : neutral.backgroundColor
    };
    border-color: ${
      trend === OddTrend.Up
        ? hexa(tint(c(theme, { kind: ColorOption.MaxbetBase, color: 'success' }), 62), 0.3)
        : trend === OddTrend.Down
        ? hexa(tint(c(theme, { kind: ColorOption.MaxbetBase, color: 'error' }), 14), 0.3)
        : neutral.borderColor
    };
  }
  100% {
    color: ${neutral.color};
    background-color: ${neutral.backgroundColor};
    border-color: ${neutral.borderColor};
  }
}`;

export const fadeInOut = keyframes`
  0% {
    opacity: 0.5;
  }
  10% {
    opacity: 1;
  }
  95% {
    opacity: 0.9;
  }
  100% {
    opacity: 0;
  }
`;

type GetRandomInt = (min: number, max: number) => number;
export const getRandomInt: GetRandomInt = (min, max) => {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min + 1)) + min;
};

type Factorial = (n: number) => number;
export const factorial: Factorial = (function() {
  const cache = {},
    fn = function(n: number) {
      if (n === 0) {
        return 1;
      } else if (cache[n]) {
        return cache[n];
      }
      return (cache[n] = n * fn(n - 1));
    };
  return fn;
})();

type Capitalize = (s: string) => string;
export const capitalize: Capitalize = s => s && s[0].toUpperCase() + s.slice(1).toLowerCase();

export function isObject(item) {
  return item && typeof item === 'object' && !Array.isArray(item);
}

export function mergeDeep(target, source) {
  const output = Object.assign({}, target);
  if (isObject(target) && isObject(source)) {
    Object.keys(source).forEach(key => {
      if (isObject(source[key])) {
        if (!(key in target)) Object.assign(output, { [key]: source[key] });
        else output[key] = mergeDeep(target[key], source[key]);
      } else {
        Object.assign(output, { [key]: source[key] });
      }
    });
  }
  return output;
}

export const removeCommonElements = <T>(a: T[], b: T[]): [T[], T[]] => {
  const aWithoutB = a.filter(aEl => !b.includes(aEl));
  const bWithoutA = b.filter(bEl => !a.includes(bEl));
  return [aWithoutB, bWithoutA];
};

type Range = (start: number, end: number) => number[];
export const range: Range = (start, end) => Array.from({ length: end - start }, (_, k) => k + start);

export const getDateWithoutMiliseconds = (date: Date) => date.toISOString().split('.')[0];

// eslint-disable-next-line @typescript-eslint/no-empty-function
export const noOp = () => {};

export const compose = (...fns) => x => fns.reduceRight((acc, cur) => cur(acc), x);
