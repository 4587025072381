import { ThemesGraphql } from '../../../../graphql';
import { BaseColorScheme, BaseColors, FooterColors, HeaderColors } from '../../../../shared-types/color-scheme';
import { Betshop } from '../../../../shared-types/graphql';
import { NavigationItem, NavigationType } from '../../../../shared-types/header-footer';
import { ContextAction } from '../../../../shared/types/ContextAction';

export const SET_BETSHOP_DATA = 'SET_BETSHOP_DATA';
export const SET_BETSHOP_TITLE = 'SET_BETSHOP_TITLE';
export const SET_BETSHOP_THEME_DATA = 'SET_BETSHOP_THEME_DATA';
export const SET_BASE_COLORS = 'SET_BASE_COLORS';
export const SET_HEADER_COLORS = 'SET_HEADER_COLORS';
export const SET_FOOTER_COLORS = 'SET_FOOTER_COLORS';
export const SET_BETSHOP_LOGO = 'SET_BETSHOP_LOGO';
export const ADD_TO_BETSHOP_NAVIGATION_ITEMS = 'ADD_TO_BETSHOP_NAVIGATION_ITEMS';
export const REMOVE_FROM_BETSHOP_NAVIGATION_ITEMS = 'REMOVE_FROM_BETSHOP_NAVIGATION_ITEMS';
export const TOGGLE_BETSHOP_HEADER_ITEM_HIGHLIGHT = 'TOGGLE_BETSHOP_HEADER_ITEM_HIGHLIGHT';
export const RESORT_NAVIGATION = 'RESORT_NAVIGATION';

export type BetshopThemeData = {
  colorScheme: BaseColorScheme;
  activeTheme?: ThemesGraphql;
};

export type NavigationItemData = {
  item: NavigationItem;
  level: number;
};

export type NavigationItemPayload = {
  type: NavigationType;
} & NavigationItemData;

export type SetBetshopData = (betshopThemeData: Betshop) => void;
type SetBetshopAction = ContextAction<typeof SET_BETSHOP_DATA, Betshop>;

export type SetBetshopTitle = (betshopTitle: string) => void;
type SetBetshopTitleAction = ContextAction<typeof SET_BETSHOP_TITLE, string>;

export type SetBetshopThemeData = (betshopThemeData: BetshopThemeData) => void;
type SetBetshopThemeAction = ContextAction<typeof SET_BETSHOP_THEME_DATA, BetshopThemeData>;

export type SetBaseColors = (colors: BaseColors) => void;
type SetBaseColorsAction = ContextAction<typeof SET_BASE_COLORS, BaseColors>;

export type SetHeaderColors = (colors: HeaderColors) => void;
type SetHeaderColorsAction = ContextAction<typeof SET_HEADER_COLORS, HeaderColors>;

export type SetFooterColors = (colors: FooterColors) => void;
type SetFooterColorsAction = ContextAction<typeof SET_FOOTER_COLORS, FooterColors>;

export type SetBetshopLogo = (logoData: { logo: string }) => void;
type SetBetshopLogoAction = ContextAction<typeof SET_BETSHOP_LOGO, { logo: string }>;

export type AddToBetshopNavigationItems = (itemData: NavigationItemPayload) => void;
type AddToBetshopNavigationItemsAction = ContextAction<typeof ADD_TO_BETSHOP_NAVIGATION_ITEMS, NavigationItemPayload>;

export type RemoveFromBetshopNavigationItems = (itemData: NavigationItemPayload) => void;
type RemoveFromBetshopNavigationItemsAction = ContextAction<
  typeof REMOVE_FROM_BETSHOP_NAVIGATION_ITEMS,
  NavigationItemPayload
>;

export type ToggleBetshopHeaderItemHighlight = (itemData: NavigationItemPayload) => void;
type ToggleBetshopHeaderItemHighlightAction = ContextAction<
  typeof TOGGLE_BETSHOP_HEADER_ITEM_HIGHLIGHT,
  NavigationItemPayload
>;

export type ResortNavigationPayload = {
  type: NavigationType;
  level: number;
  source: number;
  destination: number;
};
export type ResortNavigation = (resortData: ResortNavigationPayload) => void;
export type ResortNavigationAction = ContextAction<typeof RESORT_NAVIGATION, ResortNavigationPayload>;

export type BetshopEditActions = {
  setBetshopData: SetBetshopData;
  setBetshopTitle: SetBetshopTitle;
  setBetshopThemeData: SetBetshopThemeData;
  setBaseColors: SetBaseColors;
  setHeaderColors: SetHeaderColors;
  setFooterColors: SetFooterColors;
  setBetshopLogo: SetBetshopLogo;
  addToBetshopNavigationItems: AddToBetshopNavigationItems;
  removeFromBetshopNavigationItems: RemoveFromBetshopNavigationItems;
  toggleBetshopHeaderItemHighlight: ToggleBetshopHeaderItemHighlight;
  resortNavigation: ResortNavigation;
};
export type BetshopEditAction =
  | SetBetshopAction
  | SetBetshopTitleAction
  | SetBetshopThemeAction
  | SetBaseColorsAction
  | SetHeaderColorsAction
  | SetFooterColorsAction
  | SetBetshopLogoAction
  | AddToBetshopNavigationItemsAction
  | RemoveFromBetshopNavigationItemsAction
  | ToggleBetshopHeaderItemHighlightAction
  | ResortNavigationAction;
export type BetshopEditReducer = (state: Betshop, action: BetshopEditAction) => Betshop;
