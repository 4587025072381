import { BehaviorSubject } from 'rxjs';

import { WEB_TICKET_ITEMS, WebTicketItems } from './types';

export type WebTicketItemsSubject = BehaviorSubject<WebTicketItems>;
export const WebTicketItemsStream: WebTicketItemsSubject = new BehaviorSubject<WebTicketItems>({
  identity: '',
  type: WEB_TICKET_ITEMS,
  payload: null,
});
