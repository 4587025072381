import { useEffect } from 'react';
import { BehaviorSubject, Subject } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import { catchAndLogError } from '../../../utils/widget-utils/catchAndLogError';
import { HTTP } from '../http';
import { AuthDependency, Gender, UserData } from './types';

export const initialUserData: UserData = {
  user: {
    email: '',
    firstName: '',
    lastName: '',
    gender: Gender.Male,
    nationalId: '',
    idCard: '',
    passport: '',
    telephone: '',
    idGeolocation: '',
    country: '',
    city: '',
    address: '',
    addressNum: '',
    zip: '',
    code: '',
    emailVerified: false,
    birthDate: null,
  },
  wallet: { currency: '', balance: 0, freeBetBalance: 0 },
  geo: {
    coordinates: [0, 0],
    id_location: null,
    params: {
      MAX_PAYOUT_PER_TICKET: null,
      MAX_DEPOSIT_FOR_MULTI_TICKET: null,
      MIN_DEPOSIT_FOR_MULTI_TICKET: null,
      MAX_DEPOSIT_FOR_SINGLE_TICKET: null,
      MAX_DEPOSIT_FOR_SYSTEM_TICKET: null,
      MIN_DEPOSIT_FOR_SINGLE_TICKET: null,
      MIN_DEPOSIT_FOR_SYSTEM_TICKET: null,
      MAX_NUM_OF_EVENTS_FOR_MULTI_TICKET: null,
      MAX_NUM_OF_COMBOS_FOR_SYSTEM_TICKET: null,
      MAX_NUM_OF_EVENTS_FOR_SYSTEM_TICKET: null,
      MIN_NUM_OF_EVENTS_FOR_SYSTEM_TICKET: null,
      MAX_DEPOSIT_PER_EVENT_FOR_MULTI_TICKET: null,
      MIN_DEPOSIT_PER_EVENT_FOR_MULTI_TICKET: null,
      MAX_DEPOSIT_PER_COMBO_FOR_SYSTEM_TICKET: null,
      MIN_DEPOSIT_PER_COMBO_FOR_SYSTEM_TICKET: null,
    },
    id_device: null,
    entity_type: '',
    id_client: '',
    id_company: '',
    name: '',
    id_region: '',
    id: '',
    address: '',
    id_group: '',
    telephone_num: '',
  },
};

export const UserDataStream = new BehaviorSubject<UserData>(initialUserData);
const userData$ = UserDataStream.asObservable();

const GetUserData = new Subject();

export const getUserData = () => {
  GetUserData.next();
};

export const useAuthEffect = (cb: (userData: UserData) => void) => {
  useEffect(() => {
    const userDataSub$ = userData$.subscribe(cb);

    return () => userDataSub$.unsubscribe();
  }, [cb]);
};

const user = (http: HTTP): AuthDependency => {
  GetUserData.pipe(
    switchMap(() => {
      return http.get<UserData>('users/me/', { withCredentials: true }, false, false);
    }),
    catchAndLogError('user', initialUserData),
  ).subscribe(userData => UserDataStream.next(userData));

  return { getUserData };
};

export default user;
