import { Dispatch, useCallback } from 'react';

import { WidgetGraphql } from '../../../../graphql';
import { DropAreaInfo } from './context';
import {
  CLEAR_WIDGET_FROM_SETTINGS,
  PageWidgetsAction,
  PageWidgetsState,
  SET_ALL_PAGE_WIDGETS,
  SET_WIDGET_FOR_SETTINGS,
} from './types';

export type SetWidgetsForSettings = (widget: WidgetGraphql, positionInfo: DropAreaInfo) => void;
export type ClearWidgetFromSettings = () => void;
export type SetAllPageWidgets = (widgets: WidgetGraphql[]) => void;

export type PageWidgetsActions = {
  setWidgetsForSettings: SetWidgetsForSettings;
  clearWidgetFromSettings: ClearWidgetFromSettings;
  setAllPageWidgets: SetAllPageWidgets;
};

export type UsePageWidgetsActions = (
  state: PageWidgetsState,
  dispatch: Dispatch<PageWidgetsAction>,
) => PageWidgetsActions;
export const usePageWidgetsActions: UsePageWidgetsActions = (_state, dispatch) => {
  const setWidgetsForSettings: SetWidgetsForSettings = (widget, positionInfo) => {
    dispatch({
      type: SET_WIDGET_FOR_SETTINGS,
      payload: { widget, positionInfo },
    });
  };

  const clearWidgetFromSettings: ClearWidgetFromSettings = () => {
    dispatch({
      type: CLEAR_WIDGET_FROM_SETTINGS,
      payload: undefined,
    });
  };

  const setAllPageWidgets: SetAllPageWidgets = useCallback(
    widgets => {
      dispatch({
        type: SET_ALL_PAGE_WIDGETS,
        payload: widgets,
      });
    },
    [dispatch],
  );

  return { setWidgetsForSettings, clearWidgetFromSettings, setAllPageWidgets };
};
