import { ADD_TO_OPENED_ROOMS, BetshopBuildReducer, BetshopBuildState, REMOVE_FROM_OPENED_ROOMS } from './types';

export const betshopBuildInitialState: BetshopBuildState = {
  openedRooms: [],
};

export const betshopBuildReducer: BetshopBuildReducer = (state = betshopBuildInitialState, action) => {
  switch (action.type) {
    case ADD_TO_OPENED_ROOMS: {
      return { ...state, openedRooms: [...state.openedRooms, action.payload] };
    }
    case REMOVE_FROM_OPENED_ROOMS: {
      return {
        ...state,
        openedRooms: state.openedRooms.filter(room => room !== action.payload),
      };
    }
    default:
      return state;
  }
};
