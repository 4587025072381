import { ACTIVATE_SPINNER, DEACTIVATE_SPINNER, RESET_SPINNER, SpinnerReducer } from './types';

export const spinnerReducer: SpinnerReducer = (state, action) => {
  switch (action.type) {
    case ACTIVATE_SPINNER:
      return { ...state, activeWidgetsIds: [...state.activeWidgetsIds, action.payload] };
    case DEACTIVATE_SPINNER:
      return { ...state, activeWidgetsIds: state.activeWidgetsIds.filter(id => id !== action.payload) };
    case RESET_SPINNER:
      return { ...state, activeWidgetsIds: [] };
    default:
      return state;
  }
};
