import { InMemoryCache } from 'apollo-cache-inmemory';
import ApolloClient from 'apollo-client';
import { ApolloLink, from } from 'apollo-link';
import { onError } from 'apollo-link-error';
import { createUploadLink } from 'apollo-upload-client';
import { FC, useCallback, useEffect, useState } from 'react';
import React from 'react';
import { ApolloProvider } from 'react-apollo';
import { useToasts } from 'react-toast-notifications';

import { useAuthContext } from '../../features/authentication/state/auth/context';
import { environment } from '../environment';
import { CustomApolloError } from '../types/CustomApolloError';

function omitTypename(key: string, value: unknown) {
  return key === '__typename' ? undefined : value;
}

const httpLink = createUploadLink({
  uri: `${environment.graphqlApiUrl}`,
});

const ApolloContextProvider: FC = ({ children }) => {
  const { addToast } = useToasts();
  const {
    state: { refreshToken, token },
    signOut: _signOut,
  } = useAuthContext();
  const signOut = useCallback(_signOut, []);

  const [client, setClient] = useState(
    new ApolloClient({
      link: httpLink,
      cache: new InMemoryCache(),
      defaultOptions: {
        mutate: {
          errorPolicy: 'all',
        },
        query: {
          errorPolicy: 'all',
        },
      },
    }),
  );

  useEffect(() => {
    setClient(() => {
      const authMiddleware = new ApolloLink((operation, forward) => {
        // add the authorization to the headers
        operation.setContext({
          headers: {
            authorization: token ? `Bearer ${token}` : '',
            refreshToken: refreshToken ? refreshToken : '',
            connection: 'keep-alive',
          },
        });

        if (operation.variables && !operation.variables.file) {
          operation.variables = JSON.parse(JSON.stringify(operation.variables), omitTypename);
        }

        return forward(operation);
      });

      const errorMiddleware = onError(({ networkError, graphQLErrors, forward, operation }) => {
        if (graphQLErrors) {
          graphQLErrors.forEach(err => {
            const { statusCode, message } = (err.message as unknown) as CustomApolloError;
            switch (statusCode) {
              case 403:
                return signOut();
              default:
                addToast(`Error: ${message}`, { appearance: 'error' });
            }
          });
        }
        if (networkError) {
          addToast(networkError.message, { appearance: 'error' });
        }

        return forward(operation);
      });

      return new ApolloClient({
        link: from([errorMiddleware, authMiddleware, httpLink]),
        cache: new InMemoryCache(),
        defaultOptions: {
          mutate: {
            errorPolicy: 'all',
          },
          query: {
            errorPolicy: 'all',
          },
        },
      });
    });
  }, [token, refreshToken, signOut, addToast]);

  return <ApolloProvider client={client}>{children}</ApolloProvider>;
};

export default ApolloContextProvider;
