import { TicketItem } from '../../../../shared/types/widget-types/TicketItem';
import { TOGGLE_ODD } from '../../../../themes/orion-tip/widgets/Ticket/store/types';
import { BROADCAST_MESSAGE } from '../types';
import {
  ADD_TO_TICKET,
  AddToTicket,
  CASHBACK_DISABLE,
  CLEAR_MARGIN_CHANGES,
  CLEAR_TICKET,
  CashBackDisable,
  ClearMarginChanges,
  ClearTicket,
  ENABLE_MAX_BUTTON,
  EnableMaxButton,
  REMOVE_FROM_TICKET,
  REMOVE_ODD_FROM_TICKET,
  RemoveFromTicket,
  RemoveOddFromTicket,
  SET_BALANCE_TO_BET_AMOUNT,
  SET_TICKET_ITEMS,
  SetBalanceToBetAmount,
  SetTicketItems,
  TICKET_ITEMS,
  TICKET_SUBMIT_SUCCESS,
  TOGGLE_ODD_TYPE,
  TicketItems,
  TicketSubmitSuccess,
  ToggleOdd,
  ToggleOddType,
} from './types';

type Dispatch<Input, Output> = (identity: string, input: Input) => Output & { identity: string };

type DispatchTicketItems = Dispatch<TicketItem[], TicketItems>;
export const dispatchTicketItems: DispatchTicketItems = (_identity, items) => ({
  type: TICKET_ITEMS,
  payload: items,
  identity: BROADCAST_MESSAGE,
});

type DispatchToggleOdd = Dispatch<TicketItem, ToggleOdd>;
export const dispatchToggleOdd: DispatchToggleOdd = (_identity, item) => {
  return {
    type: TOGGLE_ODD,
    payload: item,
    identity: BROADCAST_MESSAGE,
  };
};

type DispatchRemoveOddFromTicket = Dispatch<TicketItem, RemoveOddFromTicket>;
export const dispatchRemoveOddFromTicket: DispatchRemoveOddFromTicket = (_identity, item) => {
  return {
    type: REMOVE_ODD_FROM_TICKET,
    payload: item,
    identity: BROADCAST_MESSAGE,
  };
};

type DispatchAddToTicket = Dispatch<TicketItem, AddToTicket>;
export const dispatchAddToTicket: DispatchAddToTicket = (_identity, event) => {
  return {
    type: ADD_TO_TICKET,
    payload: event,
    identity: BROADCAST_MESSAGE,
  };
};

type DispatchRemoveFormTicket = Dispatch<TicketItem, RemoveFromTicket>;
export const dispatchRemoveFromTicket: DispatchRemoveFormTicket = (_identity, event) => ({
  type: REMOVE_FROM_TICKET,
  payload: event,
  identity: BROADCAST_MESSAGE,
});

type DispatchSetTicketItems = Dispatch<TicketItem[], SetTicketItems>;
export const dispatchSetTicketItems: DispatchSetTicketItems = (identity, events) => ({
  type: SET_TICKET_ITEMS,
  payload: events,
  identity,
});

type DispatchToggleOddType = Dispatch<TicketItem, ToggleOddType>;
export const dispatchToggleOddType: DispatchToggleOddType = (identity, ticketItem) => ({
  type: TOGGLE_ODD_TYPE,
  payload: ticketItem,
  identity,
});

type DispatchClearTicket = Dispatch<null, ClearTicket>;
export const dispatchClearTicket: DispatchClearTicket = identity => ({
  type: CLEAR_TICKET,
  payload: null,
  identity,
});

type DispatchTicketSubmitSuccess = Dispatch<null, TicketSubmitSuccess>;
export const dispatchTicketSubmitSuccess: DispatchTicketSubmitSuccess = identity => ({
  type: TICKET_SUBMIT_SUCCESS,
  payload: TICKET_SUBMIT_SUCCESS,
  identity,
});

type DispatchCashBackDisabled = Dispatch<null, CashBackDisable>;
export const dispatchCashBackDisabled: DispatchCashBackDisabled = identity => ({
  type: CASHBACK_DISABLE,
  payload: null,
  identity,
});

type DispatchClearMarginChanges = Dispatch<null, ClearMarginChanges>;
export const dispatchClearMarginChanges: DispatchClearMarginChanges = (identity, ticketItems) => ({
  type: CLEAR_MARGIN_CHANGES,
  payload: ticketItems,
  identity,
});

type DispatchSetBalanceToBetAmount = Dispatch<null, SetBalanceToBetAmount>;
export const dispatchSetBalanceToBetAmount: DispatchSetBalanceToBetAmount = identity => ({
  type: SET_BALANCE_TO_BET_AMOUNT,
  payload: null,
  identity,
});

type DispatchEnableMaxButton = Dispatch<null, EnableMaxButton>;
export const dispatchEnableMaxButton: DispatchEnableMaxButton = identity => ({
  type: ENABLE_MAX_BUTTON,
  payload: null,
  identity,
});
