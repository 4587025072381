import { SetSocketRoom, SubmitReadyTicket, SubmitTicket } from '../../../../../shared-types/ticket-types';
import { ActionWithIdentity } from '../../../../../shared/types/ActionWithIdentity';
import { ContextReducer } from '../../../../../shared/types/ContextReducer';
import { EventPresenterEvents } from '../../../../../shared/types/widget-types/EventsPresenterSport';
import { TicketItem } from '../../../../../shared/types/widget-types/TicketItem';
import { DeviceType, TicketParams } from '../../../../../store/dependencies/app-messaging/ticket-params/types';
import { TicketSystemResponse } from '../../../../../store/dependencies/worker/ticket-system/types';
import { OtTicketItem } from '../../../shared/types';

export const SET_TICKET_ITEMS = 'SET_TICKET_ITEMS';
export const SET_TICKET_META = 'SET_TICKET_META';
export const SET_POSSIBLE_TICKET_SYSTEM_OPTIONS = 'SET_POSSIBLE_TICKET_SYSTEM_OPTIONS';
export const SELECT_TICKET_SYSTEM_OPTION = 'SELECT_TICKET_SYSTEM_OPTION';
export const SET_TICKET_SYSTEM_DATA = 'SET_TICKET_SYSTEM_DATA';
export const UPDATE_TICKET_SELECTION_STATE = 'UPDATE_TICKET_SELECTION_STATE';
export const SET_TICKET_BET_TYPE = 'SET_TICKET_BET_TYPE';
export const SET_TICKET_ACTIVE_TAB = 'SET_TICKET_ACTIVE_TAB';
export const SET_TICKET_BONUS_OPTION = 'SET_TICKET_BONUS_OPTION';
export const SET_ODD_CHANGES_ACCEPTED = 'SET_ODD_CHANGES_ACCEPTED';
export const UPDATE_BET_AMOUNT = 'UPDATE_BET_AMOUNT';
export const UPDATE_BET_AMOUNT_KEYPAD = 'UPDATE_BET_AMOUNT_KEYPAD';
export const REFETCH_TICKET_ITEMS = 'REFETCH_TICKET_ITEMS';
export const UPDATE_TICKET_ITEMS = 'UPDATE_TICKET_ITEMS';
export const GET_MAX_BET = 'GET_MAX_BET';
export const SET_MAX_BET_AMOUNT_SOCKET_ROOM = 'SET_MAX_BET_AMOUNT_SOCKET_ROOM';
export const SET_MAX_BET_AMOUNT_KEYPAD_SOCKET_ROOM = 'SET_MAX_BET_AMOUNT_KEYPAD_SOCKET_ROOM';
export const SET_IS_STAKE_MODAL_ACTIVE = 'SET_IS_STAKE_MODAL_ACTIVE';
export const SET_LOCATION_PARAMS = 'SET_LOCATION_PARAMS';
export const SET_TICKET_PARAMS = 'SET_TICKET_PARAMS';
export const CHECK_LIVE_TRACKER = 'CHECK_LIVE_TRACKER';
export const SET_SELECTED_EVENT_HAS_LIVE_TRACKER = 'SET_SELECTED_EVENT_HAS_LIVE_TRACKER';
export const ADD_TICKET_ITEM = 'ADD_TICKET_ITEM';
export const TOGGLE_ODD = 'TOGGLE_ODD';
export const REMOVE_ODD_FROM_TICKET = 'REMOVE_ODD_FROM_TICKET';
export const DISABLE_CASH_BACK = 'DISABLE_CASH_BACK';
export const BOOK_BET = 'BOOK_BET';
export const SET_IS_EXPRESS_TICKET_MODAL_ACTIVE = 'SET_IS_EXPRESS_TICKET_MODAL_ACTIVE';
export const GET_EXPRESS_TICKET = 'GET_EXPRESS_TICKET';
export const SET_EXPRESS_TICKET_PARAMS = 'SET_EXPRESS_TICKET_PARAMS';
export const DEVICE_ME = 'DEVICE_ME';
export const SET_IS_LOGGED_IN = 'SET_IS_LOGGED_IN';
export const SET_DEVICE_TYPE = 'SET_DEVICE_TYPE';

export type LiveTrackerCheckResponse = {
  EventId: string | null;
};

export enum BetType {
  single = 'single',
  multi = 'multi',
  system = 'system',
}
export enum BonusOption {
  cashBack = 'CASH_BACK',
  multiBonus = 'MULTI_BONUS',
  cashbackUltra = 'CASH_BACK_ULTRA',
}

export enum BonusType {
  none = 0,
  cashBack = 1,
  multiBonus = 2,
  cashbackUltra = 5,
}

export enum TicketTab {
  betSlip = 'betSlip',
  liveInfo = 'liveInfo',
}

export type GetMaxBetPayload = {
  ticket: SubmitReadyTicket;
  isKeypad?: boolean;
};

export type TicketSystemOption = {
  min: number;
  total: number;
};
export type TicketSystem = {
  possibleOptions: TicketSystemOption[];
  selectedOption: TicketSystemOption | null;
  selectionInProgress: boolean;
  systemData: TicketSystemResponse | null;
};
export type TicketLimits = {
  deposit: {
    min: number;
    max: number;
    maxPerEvent: number;
    minPerEvent: number;
    maxPerCombo: number;
    minPerCombo: number;
  };
  maxPayout: number;
  maxNumberOfCombos: number;
  maxNumberOfEvents: number;
  minNumberOfEvents: number;
  payinTax: string;
};

export type TicketStoreState = {
  ticketItems: OtTicketItem[];
  prevTicketItems: OtTicketItem[];
  ticketSystem: TicketSystem;
  betType: BetType;
  activeTab: TicketTab;
  bonusOption: BonusOption;
  oddChangesAccepted: boolean;
  betAmount: number;
  betAmountKeypad: number;
  socketRoom: string;
  maxBetAmountSocketRoom: string;
  maxBetAmountKeypadSocketRoom: string;
  isStakeModalActive: boolean;
  locationParams: TicketParams;
  limits: TicketLimits;
  isLive: boolean;
  isAmountManuallyChanged: boolean;
  selectedEventHasLiveTracker: boolean;
  disableCashBackMsg: boolean;
  isExpressTicketModalActive: boolean;
  isLoggedIn: boolean;
  deviceType: DeviceType;
};

export type TicketMeta = Omit<TicketStoreState, 'ticketItems'>;

export type ExpressTicketParams = { eventId: string; oddsType: string }[];

export type SetTicketItemsAction = ActionWithIdentity<typeof SET_TICKET_ITEMS, TicketItem[]>;
export type SetTicketMetaAction = ActionWithIdentity<typeof SET_TICKET_META, TicketMeta>;
export type SetPossibleTicketSystemOptionsAction = ActionWithIdentity<
  typeof SET_POSSIBLE_TICKET_SYSTEM_OPTIONS,
  TicketSystemOption[]
>;
export type SelectTicketSystemOptionAction = ActionWithIdentity<
  typeof SELECT_TICKET_SYSTEM_OPTION,
  TicketSystemOption | null
>;
export type SetTicketSystemDataAction = ActionWithIdentity<typeof SET_TICKET_SYSTEM_DATA, TicketSystemResponse | null>;
export type UpdateTicketSystemSelectionStateAction = ActionWithIdentity<typeof UPDATE_TICKET_SELECTION_STATE, boolean>;
export type SetTicketBetTypeAction = ActionWithIdentity<typeof SET_TICKET_BET_TYPE, BetType>;
export type SetTicketActiveTabAction = ActionWithIdentity<typeof SET_TICKET_ACTIVE_TAB, TicketTab>;
export type SetTicketBonusOptionAction = ActionWithIdentity<typeof SET_TICKET_BONUS_OPTION, BonusOption>;
export type SetOddChangesAcceptedAction = ActionWithIdentity<typeof SET_ODD_CHANGES_ACCEPTED, boolean>;
export type UpdateBetAmountAction = ActionWithIdentity<typeof UPDATE_BET_AMOUNT, number>;
export type UpdateBetAmountKeypadAction = ActionWithIdentity<typeof UPDATE_BET_AMOUNT_KEYPAD, number>;
export type RefetchTicketItemsAction = ActionWithIdentity<typeof REFETCH_TICKET_ITEMS>;
export type UpdateTicketItemsAction = ActionWithIdentity<typeof UPDATE_TICKET_ITEMS, EventPresenterEvents>;
export type GetMaxBetAction = ActionWithIdentity<typeof GET_MAX_BET, GetMaxBetPayload>;
export type SetMaxBetAmountSocketRoomAction = ActionWithIdentity<typeof SET_MAX_BET_AMOUNT_SOCKET_ROOM, string>;
export type SetMaxBetAmountKeypadSocketRoomAction = ActionWithIdentity<
  typeof SET_MAX_BET_AMOUNT_KEYPAD_SOCKET_ROOM,
  string
>;
export type SetIsStakeModalActiveAction = ActionWithIdentity<typeof SET_IS_STAKE_MODAL_ACTIVE, boolean>;
export type SetLocationParamsAction = ActionWithIdentity<typeof SET_LOCATION_PARAMS, TicketParams>;
export type SetTicketParamsAction = ActionWithIdentity<typeof SET_TICKET_PARAMS>;
export type CheckLiveTrackerAction = ActionWithIdentity<
  typeof CHECK_LIVE_TRACKER,
  { eventId: string; sportId: string }
>;
export type SetSelectedEventHasLiveTrackerAction = ActionWithIdentity<
  typeof SET_SELECTED_EVENT_HAS_LIVE_TRACKER,
  boolean
>;
export type AddTicketItemAction = ActionWithIdentity<typeof ADD_TICKET_ITEM, TicketItem>;
export type ToggleOddAction = ActionWithIdentity<typeof TOGGLE_ODD, TicketItem>;
export type RemoveOddFromTicket = ActionWithIdentity<typeof REMOVE_ODD_FROM_TICKET, TicketItem>;
export type DisableCashBack = ActionWithIdentity<typeof DISABLE_CASH_BACK, boolean>;
export type BookBetAction = ActionWithIdentity<typeof BOOK_BET, SubmitReadyTicket>;
export type SetIsExpressTicketModalActive = ActionWithIdentity<typeof SET_IS_EXPRESS_TICKET_MODAL_ACTIVE, boolean>;
export type GetExpressTicket = ActionWithIdentity<typeof GET_EXPRESS_TICKET, string>;
export type SetExpressTicketParams = ActionWithIdentity<typeof SET_EXPRESS_TICKET_PARAMS, ExpressTicketParams>;
export type DeviceMeAction = ActionWithIdentity<typeof DEVICE_ME>;
export type SetIsLoggedInAction = ActionWithIdentity<typeof SET_IS_LOGGED_IN, boolean>;
export type SetDeviceTypeAction = ActionWithIdentity<typeof SET_DEVICE_TYPE, DeviceType>;

export type TicketAction =
  | SetTicketItemsAction
  | SetTicketMetaAction
  | SetPossibleTicketSystemOptionsAction
  | SelectTicketSystemOptionAction
  | SetTicketSystemDataAction
  | UpdateTicketSystemSelectionStateAction
  | SetTicketBetTypeAction
  | SetTicketActiveTabAction
  | SetTicketBonusOptionAction
  | SetOddChangesAcceptedAction
  | UpdateBetAmountAction
  | SubmitTicket
  | SetSocketRoom
  | UpdateTicketItemsAction
  | SetMaxBetAmountSocketRoomAction
  | SetMaxBetAmountKeypadSocketRoomAction
  | UpdateBetAmountKeypadAction
  | SetIsStakeModalActiveAction
  | SetLocationParamsAction
  | SetTicketParamsAction
  | CheckLiveTrackerAction
  | SetSelectedEventHasLiveTrackerAction
  | AddTicketItemAction
  | ToggleOddAction
  | RemoveOddFromTicket
  | BookBetAction
  | SetIsExpressTicketModalActive
  | GetExpressTicket
  | SetExpressTicketParams
  | DeviceMeAction
  | SetIsLoggedInAction
  | SetDeviceTypeAction;

export type TicketReducer = ContextReducer<TicketStoreState, TicketAction>;
