import { SOCKET_MESSAGE_TYPE } from '../types';
import { mapEventChange } from './event-change';
import { mapEventChangeLive } from './event-change-live';
import { mapEventChangePrematch } from './event-change-prematch';
import { mapEventResult } from './event-result';
import { mapNewEvent } from './new-event';
import { mapOddsChange } from './odds-change';

export const mappers = {
  [SOCKET_MESSAGE_TYPE.EVENT_CHANGE_LIVE]: mapEventChangeLive,
  [SOCKET_MESSAGE_TYPE.EVENT_CHANGE_PREMATCH]: mapEventChangePrematch,
  [SOCKET_MESSAGE_TYPE.EVENT_CHANGE]: mapEventChange,
  [SOCKET_MESSAGE_TYPE.EVENT_RESULT]: mapEventResult,
  [SOCKET_MESSAGE_TYPE.NEW_EVENT]: mapNewEvent,
  [SOCKET_MESSAGE_TYPE.ODDS_CHANGE]: mapOddsChange,
  [SOCKET_MESSAGE_TYPE.BET_ODDS]: mapOddsChange,
};
