import { BetshopType } from '../../../../shared-types/BetshopType';
import { ActionWithIdentity } from '../../../../shared/types/ActionWithIdentity';
import { TicketMeta as WebTicketMeta } from '../../../../themes/maxbet/widgets/Ticket/store/types';
import { TicketMeta as TerminalTicketMeta } from '../../../../themes/orion-tip/widgets/Ticket/store/types';

export const TICKET_META = 'TICKET_META';
export const SET_TICKET_META = 'SET_TICKET_META';
export type TicketMetaPayload =
  | { betshopType: BetshopType.terminal; payload: TerminalTicketMeta }
  | { betshopType: BetshopType.web; payload: WebTicketMeta }
  | null;
export type TicketMeta = ActionWithIdentity<typeof TICKET_META, TicketMetaPayload>;
export type SetTicketMeta = ActionWithIdentity<typeof SET_TICKET_META, TicketMetaPayload>;
type TicketMetaAction = TicketMeta | SetTicketMeta;

export type TicketMetaReducer = (state: TicketMetaPayload, action: TicketMetaAction) => TicketMetaPayload;
