import { ActionWithIdentity } from '../../../../shared/types/ActionWithIdentity';
import { DeviceType } from '../ticket-params/types';

export const SET_DEVICE_TYPE = 'SET_DEVICE_TYPE';
export const DEVICE_TYPE_SET = 'DEVICE_TYPE_SET';

export type DeviceState = {
  type: DeviceType;
};

export const initialDeviceState = {
  type: DeviceType.TERMINAL,
};

type DeviceMessage<T> = ActionWithIdentity<T, DeviceState>;

export type SetDeviceType = ActionWithIdentity<typeof SET_DEVICE_TYPE, DeviceType>;
export type DeviceTypeSet = DeviceMessage<typeof DEVICE_TYPE_SET>;

type DeviceAction = SetDeviceType | DeviceTypeSet;

export type DeviceReducer = (state: DeviceState, action: DeviceAction) => DeviceState;
