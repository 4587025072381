import { ACTIVATE_ALERT_MODAL, AlertModalReducer, DEACTIVATE_ALERT_MODAL } from './types';

export const alertModalReducer: AlertModalReducer = (state, action) => {
  switch (action.type) {
    case ACTIVATE_ALERT_MODAL:
      return { ...state, ...action.payload };
    case DEACTIVATE_ALERT_MODAL:
      return { ...state, message: null, options: {} };
    default:
      return state;
  }
};
