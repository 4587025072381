import { CLEAR_USER, SET_USER, UserReducer, UserState } from './types';

export const userInitialState: UserState = {
  user: {
    _id: '',
    username: '',
    email: '',
    profilePhoto: '',
    updatedAt: '',
  },
};

export const userReducer: UserReducer = (state = userInitialState, action) => {
  switch (action.type) {
    case SET_USER:
      return { ...state, user: action.payload };
    case CLEAR_USER:
      return { ...state, user: userInitialState.user };
    default:
      return state;
  }
};
