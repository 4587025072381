import {
  DRAG_FINISHED,
  DnDContextState,
  DnDReducer,
  SET_AVAILABLE_ZONES,
  SET_DROP_RESULT,
  SET_IS_DRAG_ALLOWED,
  SET_SELECTED_DROP_ZONE,
} from '../types';

export const initialDnDState: DnDContextState = {
  isDragAllowed: false,
  availableZones: [],
  dropResult: null,
  selectedDropZone: null,
};

export const dnDReducer: DnDReducer = (state, action) => {
  switch (action.type) {
    case SET_AVAILABLE_ZONES:
      return { ...state, availableZones: action.payload };
    case DRAG_FINISHED:
      return initialDnDState;
    case SET_IS_DRAG_ALLOWED:
      return { ...state, isDragAllowed: action.payload };
    case SET_DROP_RESULT:
      return { ...state, dropResult: action.payload };
    case SET_SELECTED_DROP_ZONE:
      return {
        ...state,
        selectedDropZone: state.selectedDropZone === action.payload ? null : action.payload,
      };
    default:
      return state;
  }
};
