import React, { FC } from 'react';
import { ToastProvider as Toast } from 'react-toast-notifications';

const ToastProvider: FC = ({ children }) => {
  return (
    <Toast autoDismiss autoDismissTimeout={5000}>
      {children}
    </Toast>
  );
};

export default ToastProvider;
