import { ActionWithIdentity } from '../../../../shared/types/ActionWithIdentity';

export const ACTIVATE_SPINNER = 'ACTIVATE_SPINNER';
export const DEACTIVATE_SPINNER = 'DEACTIVATE_SPINNER';
export const RESET_SPINNER = 'RESET_SPINNER';
export const SPINNER_STATE_SET = 'SPINNER_STATE_SET';

export type SpinnerState = {
  activeWidgetsIds: string[];
};

export type ActivateSpinner = ActionWithIdentity<typeof ACTIVATE_SPINNER, string>;
export type DeactivateSpinner = ActionWithIdentity<typeof DEACTIVATE_SPINNER, string>;
export type ResetSpinner = ActionWithIdentity<typeof RESET_SPINNER, null>;
export type SpinnerStateSet = ActionWithIdentity<typeof SPINNER_STATE_SET, SpinnerState>;

type SpinnerAction = ActivateSpinner | DeactivateSpinner | ResetSpinner;

export type SpinnerReducer = (state: SpinnerState, action: SpinnerAction) => SpinnerState;
