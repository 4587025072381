import { BehaviorSubject } from 'rxjs';

import { BROADCAST_MESSAGE } from '../types';
import { REGISTRATION_MODAL_STATE_SET, RegistrationModalState, RegistrationModalStateSet } from './types';

export const initialRegistrationModalMessage: RegistrationModalState = { isShown: false };

export type RegistrationModalStream = BehaviorSubject<RegistrationModalStateSet>;
export const RegistrationModalStream: RegistrationModalStream = new BehaviorSubject<RegistrationModalStateSet>({
  type: REGISTRATION_MODAL_STATE_SET,
  payload: initialRegistrationModalMessage,
  identity: BROADCAST_MESSAGE,
});
