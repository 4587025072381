import { ActionWithIdentity } from '../../../../shared/types/ActionWithIdentity';
import { TicketItems } from '../../../../themes/maxbet/widgets/Ticket/store/types';

export const WEB_TICKET_ITEMS = 'WEB_TICKET_ITEMS';
export const SET_WEB_TICKET_ITEMS = 'SET_WEB_TICKET_ITEMS';

export type WebTicketItemsStreamState = TicketItems | null;

export type SetWebTicketItems = ActionWithIdentity<typeof SET_WEB_TICKET_ITEMS, TicketItems | null>;
export type WebTicketItems = ActionWithIdentity<typeof WEB_TICKET_ITEMS, TicketItems | null>;

export type TicketMessage = SetWebTicketItems | WebTicketItems;

export type WebTicketItemsReducer = (
  state: WebTicketItemsStreamState,
  action: TicketMessage,
) => WebTicketItemsStreamState;
