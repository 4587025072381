import gql from 'graphql-tag';

export const ADD_PAGE = gql`
  mutation addPage($name: String!, $betshopId: String!) {
    addPage(name: $name, betshopId: $betshopId) {
      name
      pages {
        name
        slug
        layout {
          name
        }
        content {
          leftSidebar {
            visibleInLayouts
            content {
              id
              name
              availableIn
              component
              settings
            }
          }
          main {
            visibleInLayouts
            content {
              id
              type
              content {
                first {
                  id
                  name
                  availableIn
                  component
                  settings
                }
                second {
                  id
                  name
                  availableIn
                  component
                  settings
                }
              }
            }
          }
          rightSidebar {
            visibleInLayouts
            content {
              id
              name
              availableIn
              component
              settings
            }
          }
        }
      }
    }
  }
`;

export const REMOVE_PAGE = gql`
  mutation removePage($betshopId: String!, $slug: String!) {
    removePage(betshopId: $betshopId, slug: $slug) {
      name
      pages {
        name
        slug
        layout {
          name
        }
        content {
          leftSidebar {
            visibleInLayouts
            content {
              id
              name
              availableIn
              component
              settings
            }
          }
          main {
            visibleInLayouts
            content {
              id
              type
              content {
                first {
                  id
                  name
                  availableIn
                  component
                  settings
                }
                second {
                  id
                  name
                  availableIn
                  component
                  settings
                }
              }
            }
          }
          rightSidebar {
            visibleInLayouts
            content {
              id
              name
              availableIn
              component
              settings
            }
          }
        }
      }
    }
  }
`;
