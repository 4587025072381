import { TicketItem } from '../../../../shared/types/widget-types/TicketItem';
import { groupBy } from '../../../../utils/groupBy';
import {
  ADD_TO_TICKET,
  CLEAR_TICKET,
  REMOVE_FROM_TICKET,
  SET_TICKET_ITEMS,
  TOGGLE_ODD_TYPE,
  TicketItemsReducer,
  TicketItemsStreamState,
  ToggleOddType,
} from './types';

export enum TicketPresence {
  ABSENT,
  PRESENT_WITH_DIFFERENT_ODDTYPE,
  PRESENT_WITH_SAME_ODD_TYPE,
}
const getTicketPresence = (ticketItems: TicketItem[], ticketItem: TicketItem) => {
  return ticketItems.reduce((acc, { event: { id }, marketId, oddId }) => {
    const [, , inputEventPartId] = ticketItem.oddId.split(':');
    const [, , eventPartId] = oddId.split(':');

    if (id === ticketItem.event.id && inputEventPartId === eventPartId) {
      if (marketId === ticketItem.marketId && oddId === ticketItem.oddId) {
        return TicketPresence.PRESENT_WITH_SAME_ODD_TYPE;
      }
      return TicketPresence.PRESENT_WITH_DIFFERENT_ODDTYPE;
    }

    return acc;
  }, TicketPresence.ABSENT);
};
export const isItemPresentOnTicket = (ticket: TicketItem[], newItem: TicketItem) => {
  const {
    event: { id: eventId },
    marketId,
    oddId,
  } = newItem;

  return ticket.findIndex(ti => ti.event.id === eventId && ti.marketId === marketId && ti.oddId === oddId) > -1;
};

export const groupByPair = (ticketItems: TicketItem[]) => groupBy(ticketItems, i => i.oddId.split(':')[2]);

const removeTicketItem: (state: TicketItemsStreamState, ticketItem: TicketItem) => TicketItem[] = (
  state,
  ticketItem,
) => {
  return state.filter(
    ({ event, marketId, oddId }) =>
      event.id !== ticketItem.event.id || marketId !== ticketItem.marketId || oddId !== ticketItem.oddId,
  );
};

const groupedTicketItems = (state: TicketItemsStreamState, action: ToggleOddType) => {
  const [, , eventPartId] = action.payload.oddId.split(':');
  const groupedByPair = groupByPair(state);

  if (groupedByPair[eventPartId]) {
    const newGroupState = [
      ...groupedByPair[eventPartId].filter(({ event }) => event.id !== action.payload.event.id),
      action.payload,
    ];
    groupedByPair[eventPartId] = newGroupState;

    return Object.values(groupedByPair).flat();
  }

  return [...state, action.payload];
};

export const ticketItemsReducer: TicketItemsReducer = (state, action) => {
  switch (action.type) {
    case TOGGLE_ODD_TYPE:
      switch (getTicketPresence(state, action.payload)) {
        case TicketPresence.ABSENT:
          return [...state, action.payload];
        case TicketPresence.PRESENT_WITH_SAME_ODD_TYPE:
          return removeTicketItem(state, action.payload);
        case TicketPresence.PRESENT_WITH_DIFFERENT_ODDTYPE:
          return groupedTicketItems(state, action);
        default:
          return state;
      }
    case REMOVE_FROM_TICKET:
      return removeTicketItem(state, action.payload);
    case ADD_TO_TICKET:
      return isItemPresentOnTicket(state, action.payload) ? state : [...state, action.payload];
    case CLEAR_TICKET:
      return [];
    case SET_TICKET_ITEMS:
      return action.payload;
    default:
      return state;
  }
};
