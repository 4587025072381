import React, { FC, createContext, useCallback, useContext, useEffect, useMemo, useReducer } from 'react';
import { useCurrentRoute } from 'react-navi';

import { WidgetGraphql } from '../../../../graphql';
import { DropZoneType } from '../../../../shared-types/drop-zone-type';
import { WidgetType } from '../../../../shared-types/WidgetType';
import { LayoutPartName } from '../../../../shared/enums/layout-part-name';
import { MainElementRowPart } from '../../../../shared/enums/main-element-row-part';
import { clone } from '../../../../utils/clone';
import { createUUID } from '../../../../utils/createUUID';
import { insert } from '../../../../utils/insert';
import { moveFromSourceListToDestinationList } from '../../../../utils/moveFromSourceListToDestinationList';
import { moveListItem } from '../../../../utils/moveListItem';
import { useDnDContext } from '../../../dnd/state/context';
import { useBetshopPageEditContext } from '../../betshop-page-edit/state/context';
import { PageWidgetsActions, usePageWidgetsActions } from './actions';
import { initialPageWidgetsState, pageWidgetsReducer } from './reducer';
import { PageWidgetsState } from './types';

export type DropAreaInfo = [LayoutPartName, string | number, MainElementRowPart | undefined];
type AddWidget = (component: WidgetType, dropAreaInfo: DropAreaInfo, destination: number) => void;
type ResortWidgets = (dropAreaInfo: DropAreaInfo, source: number, destination: number) => void;
type UpdateWidgetSettings = <SettingsType>(settings: SettingsType) => void;

type MoveWidgetsBetweenAreas = (
  fromArea: DropAreaInfo,
  toArea: DropAreaInfo,
  source: number,
  destination: number,
) => void;
type RemoveWidget = (widget: WidgetGraphql, pageArea: DropAreaInfo) => void;
type BetshopPageWidgetsContext = {
  removeWidget: RemoveWidget;
  addWidgetToPage: AddWidget;
  updateWidget: UpdateWidgetSettings;
};

const BetshopPageWidgetsContext = createContext(
  {} as BetshopPageWidgetsContext & PageWidgetsState & PageWidgetsActions,
);

export const useBetshopPageWidgetsContext = () => useContext(BetshopPageWidgetsContext);
export const BetshopPageWidgetsProvider: FC = ({ children }) => {
  const [widgetsState, dispatch] = useReducer(pageWidgetsReducer, initialPageWidgetsState);
  const pageWidgetsActions = usePageWidgetsActions(widgetsState, dispatch);

  const { state, setBetshopPagesData } = useBetshopPageEditContext();
  const { dropResult, finishDrag } = useDnDContext();
  const {
    lastChunk: { request },
  } = useCurrentRoute();

  const pageSLug = useMemo(() => !!request && (request.params.pageSlug || ''), [request]);

  const { pages, page } = useMemo(() => {
    const allPages = clone(state.pages);
    const currentPage = allPages.find(({ slug }) => slug === pageSLug);

    return { pages: allPages, page: currentPage };
  }, [state, pageSLug]);

  const addWidgetToPage: AddWidget = useCallback(
    (component, dropAreaInfo, destination) => {
      if (!page) return;
      const [toLayoutElementType, toIndex, side] = dropAreaInfo;

      const widget = clone(state.activeTheme.availableWidgets.find(w => w.component === component));
      if (!widget) {
        return finishDrag();
      }
      widget.id = createUUID();

      if (toLayoutElementType === LayoutPartName.main && side) {
        page.content[toLayoutElementType].content[toIndex].content[side] = insert(
          page.content[toLayoutElementType].content[toIndex].content[side],
          widget,
          destination,
        );
      } else {
        page.content[toLayoutElementType].content = insert(
          page.content[toLayoutElementType].content as WidgetGraphql[],
          widget,
          destination,
        );
      }

      setBetshopPagesData({ ...state, pages });
      finishDrag();
    },
    [state, setBetshopPagesData, pages, page, finishDrag],
  );

  const resortWidgets: ResortWidgets = useCallback(
    (dropAreaInfo, source, destination) => {
      if (!page) return;
      const [toLayoutElementType, toIndex, side] = dropAreaInfo;
      if (toLayoutElementType === LayoutPartName.main && side) {
        page.content[toLayoutElementType].content[toIndex].content[side] = moveListItem(
          page.content[toLayoutElementType].content[toIndex].content[side],
          source,
          destination,
        );
      } else {
        page.content[toLayoutElementType].content = moveListItem(
          page.content[toLayoutElementType].content as WidgetGraphql[],
          source,
          destination,
        );
      }

      setBetshopPagesData({ ...state, pages });
      finishDrag();
    },
    [state, setBetshopPagesData, page, pages, finishDrag],
  );

  const moveWidgetBetweenAreas: MoveWidgetsBetweenAreas = useCallback(
    (fromArea, toArea, source, destination) => {
      if (!page) return;
      const [toLayoutElementType, toIndex, toSide] = toArea;
      const [fromLayoutElementType, fromIndex, fromSide] = fromArea;

      if (toLayoutElementType === LayoutPartName.main && toSide) {
        if (fromLayoutElementType === LayoutPartName.main && fromSide) {
          const moveResult = moveFromSourceListToDestinationList(
            page.content[fromLayoutElementType].content[fromIndex].content[fromSide],
            page.content[toLayoutElementType].content[toIndex].content[toSide],
            source,
            destination,
          );
          page.content[fromLayoutElementType].content[fromIndex].content[fromSide] = moveResult[0];
          page.content[toLayoutElementType].content[toIndex].content[toSide] = moveResult[1];
        } else {
          const moveResult = moveFromSourceListToDestinationList(
            page.content[fromLayoutElementType].content as WidgetGraphql[],
            page.content[toLayoutElementType].content[toIndex].content[toSide],
            source,
            destination,
          );
          page.content[fromLayoutElementType].content = moveResult[0];
          page.content[toLayoutElementType].content[toIndex].content[toSide] = moveResult[1];
        }
      } else {
        if (fromLayoutElementType === LayoutPartName.main && fromSide) {
          const moveResult = moveFromSourceListToDestinationList(
            page.content[fromLayoutElementType].content[fromIndex].content[fromSide],
            page.content[toLayoutElementType].content as WidgetGraphql[],
            source,
            destination,
          );
          page.content[fromLayoutElementType].content[fromIndex].content[fromSide] = moveResult[0];
          page.content[toLayoutElementType].content = moveResult[1];
        } else {
          const moveResult = moveFromSourceListToDestinationList(
            page.content[fromLayoutElementType].content as WidgetGraphql[],
            page.content[toLayoutElementType].content as WidgetGraphql[],
            source,
            destination,
          );
          page.content[fromLayoutElementType].content = moveResult[0];
          page.content[toLayoutElementType].content = moveResult[1];
        }
      }

      setBetshopPagesData({ ...state, pages });
      finishDrag();
    },
    [state, page, pages, setBetshopPagesData, finishDrag],
  );

  const removeWidget = useCallback(
    (widget: WidgetGraphql, pageArea: DropAreaInfo) => {
      if (!page) return;
      const [layoutPartName, index, mainElementRowPart] = pageArea;
      if (layoutPartName === LayoutPartName.main && mainElementRowPart) {
        page.content[layoutPartName].content[index].content[mainElementRowPart] = page.content[layoutPartName].content[
          index
        ].content[mainElementRowPart].filter(({ id }) => id !== widget.id);
      } else {
        page.content[layoutPartName].content = (page.content[layoutPartName].content as WidgetGraphql[]).filter(
          ({ id }) => id !== widget.id,
        );
      }

      setBetshopPagesData({ ...state, pages });
      finishDrag();
      pageWidgetsActions.clearWidgetFromSettings();
    },
    [state, page, pages, setBetshopPagesData, finishDrag, pageWidgetsActions],
  );

  const updateWidget: UpdateWidgetSettings = useCallback(
    settings => {
      const { widget, positionInfo } = widgetsState;
      if (!page || !widget || !positionInfo) return;

      let widgetToMutate: WidgetGraphql | undefined;
      const [layoutPartName, index, mainElementRowPart] = positionInfo;

      if (layoutPartName === LayoutPartName.main && mainElementRowPart) {
        widgetToMutate = page.content[layoutPartName].content[index].content[mainElementRowPart].find(
          ({ id }) => id === widget.id,
        );
      } else {
        widgetToMutate = (page.content[layoutPartName].content as WidgetGraphql[]).find(({ id }) => id === widget.id);
      }

      if (widgetToMutate) {
        widgetToMutate.settings = settings;
        setBetshopPagesData({ ...state, pages });
        pageWidgetsActions.setWidgetsForSettings(widgetToMutate, positionInfo);
      }
    },
    [state, page, pages, setBetshopPagesData, widgetsState, pageWidgetsActions],
  );

  useEffect(() => {
    if (dropResult && dropResult.type === DropZoneType.widgets) {
      const component = dropResult.movedItemId as WidgetType;
      const toArea = dropResult.movedTo.split('-') as DropAreaInfo;
      const fromArea = dropResult.movedFrom.split('-') as DropAreaInfo;

      if (dropResult.movedFrom === dropResult.movedTo) {
        resortWidgets(toArea, dropResult.source, dropResult.destination);
      } else if (dropResult.movedFrom !== dropResult.movedTo && !dropResult.movedFrom.includes(DropZoneType.widgets)) {
        moveWidgetBetweenAreas(fromArea, toArea, dropResult.source, dropResult.destination);
      } else {
        addWidgetToPage(component, toArea, dropResult.destination);
      }
      pageWidgetsActions.clearWidgetFromSettings();
    }
  }, [dropResult, finishDrag, state, addWidgetToPage, resortWidgets, moveWidgetBetweenAreas, pageWidgetsActions]);

  /*
      If we add pageWidgetsActions or pageWidgetsActions.clearWidgetFromSettings we loose wanted effect
      since the change will fire always what context state changes, and we dont want that
  */
  /* eslint-disable */
  useEffect(() => {
    pageWidgetsActions.clearWidgetFromSettings();
  }, [request]);

  useEffect(() => {
    if (!page) {
      return;
    }

    const widgets = [
      page.content.leftSidebar.content,
      page.content.rightSidebar.content,
      page.content.main.content.map(({ content }) => Object.entries(content).map(([, v]) => v)),
    ]
      .flat(10)
      .filter(o => o.id);
    pageWidgetsActions.setAllPageWidgets(widgets);
  }, [page]);
  /* eslint-enable */

  return (
    <BetshopPageWidgetsContext.Provider
      value={{
        removeWidget,
        addWidgetToPage,
        updateWidget,
        ...pageWidgetsActions,
        ...widgetsState,
      }}
    >
      {children}
    </BetshopPageWidgetsContext.Provider>
  );
};
