import { Dispatch, useCallback } from 'react';

import { Betshop } from '../../../../shared-types/graphql';
import {
  BetshopPageEditAction,
  BetshopPageEditActions,
  SET_BETSHOP_NAVI_ITEMS_TEXT,
  SET_BETSHOP_PAGES_DATA,
  SET_BETSHOP_PAGE_NAME,
  SetBetshopNaviItemsText,
  SetBetshopPageName,
  SetBetshopPagesData,
} from './types';

type UseBetshopPageEditActions = (state: Betshop, dispatch: Dispatch<BetshopPageEditAction>) => BetshopPageEditActions;
export const useBetshopPageEditActions: UseBetshopPageEditActions = (_state, dispatch) => {
  const setBetshopPagesData: SetBetshopPagesData = useCallback(
    pages => {
      dispatch({
        type: SET_BETSHOP_PAGES_DATA,
        payload: pages,
      });
    },
    [dispatch],
  );

  const setBetshopPageName: SetBetshopPageName = useCallback(
    page => {
      dispatch({
        type: SET_BETSHOP_PAGE_NAME,
        payload: page,
      });
    },
    [dispatch],
  );

  const setBetshopNaviItemsText: SetBetshopNaviItemsText = useCallback(
    page => {
      dispatch({
        type: SET_BETSHOP_NAVI_ITEMS_TEXT,
        payload: page,
      });
    },
    [dispatch],
  );

  return {
    setBetshopPagesData,
    setBetshopPageName,
    setBetshopNaviItemsText,
  };
};
