import { BROADCAST_MESSAGE } from '../types';
import { DEVICE_TYPE_SET, DeviceState, DeviceTypeSet, SET_DEVICE_TYPE, SetDeviceType } from './types';

export type DispatchSetDeviceType = (type: DeviceState['type']) => SetDeviceType;
export const dispatchSetDeviceType: DispatchSetDeviceType = type => ({
  type: SET_DEVICE_TYPE,
  payload: type,
  identity: BROADCAST_MESSAGE,
});

export type DispatchDeviceTypeSet = (state: DeviceState) => DeviceTypeSet;
export const dispatchDeviceTypeSet: DispatchDeviceTypeSet = state => ({
  type: DEVICE_TYPE_SET,
  payload: state,
  identity: BROADCAST_MESSAGE,
});
