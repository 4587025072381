import { BetshopType } from '../features/betshop/betshop-listing/state/types';
import { MaxbetBaseColors, MaxbetFooterColors, MaxbetHeaderColors } from './maxbet-colors';
import { OrionTipBaseColors } from './oriontip-colors';

export type ColorNames<
  Name1 extends number | string,
  Name2 extends number | string,
  Name3 extends number | string,
  Name4 extends number | string
> = {
  [key in Name1 | Name2 | Name3 | Name4]: string;
};
export type BaseColors = Partial<
  ColorNames<keyof MaxbetBaseColors, keyof OrionTipBaseColors, keyof MaxbetHeaderColors, keyof MaxbetFooterColors>
>;

export enum ThemeOption {
  Maxbet = 'maxbet',
  OrionTip = 'orion-tip',
}

export enum ColorOption {
  MaxbetBase = 'maxbet-base',
  MaxbetHeader = 'maxbet-header',
  MaxbetFooter = 'maxbet-footer',
  OrionTipBase = 'oriontip-base',
}
type MaxbetBaseOptions = {
  kind: ColorOption.MaxbetBase;
  color: keyof MaxbetBaseColors;
};
type MaxbetHeaderOptions = {
  kind: ColorOption.MaxbetHeader;
  color: keyof MaxbetHeaderColors;
};
type MaxbetFooterOptions = {
  kind: ColorOption.MaxbetFooter;
  color: keyof MaxbetFooterColors;
};
type OrionTipBaseOptions = {
  kind: ColorOption.OrionTipBase;
  color: keyof OrionTipBaseColors;
};
export type ColorOptions = MaxbetBaseOptions | MaxbetHeaderOptions | MaxbetFooterOptions | OrionTipBaseOptions;

export type BaseColorScheme = {
  theme: ThemeOption;
  colors: BaseColors;
  colorOrder: (keyof BaseColors)[];
};

export type HeaderColors = Partial<MaxbetHeaderColors>;
export type HeaderColorScheme = {
  colors: HeaderColors;
  colorOrder: (keyof HeaderColors)[];
};

export type FooterColors = Partial<MaxbetFooterColors>;
export type FooterColorScheme = {
  colors: FooterColors;
  colorOrder: (keyof FooterColors)[];
};

type Colors = {
  base: BaseColors;
  header?: HeaderColors;
  footer?: FooterColors;
};
export type Theme = {
  colors: Colors;
  type: BetshopType;
};
