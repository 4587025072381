import { SET_SELECTED_MARKETS, SelectedMarketsReducer } from './types';

export const selectedMarketsReducer: SelectedMarketsReducer = (state, action) => {
  switch (action.type) {
    case SET_SELECTED_MARKETS:
      const { isLive, selectedMarkets } = action.payload;
      return {
        ...state,
        selectedMarkets: {
          ...state.selectedMarkets,
          [isLive ? 'live' : 'prematch']: selectedMarkets,
        },
      };
  }
};
