import {
  LIVE_EVENTS_RELOADED,
  LiveEventsReloaded,
  RELOAD_LIVE_EVENTS,
  ReloadLiveEvents,
  SidebarLabelReloadLiveEvents,
} from './types';

export const reloadLiveEvents: (identity: string) => ReloadLiveEvents = identity => ({
  type: RELOAD_LIVE_EVENTS,
  identity,
  payload: Date.now(),
});

export const liveEventsReloaded: (identity: string, payload: SidebarLabelReloadLiveEvents) => LiveEventsReloaded = (
  identity,
  payload,
) => ({
  type: LIVE_EVENTS_RELOADED,
  payload,
  identity,
});
