import { FooterColorScheme, HeaderColorScheme } from './color-scheme';

export type NavigationItem = {
  text: string;
  link: string;
  isExternal?: boolean;
  isHighlighted?: boolean;
};

export enum NavigationType {
  HEADER = 'HEADER',
  FOOTER = 'FOOTER',
}

export type NavigationRow = {
  items: NavigationItem[];
};

export type Header = {
  navigation: NavigationRow[];
  colorScheme: HeaderColorScheme;
};

export type Footer = {
  navigation: NavigationRow[];
  colorScheme: FooterColorScheme;
};
