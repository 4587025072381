import { Subject } from 'rxjs';

import { environment } from '../../../shared/environment';
import { EventChangeMessage, EventResultMessage, NewEventMessage, OddsChangeMessage } from './types';

type Messages = OddsChangeMessage | EventChangeMessage | EventResultMessage | NewEventMessage;
export class SocketUpdates {
  data: Messages[] = [];
  socket: Subject<Messages[]>;
  interval: NodeJS.Timeout;

  constructor(socket) {
    this.socket = socket;
  }

  clearData() {
    this.data = [];
  }

  bufferData(update: Messages) {
    if (!!update.id) this.data.push(update);
  }

  start() {
    console.info('Starting update interval');
    this.interval = setInterval(() => {
      if (!this.data.length) return;
      this.socket.next(this.data);
      this.clearData();
    }, environment.updateInterval);
  }

  end() {
    console.info('Clearing update interval');
    clearInterval(this.interval);
  }
}
