import React, { FC, createContext, useContext, useReducer } from 'react';

import * as config from '../../../build-config.json';
import { Betshop } from '../../../shared-types/graphql';
import { ConfigState } from '../types';
import { SetBetshopConfig, useConfigActions } from './actions';
import { configReducer, initialConfigState } from './reducer';

type ConfigContext = {
  state: ConfigState;
  setBetshopConfig: SetBetshopConfig;
};

const betshopConfig = (config as unknown) as Betshop;

const getBetshopConfig = (): Betshop => {
  return betshopConfig._id ? betshopConfig : initialConfigState.betshopConfig;
};

const Context = createContext({} as ConfigContext);

export const useConfigContext = () => useContext(Context);

export const ConfigContextProvider: FC = ({ children }) => {
  const [state, dispatch] = useReducer(configReducer, {
    betshopConfig: getBetshopConfig(),
  });
  const { setBetshopConfig } = useConfigActions(state, dispatch);

  return <Context.Provider value={{ state, setBetshopConfig }}>{children}</Context.Provider>;
};
