import { BetshopListingReducer, BetshopListingState, SET_BETSHOPS } from './types';

export const betshopListingInitialState: BetshopListingState = {
  betshops: [],
};

export const betshopListingReducer: BetshopListingReducer = (state = betshopListingInitialState, action) => {
  switch (action.type) {
    case SET_BETSHOPS:
      return { ...state, betshops: action.payload };
    default:
      return state;
  }
};
