import { SelectorView } from '../view-selector/types';
import {
  SET_BOOKMARKED_SPORTS,
  SET_BOOKMARKED_TOURNAMENTS,
  SET_FAVORITE_EVENTS,
  SET_SELECTED_EVENTS,
  SET_SELECTED_SPORT,
  SET_SELECTED_SPORTS,
  SET_SELECTED_TOURNAMENTS_BROADCAST,
  SET_SPORTS_ORDER,
  SET_SPORT_FILTER_LIVE_STATUS,
  SportFilterReducer,
} from './types';

const initialState = {
  selectedSport: null,
  selectedSports: [],
  selectedSportsAnnouncement: [],
  selectedTournaments: {},
  bookmarkedTournaments: {},
  bookmarkedSports: [],
  bookmarkedSportsLive: [],
  sportsOrder: [],
  favoriteLiveEvents: [],
  selectedEvents: [],
  isLive: false,
};

export const sportFilterReducer: SportFilterReducer = (state, action) => {
  switch (action.type) {
    case SET_SELECTED_SPORT:
      return {
        ...state,
        [action.identity]: {
          ...(state[action.identity] ? state[action.identity] : initialState),
          selectedSport: action.payload,
        },
      };
    case SET_SELECTED_SPORTS: {
      const { sportIds, selectedView } = action.payload;
      const { selectedSports, selectedSportsAnnouncement } = state[action.identity] || initialState;
      return {
        ...state,
        [action.identity]: {
          ...(state[action.identity] ? state[action.identity] : initialState),
          selectedSportsAnnouncement:
            selectedView && selectedView === SelectorView.Announcement ? sportIds : selectedSportsAnnouncement,
          selectedSports: !selectedView || selectedView === SelectorView.Overview ? sportIds : selectedSports,
        },
      };
    }
    case SET_SELECTED_TOURNAMENTS_BROADCAST:
      return {
        ...state,
        [action.identity]: {
          ...(state[action.identity] ? state[action.identity] : initialState),
          selectedTournaments: action.payload,
        },
      };
    case SET_BOOKMARKED_TOURNAMENTS:
      return {
        ...state,
        [action.identity]: {
          ...(state[action.identity] ? state[action.identity] : initialState),
          bookmarkedTournaments: action.payload,
        },
      };
    case SET_BOOKMARKED_SPORTS:
      const { isLive, sportIds } = action.payload;
      const update = isLive ? { bookmarkedSportsLive: sportIds } : { bookmarkedSports: sportIds };
      return {
        ...state,
        [action.identity]: {
          ...(state[action.identity] ? state[action.identity] : initialState),
          ...update,
        },
      };
    case SET_SPORTS_ORDER:
      return {
        ...state,
        [action.identity]: {
          ...(state[action.identity] ? state[action.identity] : initialState),
          sportsOrder: action.payload,
        },
      };
    case SET_SPORT_FILTER_LIVE_STATUS:
      return {
        ...state,
        [action.identity]: {
          ...(state[action.identity] ? state[action.identity] : initialState),
          isLive: action.payload,
        },
      };
    case SET_FAVORITE_EVENTS:
      return {
        ...state,
        [action.identity]: {
          ...(state[action.identity] ? state[action.identity] : initialState),
          favoriteLiveEvents: action.payload,
        },
      };
    case SET_SELECTED_EVENTS:
      return {
        ...state,
        [action.identity]: {
          ...(state[action.identity] ? state[action.identity] : initialState),
          selectedEvents: action.payload,
        },
      };
    default:
      return state;
  }
};
