import gql from 'graphql-tag';

export const GET_BETSHOPS = gql`
  query {
    betshops {
      _id
      name
      logo
      pages {
        name
        slug
      }
    }
  }
`;

export const CREATE_BETSHOP = gql`
  mutation createBetshop($name: String!, $type: String!, $provider: String!) {
    createBetshop(name: $name, type: $type, provider: $provider) {
      _id
      name
      logo
      type
    }
  }
`;

export const DELETE_BETSHOP = gql`
  mutation deleteBetshop($_id: String!) {
    deleteBetshop(_id: $_id) {
      status
    }
  }
`;
