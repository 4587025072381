type StoreInStorage = <T>(key: string, item: T) => void;
type GetValueForKeyInStorage = <T>(key: string) => T | null;

export const storeInSessionStorage: StoreInStorage = (key, item) => {
  sessionStorage.setItem(key, JSON.stringify(item));
};

export const getValueForKeyInSessionStorage: GetValueForKeyInStorage = key => {
  const item = sessionStorage.getItem(key);

  return item ? JSON.parse(item) : null;
};

export const storeInLocalStorage: StoreInStorage = (key, item) => {
  localStorage.setItem(key, JSON.stringify(item));
};

export const getValueForKeyInLocalStorage: GetValueForKeyInStorage = key => {
  const item = localStorage.getItem(key);

  return item ? JSON.parse(item) : null;
};
