import { clone } from './clone';

export const moveFromSourceListToDestinationList = <T>(
  sourceList: T[],
  destinationList: T[],
  sourceItemIndex: number,
  destinationIndex: number,
): [T[], T[]] => {
  const item = clone(sourceList[sourceItemIndex]);
  return [
    sourceList.filter((_, index) => index !== sourceItemIndex),
    [...destinationList.slice(0, destinationIndex), item, ...destinationList.slice(destinationIndex)],
  ];
};
