import { compose, mount, withTitle, withView } from 'navi';
import React, { ComponentProps, FC, lazy } from 'react';
import { View } from 'react-navi';

import { Betshop } from '../shared-types/graphql';
import { environment } from '../shared/environment';
import { generateBethopRoutes } from './routes/betshop';
import { cmsRoutes } from './routes/cms';
import { sharedRoutes } from './routes/shared';
import { RoutesConfig } from './types/RoutesConfig';
import { RouteWithView } from './types/RouteWithView';

const RouteGuard = environment.isCms
  ? lazy(() => import('../features/authentication/components/AuthGuard'))
  : ({ children }: ComponentProps<FC>) => <>{children}</>;

const createRoute = (routes: RoutesConfig, { path, component, title }: RouteWithView): RoutesConfig => {
  return { ...routes, ...{ [path]: withTitle(title, component()) } };
};

export const configureRoutes = (routes: RouteWithView[]): RoutesConfig =>
  [...sharedRoutes, ...routes].reduce(createRoute, {});

const generateRoutes = (betshopConfig: Betshop) => {
  return compose(
    withView(() => (
      <RouteGuard>
        <View />
      </RouteGuard>
    )),
    mount(configureRoutes(environment.isCms ? cmsRoutes : generateBethopRoutes(betshopConfig))),
  );
};

export default generateRoutes;
