import gql from 'graphql-tag';

const BETSHOP_SELECTION = `{
  _id
  name
  logo
  type
  provider
  header
  footer
  pages {
    name
    slug
    layout {
      name
    }
    content {
      leftSidebar {
        visibleInLayouts
        content {
          id
          name
          availableIn
          component
          settings
        }
      }
      main {
       visibleInLayouts
       content {
         id
         type
         content {
             first {
               id
               name
               availableIn
               component
               settings
             }
             second {
               id
               name
               availableIn
               component
               settings
             }
           }
         }
      }
      rightSidebar {
        visibleInLayouts
        content {
          id
          name
          availableIn
          component
          settings
        }
      }
    }
  }
  colorScheme
  activeTheme {
    _id
    name
    colorScheme
    layouts {
      name
    }
    availableWidgets {
      name
      availableIn
      component
      settings
    }
  }
}
`;

export const GET_BETSHOP_PAGES = gql`
  query betshop($_id: String!) {
    betshop(_id: $_id) ${BETSHOP_SELECTION}
  }
`;

export const UPDATE_PAGES = gql`
  mutation updateBetshop($betshopPagesData: UpdateBetshopPagesInput!) {
    updateBetshopPages(betshopPagesData: $betshopPagesData) ${BETSHOP_SELECTION}
  }
`;
