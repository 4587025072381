import React, { FC, createContext, useContext, useReducer } from 'react';

import { Betshop } from '../../../../shared-types/graphql';
import { NavigationType } from '../../../../shared-types/header-footer';
import { ReFetchQuery } from '../../../../shared/types/RefetchQuery';
import { useBetshopGraphQL } from '../graphql/useBetshopGraphql';
import { UpdateBetshopLogo } from '../types';
import { betshopEditReducer, initialBetshopEditState } from './reducer';
import { BetshopEditActions } from './types';
import { NavigationItemData } from './types';

type BetshopEditContext = {
  state: Betshop;
  updateBetshop: () => void;
  refetchBetshop: ReFetchQuery;
  updateBetshopLogo: UpdateBetshopLogo;
  addToNavi: (type: NavigationType) => (itemData: NavigationItemData) => void;
  removeFromNavi: (type: NavigationType) => (itemData: NavigationItemData) => void;
  toggleHeaderItemHighlight: (type: NavigationType) => (itemData: NavigationItemData) => void;
} & Omit<
  BetshopEditActions,
  'addToBetshopNavigationItems' | 'removeFromBetshopNavigationItems' | 'toggleBetshopHeaderItemHighlight'
>;

const BetshopEditContext = createContext({} as BetshopEditContext);

export const useBetshopEditContext = () => useContext(BetshopEditContext);
export const BetshopEditContextProvider: FC = ({ children }) => {
  const [state, dispatch] = useReducer(betshopEditReducer, initialBetshopEditState);
  const {
    updateBetshopAndRefetch,
    addToBetshopNavigationItems,
    removeFromBetshopNavigationItems,
    toggleBetshopHeaderItemHighlight,
    ...editBetshopActions
  } = useBetshopGraphQL(state, dispatch);

  const updateBetshop = () => {
    if (state._id) {
      const { _id, name, colorScheme, header, footer, activeTheme: theme, logo } = state;
      theme &&
        updateBetshopAndRefetch({
          _id,
          name,
          logo,
          colorScheme,
          header,
          footer,
          activeTheme: theme._id,
        });
    }
  };

  const addToNavi = (type: NavigationType) => (itemData: NavigationItemData) => {
    addToBetshopNavigationItems({
      type,
      ...itemData,
    });
  };

  const removeFromNavi = (type: NavigationType) => (itemData: NavigationItemData) => {
    removeFromBetshopNavigationItems({
      type,
      ...itemData,
    });
  };

  const toggleHeaderItemHighlight = (type: NavigationType) => (itemData: NavigationItemData) => {
    toggleBetshopHeaderItemHighlight({
      type,
      ...itemData,
    });
  };

  return (
    <BetshopEditContext.Provider
      value={{
        state,
        updateBetshop,
        addToNavi,
        removeFromNavi,
        toggleHeaderItemHighlight,
        ...editBetshopActions,
      }}
    >
      {children}
    </BetshopEditContext.Provider>
  );
};
