import { Dispatch, useEffect } from 'react';
import { useLazyQuery, useMutation } from 'react-apollo';
import { useCurrentRoute } from 'react-navi';

import { cmsDashboardRoute } from '../../../../routing/routes/cms';
import { Betshop } from '../../../../shared-types/graphql';
import { ReFetchQuery } from '../../../../shared/types/RefetchQuery';
import { useBetshopListingActions } from '../state/actions';
import {
  BetshopListingAction,
  BetshopListingState,
  CreateBetshopAndRefetch,
  DeleteBetshopAndRefetch,
} from '../state/types';
import { CREATE_BETSHOP, DELETE_BETSHOP, GET_BETSHOPS } from './queries';

type GetBetshops = {
  betshops: Betshop[];
};

type CreateBetshop = { createBetshop: Betshop };

type UseBetshopsGraphQL = (
  state: BetshopListingState,
  dispatch: Dispatch<BetshopListingAction>,
) => BetshopGraphqlActions;

export type BetshopGraphqlActions = {
  createBetshopAndRefetch: CreateBetshopAndRefetch;
  deleteBetshopAndRefetch: DeleteBetshopAndRefetch;
  refetchBetshops: ReFetchQuery;
};

export const useBetshopsGraphQL: UseBetshopsGraphQL = (state, dispatch) => {
  const { setBetshops } = useBetshopListingActions(state, dispatch);
  const [getBetshops, { data }] = useLazyQuery<GetBetshops>(GET_BETSHOPS);
  const refetchBetshops: ReFetchQuery = {
    refetchQueries: [{ query: GET_BETSHOPS }],
  };
  const [createBetshop] = useMutation<CreateBetshop>(CREATE_BETSHOP, refetchBetshops);
  const [removeBetshop] = useMutation(DELETE_BETSHOP, refetchBetshops);

  const {
    url: { pathname },
  } = useCurrentRoute();
  useEffect(() => {
    if (pathname === cmsDashboardRoute.path) {
      getBetshops();
      if (data) {
        setBetshops(data.betshops);
      }
    }
  }, [pathname, data, getBetshops, setBetshops]);

  const createBetshopAndRefetch: CreateBetshopAndRefetch = async (name, type, provider) => {
    const createBetshopResponse = await createBetshop({
      variables: { name, type, provider },
    });
    return createBetshopResponse && createBetshopResponse.data && createBetshopResponse.data.createBetshop._id;
  };

  const deleteBetshopAndRefetch: DeleteBetshopAndRefetch = _id => {
    removeBetshop({ variables: { _id } });
  };

  return {
    createBetshopAndRefetch,
    deleteBetshopAndRefetch,
    refetchBetshops,
  };
};
