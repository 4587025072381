import { HIDE_REGISTRATION_MODAL, RegistrationModalReducer, SHOW_REGISTRATION_MODAL } from './types';

export const registrationModalReducer: RegistrationModalReducer = (state, action) => {
  switch (action.type) {
    case SHOW_REGISTRATION_MODAL:
      return { ...state, isShown: true };
    case HIDE_REGISTRATION_MODAL:
      return { ...state, isShown: false };
    default:
      return state;
  }
};
