import { BROADCAST_MESSAGE } from '../types';
import { SET_TICKET_PARAMS, SetTicketParams, TICKET_PARAMS_SET, TicketParams, TicketParamsSet } from './types';

export type SetTicketParamsState = (params: TicketParams) => SetTicketParams;
export const dispatchSetTicketParamsState: SetTicketParamsState = params => ({
  type: SET_TICKET_PARAMS,
  payload: params,
  identity: BROADCAST_MESSAGE,
});

export type TicketParamsStateSet = (params: TicketParams) => TicketParamsSet;
export const dispatchTicketParamsStateSet: TicketParamsStateSet = params => ({
  type: TICKET_PARAMS_SET,
  payload: params,
  identity: BROADCAST_MESSAGE,
});
