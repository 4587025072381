/* eslint-disable @typescript-eslint/ban-ts-ignore */
import { BehaviorSubject, Observable, empty } from 'rxjs';
import { catchError, filter, map, switchMap, withLatestFrom } from 'rxjs/operators';

import { BetshopType } from '../../../shared-types/BetshopType';
import { Markets } from '../../../shared/types/widget-types/Markets';
import { MarketsResponse } from '../../../shared/types/widget-types/response/MarketsResponse';
import { HTTP } from '../http';
import { Settings } from '../settings';

const mapMarkets = (res: MarketsResponse, type: BetshopType): Markets | null => {
  return !res
    ? null
    : {
        type,
        sports: Object.entries(res).reduce(
          (
            acc,
            [
              sportId,
              {
                markets: { markets, favorites },
              },
            ],
          ) => ({ ...acc, [sportId]: { markets, favorites } }),
          {},
        ),
      };
};

const processMarketsResponse = (res: MarketsResponse | null, type: BetshopType): Markets | null =>
  mapMarkets(res as MarketsResponse, type);

export type MarketsState = {
  markets$: Observable<Markets | null>;
};

const MarketsStream = new BehaviorSubject<Markets | null>(null);
export const markets$ = MarketsStream.asObservable();

// const REFRESH_INTERVAL = (environment.marketsRefreshInterval || 1) * 60 * 60 * 1000;

const markets = (http: HTTP, locale: Observable<string>, settings: Observable<Settings | null>): MarketsState => {
  locale
    .pipe(
      switchMap(lang => {
        return http.get<MarketsResponse>(`markets?extended=false&lang=${lang}&platform=web`).pipe(
          catchError(e => {
            console.error('---- Markets ----');
            console.error(e);

            return empty();
          }),
        );

        // const dbData$ = from(idb.markets.get(lang));

        // return dbData$.pipe(
        //   switchMap(dbResult => {
        //     if (dbResult && Date.now() - dbResult.updated_at <= REFRESH_INTERVAL) {
        //       return of(dbResult.markets);
        //     } else {
        //       return http.get<MarketsResponse>(`markets?extended=false&lang=${lang}&platform=web`).pipe(
        //         catchError(e => {
        //           console.error('---- Markets ----');
        //           console.error(e);

        //           return empty();
        //         }),
        //         tap(markets => {
        //           idb.markets.put({ id: lang, markets, updated_at: Date.now() });
        //         }),
        //       );
        //     }
        //   }),
        // );
      }),
      withLatestFrom(settings),
      filter(([_, settings]) => !!settings),
      // @ts-ignore
      map(([markets, { betshopType }]) => processMarketsResponse(markets, betshopType)),
    )
    .subscribe(markets => MarketsStream.next(markets));

  return { markets$ };
};

export default markets;
