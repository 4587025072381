import { Betshop } from '../../../../shared-types/graphql';

export type CreateBetshopAndRefetch = (
  betshopName: string,
  betshopType: string,
  provider: string,
) => Promise<string | undefined>;
export type DeleteBetshopAndRefetch = (betshopId: string) => void;

export type BetshopType = 'terminal' | 'web' | 'mobile' | 'hybrid';

export type BetshopListingState = {
  betshops: Betshop[];
};

export const SET_BETSHOPS = 'SET_BETSHOPS';
export type SetBetshopsAction = {
  type: typeof SET_BETSHOPS;
  payload: Betshop[];
};

export type BetshopListingAction = SetBetshopsAction;

export type BetshopListingReducer = (state: BetshopListingState, action: BetshopListingAction) => BetshopListingState;
