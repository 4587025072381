import { combineComponents } from '../../utils/combineComponents';
import { BetshopBuildContextProvider } from './betshop-build/state/context';
import { BetshopEditContextProvider } from './betshop-edit/state/context';
import { BetshopListingContextProvider } from './betshop-listing/state/context';
import { BetshopPageEditContextProvider } from './betshop-page-edit/state/context';
import { BetshopPageWidgetsProvider } from './betshop-page-widgets/state/context';
import { BetshopPreviewContextProvider } from './betshop-preview/state/context';

export const BetshopContextProvider = combineComponents(
  BetshopListingContextProvider,
  BetshopEditContextProvider,
  BetshopPreviewContextProvider,
  BetshopBuildContextProvider,
  BetshopPageEditContextProvider,
  BetshopPageWidgetsProvider,
);
