import { storeInBrowserStorage } from '../../../../utils/browserStorage';
import { AuthBrowserStorageKeys } from '../../types';
import { SET_ACCESS_TOKEN, SET_REFRESH_TOKEN, SetAccessToken, SetRefreshToken, UseAuthActions } from './types';

export const useAuthActions: UseAuthActions = (_state, dispatch) => {
  const setAccessToken: SetAccessToken = token => {
    storeInBrowserStorage(AuthBrowserStorageKeys.token, token);

    dispatch({
      type: SET_ACCESS_TOKEN,
      payload: token,
    });
  };

  const setRefreshToken: SetRefreshToken = token => {
    storeInBrowserStorage(AuthBrowserStorageKeys.refreshToken, token);

    dispatch({
      type: SET_REFRESH_TOKEN,
      payload: token,
    });
  };

  return { setAccessToken, setRefreshToken };
};
