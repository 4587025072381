import React, { FC, createContext } from 'react';

import { useGetBetshopGraphql } from '../queries/useGetBetshopGraphql';

const BetshopPreviewContext = createContext({});

export const BetshopPreviewContextProvider: FC = ({ children }) => {
  useGetBetshopGraphql();

  return <BetshopPreviewContext.Provider value={{}}>{children}</BetshopPreviewContext.Provider>;
};
