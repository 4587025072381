import React, { FC, useContext, useEffect, useReducer } from 'react';
import { useLazyQuery } from 'react-apollo';

import { GET_USER_DATA, GetUserDataQuery } from '../../graphql';
import { useAuthContext } from '../auth/context';
import { useUserActions } from './actions';
import { userInitialState, userReducer } from './reducer';
import { UserState } from './types';

type UserContext = {
  state: UserState;
};

const UserContext = React.createContext({} as UserContext);

export const useUserContext = () => useContext(UserContext);

export const UserContextProvider: FC = ({ children }) => {
  const [state, dispatch] = useReducer(userReducer, userInitialState);
  const { setUser, clearUser } = useUserActions(state, dispatch);
  const {
    state: { token },
  } = useAuthContext();

  const [getUserData, { data }] = useLazyQuery<GetUserDataQuery>(GET_USER_DATA);

  useEffect(() => {
    token.length > 0 ? getUserData() : clearUser();
  }, [token, getUserData, clearUser]);

  useEffect(() => {
    if (data && data.me) {
      setUser({
        _id: data.me._id,
        username: data.me.username,
        // TODO: Connect real data from the backend when SSO is implemented
        email: '',
        profilePhoto: '',
        updatedAt: '',
      });
    }
  }, [data, setUser]);

  return <UserContext.Provider value={{ state }}>{children}</UserContext.Provider>;
};
