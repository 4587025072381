import { TicketItems } from '../../../../themes/maxbet/widgets/Ticket/store/types';
import { BROADCAST_MESSAGE } from '../types';
import { SET_WEB_TICKET_ITEMS, SetWebTicketItems, WEB_TICKET_ITEMS, WebTicketItems } from './types';

type Dispatch<Input, Output> = (input: Input) => Output & { identity: string };

type DispatchWebTicketItems = Dispatch<TicketItems | null, WebTicketItems>;
export const dispatchWebTicketItems: DispatchWebTicketItems = ticketItems => {
  return {
    identity: BROADCAST_MESSAGE,
    type: WEB_TICKET_ITEMS,
    payload: ticketItems,
  };
};

type DispatchSetWebTicketItems = Dispatch<TicketItems, SetWebTicketItems>;
export const dispatchSetWebTicketItems: DispatchSetWebTicketItems = ticketItems => {
  return {
    identity: BROADCAST_MESSAGE,
    type: SET_WEB_TICKET_ITEMS,
    payload: ticketItems,
  };
};
