import { Dispatch, useCallback } from 'react';

import { TOGGLE_SIDEBAR, UIAction, UIContextState } from './types';

export type UIActions = {
  toggleSidebar: () => void;
};

export type UseUIActions = (state: UIContextState, dispatch: Dispatch<UIAction>) => UIActions;
export const useUIActions: UseUIActions = (_state, dispatch) => {
  const toggleSidebar = useCallback(() => dispatch({ type: TOGGLE_SIDEBAR }), [dispatch]);

  return { toggleSidebar };
};
