import { Dispatch, useCallback } from 'react';

import { ADD_TO_OPENED_ROOMS, BetshopBuildAction, BetshopBuildState, REMOVE_FROM_OPENED_ROOMS } from './types';

export type AddToOpenedRooms = (room: string) => void;
export type RemoveFromOpenedRooms = (room: string) => void;
type BetshopBuildActions = {
  addToOpenedRooms: AddToOpenedRooms;
  removeFromOpenedRooms: RemoveFromOpenedRooms;
};

type UseBetshopBuildActions = (state: BetshopBuildState, dispatch: Dispatch<BetshopBuildAction>) => BetshopBuildActions;
export const useBetshopBuildActions: UseBetshopBuildActions = (_state, dispatch) => {
  const addToOpenedRooms: AddToOpenedRooms = useCallback(
    (room): void => {
      dispatch({
        type: ADD_TO_OPENED_ROOMS,
        payload: room,
      });
    },
    [dispatch],
  );

  const removeFromOpenedRooms: RemoveFromOpenedRooms = useCallback(
    (room): void => {
      dispatch({
        type: REMOVE_FROM_OPENED_ROOMS,
        payload: room,
      });
    },
    [dispatch],
  );

  return { addToOpenedRooms, removeFromOpenedRooms };
};
