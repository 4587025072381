import { ActionWithIdentity } from '../../../../shared/types/ActionWithIdentity';

export const SET_VIEW_SELECTOR_VIEW = 'SET_VIEW_SELECTOR_VIEW';
export const VIEW_SELECTOR_VIEW_SELECTED = 'VIEW_SELECTOR_VIEW_SELECTED';

export const SET_VIEW_SELECTOR_FILTER = 'SET_VIEW_SELECTOR_FILTER';
export const VIEW_SELECTOR_FILTER_SELECTED = 'VIEW_SELECTOR_FILTER_SELECTED';

export enum SelectorView {
  Overview = 'overview',
  MultipleView = 'multipleView',
  EventView = 'eventView',
  Announcement = 'announcement',
}

export enum SelectorFilter {
  All,
  BetBuilder,
  LiveMatchTracker,
  LiveStream,
}

export type ViewSelectorState = {
  [timeFilterId: string]: {
    selectedView: SelectorView;
    selectedFilter: SelectorFilter | null;
  };
};

export type SetViewSelectorView = ActionWithIdentity<typeof SET_VIEW_SELECTOR_VIEW, SelectorView>;
export type ViewSelectorViewSelected = ActionWithIdentity<typeof VIEW_SELECTOR_VIEW_SELECTED, ViewSelectorState>;

export type SetViewSelectorFilter = ActionWithIdentity<typeof SET_VIEW_SELECTOR_FILTER, SelectorFilter>;
export type ViewSelectorFilterSelected = ActionWithIdentity<typeof VIEW_SELECTOR_FILTER_SELECTED, ViewSelectorState>;

export type ViewSelectorReducer = (
  state: ViewSelectorState,
  action: SetViewSelectorView | SetViewSelectorFilter,
) => ViewSelectorState;
