import { BROADCAST_MESSAGE } from '../types';
import {
  ACTIVATE_SPINNER,
  ActivateSpinner,
  DEACTIVATE_SPINNER,
  DeactivateSpinner,
  RESET_SPINNER,
  ResetSpinner,
  SPINNER_STATE_SET,
  SpinnerState,
  SpinnerStateSet,
} from './types';

export type DispatchActivateSpinner = (widgetId: string) => ActivateSpinner;
export const dispatchActivateSpinner: DispatchActivateSpinner = widgetId => ({
  type: ACTIVATE_SPINNER,
  payload: widgetId,
  identity: BROADCAST_MESSAGE,
});

export type DispatchDeactivateSpinner = (widgetId: string) => DeactivateSpinner;
export const dispatchDeactivateSpinner: DispatchDeactivateSpinner = widgetId => ({
  type: DEACTIVATE_SPINNER,
  payload: widgetId,
  identity: BROADCAST_MESSAGE,
});

export type DispatchResetSpinner = () => ResetSpinner;
export const dispatchResetSpinner: DispatchResetSpinner = () => ({
  type: RESET_SPINNER,
  payload: null,
  identity: BROADCAST_MESSAGE,
});

export type DispatchSpinnerStateSet = (state: SpinnerState) => SpinnerStateSet;
export const dispatchSpinnerStateSet: DispatchSpinnerStateSet = state => ({
  type: SPINNER_STATE_SET,
  payload: state,
  identity: BROADCAST_MESSAGE,
});
