import { BookmarkedTournaments, SelectedTournaments } from '../../../../shared/types/widget-types/SportFilterSport';
import { BROADCAST_MESSAGE } from '../types';
import { SelectorView } from '../view-selector/types';
import {
  EVENTS_SELECTED,
  EventWithSport,
  EventsSelected,
  FAVORITE_EVENTS_SET,
  FavoriteLiveEventsSet,
  SET_BOOKMARKED_SPORTS,
  SET_BOOKMARKED_TOURNAMENTS,
  SET_FAVORITE_EVENTS,
  SET_SELECTED_EVENTS,
  SET_SELECTED_SPORT,
  SET_SELECTED_SPORTS,
  SET_SELECTED_TOURNAMENTS_BROADCAST,
  SET_SPORTS_ORDER,
  SET_SPORT_FILTER_LIVE_STATUS,
  SPORTS_BOOKMARKED,
  SPORTS_ORDERED,
  SPORTS_SELECTED,
  SPORT_FILTER_LIVE_STATUS_SET,
  SPORT_SELECTED,
  SetBookmarkedSports,
  SetBookmarkedTournaments,
  SetFavoriteLiveEvents,
  SetSelectedEvents,
  SetSelectedSport,
  SetSelectedSports,
  SetSelectedTournamentsBroadcast,
  SetSportFilterLiveStatus,
  SetSportsOrder,
  SportFilterLiveStatusSet,
  SportFilterStreamState,
  SportSelected,
  SportsBookmarked,
  SportsOrdered,
  SportsSelected,
  TOURNAMENTS_BOOKMARKED,
  TOURNAMENTS_SELECTED_BROADCAST,
  TournamentsBookmarked,
  TournamentsSelectedBroadcast,
} from './types';

type SidebarSportDispatch<T> = (identity: string, sportFilterStreamState: SportFilterStreamState) => T;

export type DispatchSportSelected = (sportFilterStreamState: SportFilterStreamState) => SportSelected;
export const dispatchSportSelected: DispatchSportSelected = sportFilterStreamState => ({
  type: SPORT_SELECTED,
  payload: sportFilterStreamState,
  identity: BROADCAST_MESSAGE,
});

export type DispatchSetSelectedSport = (identity: string, sportId: string | null) => SetSelectedSport;
export const dispatchSetSelectedSport: DispatchSetSelectedSport = (_, sportId) => ({
  type: SET_SELECTED_SPORT,
  payload: sportId,
  identity: BROADCAST_MESSAGE,
});

export type DispatchTournamentsSelectedBroadcast = SidebarSportDispatch<TournamentsSelectedBroadcast>;
export const dispatchTournamentsSelectedBroadcast: DispatchTournamentsSelectedBroadcast = (
  _,
  sportFilterStreamState,
) => ({
  type: TOURNAMENTS_SELECTED_BROADCAST,
  payload: sportFilterStreamState,
  identity: BROADCAST_MESSAGE,
});

export type DispatchSetSelectedTournamentsBroadcast = (
  identity: string,
  tournaments: SelectedTournaments,
) => SetSelectedTournamentsBroadcast;
export const dispatchSetSelectedTournamentsBroadcast: DispatchSetSelectedTournamentsBroadcast = (_, tournaments) => ({
  type: SET_SELECTED_TOURNAMENTS_BROADCAST,
  payload: tournaments,
  identity: BROADCAST_MESSAGE,
});

export type DispatchSportsSelected = SidebarSportDispatch<SportsSelected>;
export const dispatchSportsSelected: DispatchSportsSelected = (identity, sportFilterStreamState) => ({
  type: SPORTS_SELECTED,
  payload: sportFilterStreamState,
  identity,
});

export type DispatchSetSelectedSports = (
  identity: string,
  sportIds: string[],
  selectedView?: SelectorView,
) => SetSelectedSports;
export const dispatchSetSelectedSports: DispatchSetSelectedSports = (identity, sportIds, selectedView) => ({
  type: SET_SELECTED_SPORTS,
  payload: { sportIds, selectedView },
  identity,
});

export type DispatchTournamentsBookmarked = SidebarSportDispatch<TournamentsBookmarked>;
export const dispatchTournamentsBookmarked: DispatchTournamentsBookmarked = (identity, sportFilterStreamState) => ({
  type: TOURNAMENTS_BOOKMARKED,
  payload: sportFilterStreamState,
  identity,
});

export type DispatchSetBookmarkedTournaments = (
  identity: string,
  tournaments: BookmarkedTournaments,
) => SetBookmarkedTournaments;
export const dispatchSetBookmarkedTournaments: DispatchSetBookmarkedTournaments = (identity, tournaments) => ({
  type: SET_BOOKMARKED_TOURNAMENTS,
  payload: tournaments,
  identity,
});

export type DispatchSportsBookmarked = SidebarSportDispatch<SportsBookmarked>;
export const dispatchSportsBookmarked: DispatchSportsBookmarked = (identity, sportFilterStreamState) => ({
  type: SPORTS_BOOKMARKED,
  payload: sportFilterStreamState,
  identity,
});

export type DispatchSetBookmarkedSports = (
  identity: string,
  sportIds: string[],
  isLive: boolean,
) => SetBookmarkedSports;
export const dispatchSetBookmarkedSports: DispatchSetBookmarkedSports = (identity, sportIds, isLive) => ({
  type: SET_BOOKMARKED_SPORTS,
  payload: { sportIds, isLive },
  identity,
});

export type DispatchSportsOrdered = SidebarSportDispatch<SportsOrdered>;
export const dispatchSportsOrdered: DispatchSportsOrdered = (identity, sportFilterStreamState) => ({
  type: SPORTS_ORDERED,
  payload: sportFilterStreamState,
  identity,
});

export type DispatchSetSportsOrder = (identity: string, sports: string[]) => SetSportsOrder;
export const dispatchSetSportsOrder: DispatchSetSportsOrder = (identity, sports) => ({
  type: SET_SPORTS_ORDER,
  payload: sports,
  identity,
});

export type DispatchSportFilterLiveStatusSet = SidebarSportDispatch<SportFilterLiveStatusSet>;
export const dispatchSportFilterLiveStatusSet: DispatchSportFilterLiveStatusSet = (
  identity,
  sportFilterStreamState,
) => ({
  type: SPORT_FILTER_LIVE_STATUS_SET,
  payload: sportFilterStreamState,
  identity,
});

export type DispatchSetSportFilterLiveStatus = (identity: string, isLive: boolean) => SetSportFilterLiveStatus;
export const dispatchSetSportFilterLiveStatus: DispatchSetSportFilterLiveStatus = (identity, isLive) => ({
  type: SET_SPORT_FILTER_LIVE_STATUS,
  payload: isLive,
  identity,
});

export type DispatchFavoriteLiveEventsSet = SidebarSportDispatch<FavoriteLiveEventsSet>;
export const dispatchFavoriteLiveEventsSet: DispatchFavoriteLiveEventsSet = (identity, sportFilterStreamState) => ({
  type: FAVORITE_EVENTS_SET,
  payload: sportFilterStreamState,
  identity,
});

export type DispatchSetFavoriteLiveEvents = (identity: string, payload: EventWithSport[]) => SetFavoriteLiveEvents;
export const dispatchSetFavoriteLiveEvents: DispatchSetFavoriteLiveEvents = (identity, payload) => ({
  type: SET_FAVORITE_EVENTS,
  payload,
  identity,
});

export type DispatchEventsSelected = SidebarSportDispatch<EventsSelected>;
export const dispatchEventsSelected: DispatchEventsSelected = (identity, sportFilterStreamState) => ({
  type: EVENTS_SELECTED,
  payload: sportFilterStreamState,
  identity,
});

export type DispatchSetSelectedEvents = (identity: string, events: EventWithSport[]) => SetSelectedEvents;
export const dispatchSetSelectedEvents: DispatchSetSelectedEvents = (identity, events) => ({
  type: SET_SELECTED_EVENTS,
  payload: events,
  identity,
});
