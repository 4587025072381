import { ActionWithIdentity } from '../../../../shared/types/ActionWithIdentity';
import { ContextReducer } from '../../../../shared/types/ContextReducer';

export const RELOAD_LIVE_EVENTS = 'RELOAD_LIVE_EVENTS';
export const LIVE_EVENTS_RELOADED = 'LIVE_EVENTS_RELOADED';

export type ReloadLiveEvents = ActionWithIdentity<typeof RELOAD_LIVE_EVENTS, number>;

export type SidebarLabelReloadLiveEvents = {
  [widgetId: string]: ReloadMessagePayload;
};

export type LiveEventsReloaded = ActionWithIdentity<typeof LIVE_EVENTS_RELOADED, SidebarLabelReloadLiveEvents>;

export type ReloadMessagePayload = {
  lastReload: number;
};

export type ReloadLiveEventsReducer = ContextReducer<SidebarLabelReloadLiveEvents, ReloadLiveEvents>;
