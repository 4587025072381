import { BROADCAST_MESSAGE } from '../types';
import {
  MATCH_TRACKER_DATA_SET,
  MatchTrackerDataSet,
  MatchTrackerPayload,
  SET_MATCH_TRACKER_DATA,
  SetMatchTrackerData,
} from './types';

type Dispatch<Input, Output> = (input: Input) => Output & { identity: string };

type DispatchMatchTrackerDataSet = Dispatch<MatchTrackerPayload, MatchTrackerDataSet>;
export const dispatchMatchTrackerDataSet: DispatchMatchTrackerDataSet = data => ({
  type: MATCH_TRACKER_DATA_SET,
  payload: data,
  identity: BROADCAST_MESSAGE,
});

type DispatchSetMatchTrackerData = Dispatch<MatchTrackerPayload, SetMatchTrackerData>;
export const dispatchSetMatchTrackerData: DispatchSetMatchTrackerData = data => ({
  type: SET_MATCH_TRACKER_DATA,
  payload: data,
  identity: BROADCAST_MESSAGE,
});
