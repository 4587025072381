export const white = '#ffffff';
export const catSkillWhite = '#edf2f7';
export const psychoGrey = '#e3e7eb';
export const concreteGrey = '#c8cfd8';

export const black = '#000000';

export const limaGreen = '#7fbd22';
export const eucalyptusGreen = '#28a745';

export const mandyRed = '#e35454';
export const punchRed = '#dc3545';

export const baliHaiBlue = '#8fa4b8';
export const dodgerBlue = '#36a3f7';
export const sharkBlue = '#1f2226';
export const selagoBlue = '#f8fbfe';
export const bayouxBlue = '#4e6375';
export const azureBlue = '#00a0fd';
export const lynchBlue = '#73879c';

export const shuttleGray = '#5d6f84';
