import { EventPresenterMarket } from '../../../../shared/types/widget-types/Markets';
import { BROADCAST_MESSAGE } from '../types';
import {
  MARKETS_SELECTED,
  MarketsSelected,
  SET_SELECTED_MARKETS,
  SelectedMarketsState,
  SetSelectedMarkets,
} from './types';

export const dispatchSetSelectedMarkets: (
  sportId: string,
  markets: EventPresenterMarket[],
  isLive: boolean,
) => SetSelectedMarkets = (sportId, markets, isLive) => ({
  type: SET_SELECTED_MARKETS,
  payload: {
    isLive,
    selectedMarkets: {
      [sportId]: markets,
    },
  },
});

export const dispatchMarketsSelected: (selectedMarkets: SelectedMarketsState) => MarketsSelected = selectedMarkets => ({
  type: MARKETS_SELECTED,
  payload: selectedMarkets,
  identity: BROADCAST_MESSAGE,
});
