import { PageGraphql } from '../../../../graphql';
import { Betshop } from '../../../../shared-types/graphql';

export const SET_BETSHOP_PAGES_DATA = 'SET_BETSHOP_PAGES_DATA';
export const SET_BETSHOP_PAGE_NAME = 'SET_BETSHOP_PAGE_NAME';
export const SET_BETSHOP_NAVI_ITEMS_TEXT = 'SET_BETSHOP_NAVI_ITEMS_TEXT';

export type SetBetshopPagesData = (pages: Betshop) => void;
export type SetBetshopPageName = (page: PageGraphql) => void;
export type SetBetshopNaviItemsText = (page: PageGraphql) => void;
type SetBetshopPagesAction = {
  type: typeof SET_BETSHOP_PAGES_DATA;
  payload: Betshop;
};
type SetBetshopPageNameAction = {
  type: typeof SET_BETSHOP_PAGE_NAME;
  payload: PageGraphql;
};
type SetBetshopNaviItemsTextAction = {
  type: typeof SET_BETSHOP_NAVI_ITEMS_TEXT;
  payload: PageGraphql;
};

export type BetshopPageEditActions = {
  setBetshopPagesData: SetBetshopPagesData;
  setBetshopPageName: SetBetshopPageName;
  setBetshopNaviItemsText: SetBetshopNaviItemsText;
};
export type BetshopPageEditAction = SetBetshopPagesAction | SetBetshopPageNameAction | SetBetshopNaviItemsTextAction;
export type BetshopPageEditReducer = (state: Betshop, action: BetshopPageEditAction) => Betshop;
