import {
  NavigationReducer,
  SET_IS_DEVICE_BLOCKED,
  SET_IS_NAVIGATION_BLOCKED,
  SET_PREVIOUS_PAGE,
  SET_SELECTED_EVENT,
} from './types';

export const navigationReducer: NavigationReducer = (state, action) => {
  switch (action.type) {
    case SET_PREVIOUS_PAGE:
      return {
        ...state,
        previousPage: action.payload,
      };

    case SET_SELECTED_EVENT:
      return {
        ...state,
        selectedEvent: action.payload,
      };

    case SET_IS_NAVIGATION_BLOCKED:
      return {
        ...state,
        isNavigationBlocked: action.payload,
      };
    case SET_IS_DEVICE_BLOCKED:
      return {
        ...state,
        isDeviceBlocked: action.payload,
      };
  }
};
