import { ContextReducer } from '../../types/ContextReducer';

export const TOGGLE_SIDEBAR = 'TOGGLE_SIDEBAR';

export type UIContextState = {
  sidebarExpanded: boolean;
};

export type ToggleSidebarAction = { type: typeof TOGGLE_SIDEBAR };
export type UIAction = ToggleSidebarAction;
export type UIReducer = ContextReducer<UIContextState, UIAction>;
