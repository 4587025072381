import { BROADCAST_MESSAGE } from '../types';
import {
  IS_DEVICE_BLOCKED_SET,
  IS_NAVIGATION_BLOCKED_SET,
  IsDeviceBlockedSet,
  IsNavigationBlockedSet,
  NavigationState,
  PREVIOUS_PAGE_SET,
  PreviousPageSet,
  SELECTED_EVENT_SET,
  SET_IS_DEVICE_BLOCKED,
  SET_IS_NAVIGATION_BLOCKED,
  SET_PREVIOUS_PAGE,
  SET_SELECTED_EVENT,
  SelectedEventSet,
  SetIsDeviceBlocked,
  SetIsNavigationBlocked,
  SetPreviousPage,
  SetSelectedEvent,
} from './types';

export type DispatchSetPreviousPage = (pageUrl: NavigationState['previousPage']) => SetPreviousPage;
export const dispatchSetPreviousPage: DispatchSetPreviousPage = pageUrl => ({
  type: SET_PREVIOUS_PAGE,
  payload: pageUrl,
  identity: BROADCAST_MESSAGE,
});

export type DispatchSetSelectedEvent = (event: NavigationState['selectedEvent']) => SetSelectedEvent;
export const dispatchSetSelectedEvent: DispatchSetSelectedEvent = event => ({
  type: SET_SELECTED_EVENT,
  payload: event,
  identity: BROADCAST_MESSAGE,
});

export type DispatchPreviousPageSet = (state: NavigationState) => PreviousPageSet;
export const dispatchPreviousPageSet: DispatchPreviousPageSet = state => ({
  type: PREVIOUS_PAGE_SET,
  payload: state,
  identity: BROADCAST_MESSAGE,
});

export type DispatchSelectedEventSet = (state: NavigationState) => SelectedEventSet;
export const dispatchSelectedEventSet: DispatchSelectedEventSet = state => ({
  type: SELECTED_EVENT_SET,
  payload: state,
  identity: BROADCAST_MESSAGE,
});

export type DispatchSetIsNavigationBlocked = (
  isBlocked: NavigationState['isNavigationBlocked'],
) => SetIsNavigationBlocked;
export const dispatchSetIsNavigationBlocked: DispatchSetIsNavigationBlocked = isBlocked => ({
  type: SET_IS_NAVIGATION_BLOCKED,
  payload: isBlocked,
  identity: BROADCAST_MESSAGE,
});

export type DispatchIsNavigationBlockedSet = (state: NavigationState) => IsNavigationBlockedSet;
export const dispatchIsNavigationBlockedSet: DispatchIsNavigationBlockedSet = state => ({
  type: IS_NAVIGATION_BLOCKED_SET,
  payload: state,
  identity: BROADCAST_MESSAGE,
});

export type DispatchSetIsDeviceBlocked = (isBlocked: NavigationState['isDeviceBlocked']) => SetIsDeviceBlocked;
export const dispatchSetIsDeviceBlocked: DispatchSetIsDeviceBlocked = isBlocked => ({
  type: SET_IS_DEVICE_BLOCKED,
  payload: isBlocked,
  identity: BROADCAST_MESSAGE,
});

export type DispatchIsDeviceBlockedSet = (state: NavigationState) => IsDeviceBlockedSet;
export const dispatchIsDeviceBlockedSet: DispatchIsDeviceBlockedSet = state => ({
  type: IS_DEVICE_BLOCKED_SET,
  payload: state,
  identity: BROADCAST_MESSAGE,
});
