type StoreInBrowserStorage = <T>(key: string, item: T) => void;
type GetValueForKeyInBrowserStorage = <T>(key: string) => T | null;
type RemoveFromBrowserStorage = (key: string) => void;
type ClearBrowserStorage = () => void;

type UseBrowserStorage = () => {
  storeInBrowserStorage: StoreInBrowserStorage;
  getValueForKeyInBrowserStorage: GetValueForKeyInBrowserStorage;
  removeFromBrowserStorage: RemoveFromBrowserStorage;
  clearBrowserStorage: ClearBrowserStorage;
};

export const storeInBrowserStorage: StoreInBrowserStorage = (key, item) => {
  localStorage.setItem(key, JSON.stringify(item));
};

export const getValueForKeyInBrowserStorage: GetValueForKeyInBrowserStorage = key => {
  const item = localStorage.getItem(key);

  return item ? JSON.parse(item) : null;
};

export const removeFromBrowserStorage: RemoveFromBrowserStorage = key => {
  localStorage.removeItem(key);
};

export const clearBrowserStorage: ClearBrowserStorage = () => {
  localStorage.clear();
};

export const useBrowserStorage: UseBrowserStorage = () => {
  return {
    storeInBrowserStorage,
    getValueForKeyInBrowserStorage,
    removeFromBrowserStorage,
    clearBrowserStorage,
  };
};
