import { lazy } from 'navi';

import { Route } from '../types/Route';
import { RouteWithView } from '../types/RouteWithView';

export enum DEFAULT_SLUGS {
  PAGE_EDIT = 'page-edit',
  BETSHOP_PREVIEW = 'preview',
}

export const loginRoute: Route = {
  path: '/login',
  title: 'Login',
};

export const sharedRoutes: RouteWithView[] = [
  {
    path: loginRoute.path,
    component: () => lazy(() => import('../../pages/Login/Login')),
    title: loginRoute.title,
  },
];
