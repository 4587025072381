import React, { FC, MouseEvent, createContext, useCallback, useContext, useReducer } from 'react';
import { DragDropContext, DropResult } from 'react-beautiful-dnd';

import { DnDContextState } from '../types';
import { DnDActions, useDnDActions } from './actions';
import { dnDReducer, initialDnDState } from './reducer';

type SelectDropZone = (zone: string) => (event: MouseEvent) => boolean;

type DnDContext = DnDActions & DnDContextState & { selectDropZone: SelectDropZone };
const DnDContext = createContext({} as DnDContext);
export const useDnDContext = () => useContext(DnDContext);

export const DnDContextProvider: FC = ({ children }) => {
  const [state, dispatch] = useReducer(dnDReducer, initialDnDState);
  const actions = useDnDActions(state, dispatch);

  const onDragEnd = (result: DropResult) => {
    if (!result.destination) {
      actions.finishDrag();
      return;
    }
    const { type, destination, source, draggableId } = result;
    actions.setDropResult({
      type,
      destination: destination.index,
      source: source.index,
      movedItemId: draggableId,
      movedFrom: source.droppableId,
      movedTo: destination.droppableId,
    });
  };

  const selectDropZone = useCallback(
    (zone: string) => (event: MouseEvent) => {
      event.preventDefault();
      event.stopPropagation();
      actions.setSelectedDropZone(zone);
      return false;
    },
    [actions],
  );

  const onDragStart = useCallback(() => actions.setSelectedDropZone(null), [actions]);

  return (
    <DnDContext.Provider value={{ ...state, ...actions, selectDropZone }}>
      <DragDropContext onDragEnd={onDragEnd} onDragStart={onDragStart}>
        <div>{children}</div>
      </DragDropContext>
    </DnDContext.Provider>
  );
};
