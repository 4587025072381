/* eslint-disable @typescript-eslint/ban-ts-ignore */
import { useEffect } from 'react';
import { BehaviorSubject, Observable, Subject, merge } from 'rxjs';
import { bufferTime, filter, map, skipWhile, tap, withLatestFrom } from 'rxjs/operators';
import io from 'socket.io-client';

import { Settings } from '../settings';
import { mappers } from './mappers';
import { SocketUpdates } from './oddsDataUpdateClass';
import {
  Callback,
  EventChangeMessage,
  EventResultMessage,
  Filters,
  JackpotUpdateMessage,
  JackpotWonMessage,
  NewEventMessage,
  OddsChangeMessage,
  OriginalSocketMessage,
  ParsedSocketMessage,
  Room,
  SOCKET_ACTIONS,
  SOCKET_MESSAGE_TYPE,
  SubjectType,
} from './types';

export const createSocketConnection: (socketUrl: string) => SocketIOClient.Socket = socketUrl => {
  return io(socketUrl, { transports: ['websocket'] });
};
const newEventSocket = new Subject<ParsedSocketMessage>();
const oddsChangeResultSocket = new Subject<ParsedSocketMessage>();
const invalidTicketSocket = new Subject<ParsedSocketMessage>();
const placedTicketSocket = new Subject<ParsedSocketMessage>();
const refundedTicketSocket = new Subject<ParsedSocketMessage>();
const winningTicketSocket = new Subject<ParsedSocketMessage>();
const losingTicketSocket = new Subject<ParsedSocketMessage>();
const cashoutSocket = new Subject<ParsedSocketMessage>();
const digitainMaxWinAmountSocket = new Subject<ParsedSocketMessage>();
const sportFilterUpdateSocket = new Subject<ParsedSocketMessage>();
const digitainLastTickets = new Subject<ParsedSocketMessage>();
const withdrawnTicketSocket = new Subject<ParsedSocketMessage>();
const jackpotChangeSocket = new Subject<JackpotUpdateMessage>();
const jackpotWonSocket = new Subject<JackpotWonMessage>();

const socketUpdateSubject = new Subject<OddsChangeMessage[]>();
const socketUpdate = new SocketUpdates(socketUpdateSubject);
socketUpdate.start();

const eventResultsSubject = new Subject<EventResultMessage[]>();
const resultsUpdate = new SocketUpdates(eventResultsSubject);
resultsUpdate.start();

const newEventSubject = new Subject<NewEventMessage[]>();
const newEventUpdate = new SocketUpdates(newEventSubject);
newEventUpdate.start();

const handleMessage = <T extends ParsedSocketMessage>(
  socket: Subject<T>,
  message: OriginalSocketMessage | ParsedSocketMessage,
) => {
  // @ts-ignore zato sto je OriginalSocketMessage string a ovde uopste ne dolazi OriginalSocketMessage nego AltMessage koja nije zavrsena do kraja
  const mapMessage = mappers[message.type];
  const newMessage = mapMessage ? mapMessage(message) : message;

  socket.next(newMessage);
};

type SocketListener = (
  socket: SocketIOClient.Socket,
  messageType: SOCKET_MESSAGE_TYPE,
  activeRooms: BehaviorSubject<Room[]>,
  provider: string,
) => void;

export const listenTicket = (
  socket: SocketIOClient.Socket,
  messageType: SOCKET_MESSAGE_TYPE,
  activeRooms: BehaviorSubject<Room[]>,
) => {
  socket.on('reconnect', () => {
    activeRooms.value.map(({ name }) => {
      socket.emit(SOCKET_ACTIONS.JOIN, name);
    });
  });

  socket.on(messageType, (message: OriginalSocketMessage | ParsedSocketMessage) => {
    switch (messageType) {
      case SOCKET_MESSAGE_TYPE.INVALID:
        return handleMessage(invalidTicketSocket, message);

      case SOCKET_MESSAGE_TYPE.PLACED:
        return handleMessage(placedTicketSocket, message);

      case SOCKET_MESSAGE_TYPE.REFUNDED:
        return handleMessage(refundedTicketSocket, message);

      case SOCKET_MESSAGE_TYPE.WINNING:
        return handleMessage(winningTicketSocket, message);

      case SOCKET_MESSAGE_TYPE.LOSING:
        return handleMessage(losingTicketSocket, message);

      case SOCKET_MESSAGE_TYPE.CASHOUT:
        return handleMessage(cashoutSocket, message);

      case SOCKET_MESSAGE_TYPE.DIGITAIN_MAX_WIN_AMOUNT:
        return handleMessage(digitainMaxWinAmountSocket, message);

      case SOCKET_MESSAGE_TYPE.LAST_TICKETS:
        return handleMessage(digitainLastTickets, message);

      case SOCKET_MESSAGE_TYPE.WITHDRAWN:
        return handleMessage(withdrawnTicketSocket, message);

      default:
        console.warn('Unhandled socket message type', messageType);
    }
  });
};

export const listenEvents = (
  socket: SocketIOClient.Socket,
  messageType: SOCKET_MESSAGE_TYPE,
  activeRooms: BehaviorSubject<Room[]>,
) => {
  socket.on('reconnect', () => {
    activeRooms.value.map(({ name }) => {
      socket.emit(SOCKET_ACTIONS.JOIN, name);
    });
  });

  socket.on(messageType, (message: OriginalSocketMessage | ParsedSocketMessage) => {
    // @ts-ignore zato sto su netacni tipovi, trebace se nekad uzeti i ispraviti sve
    const mapMessage = mappers[message.type];

    switch (messageType) {
      case SOCKET_MESSAGE_TYPE.NEW_EVENT:
        return newEventUpdate.bufferData(mapMessage(message));

      case SOCKET_MESSAGE_TYPE.EVENT_CHANGE:
        return socketUpdate.bufferData(mapMessage(message));

      case SOCKET_MESSAGE_TYPE.EVENT_RESULT:
        socketUpdate.bufferData(mapMessage(message));
        resultsUpdate.bufferData(mapMessage(message));
        return;

      case SOCKET_MESSAGE_TYPE.ODDS_CHANGE:
        return socketUpdate.bufferData(mapMessage(message));

      case SOCKET_MESSAGE_TYPE.SPORT_FILTER_UPDATE:
        return handleMessage(sportFilterUpdateSocket, message);

      default:
        console.warn('Unhandled socket message type', messageType);
    }
  });
};

export const listenJackpot = (
  socket: SocketIOClient.Socket,
  messageType: SOCKET_MESSAGE_TYPE,
  activeRooms: BehaviorSubject<Room[]>,
) => {
  socket.on('reconnect', () => {
    activeRooms.value.map(({ name }) => {
      socket.emit(SOCKET_ACTIONS.JOIN, name);
    });
  });

  // TODO: remove logs
  socket.on(messageType, (message: JackpotUpdateMessage | JackpotWonMessage) => {
    switch (messageType) {
      case SOCKET_MESSAGE_TYPE.JACKPOT_CHANGE:
        return jackpotChangeSocket.next(message as JackpotUpdateMessage);

      case SOCKET_MESSAGE_TYPE.JACKPOT_WON:
        console.log('Jackpot won message', message);
        return jackpotWonSocket.next(message as JackpotWonMessage);

      default:
        return console.warn('Unhandled socket message type', messageType);
    }
  });
};

const createMessageStream = (messageTypes: SOCKET_MESSAGE_TYPE[]) => {
  return messageTypes.reduce((stream, type) => {
    switch (type) {
      case SOCKET_MESSAGE_TYPE.NEW_EVENT:
        return merge(stream, newEventSocket);

      case SOCKET_MESSAGE_TYPE.ODDS_CHANGE:
        return merge(stream, oddsChangeResultSocket);

      case SOCKET_MESSAGE_TYPE.INVALID:
        return merge(stream, invalidTicketSocket);

      case SOCKET_MESSAGE_TYPE.PLACED:
        return merge(stream, placedTicketSocket);

      case SOCKET_MESSAGE_TYPE.REFUNDED:
        return merge(stream, refundedTicketSocket);

      case SOCKET_MESSAGE_TYPE.WINNING:
        return merge(stream, winningTicketSocket);

      case SOCKET_MESSAGE_TYPE.LOSING:
        return merge(stream, losingTicketSocket);

      case SOCKET_MESSAGE_TYPE.CASHOUT:
        return merge(stream, cashoutSocket);

      case SOCKET_MESSAGE_TYPE.DIGITAIN_MAX_WIN_AMOUNT:
        return merge(stream, digitainMaxWinAmountSocket);

      case SOCKET_MESSAGE_TYPE.SPORT_FILTER_UPDATE:
        return merge(stream, sportFilterUpdateSocket);

      case SOCKET_MESSAGE_TYPE.LAST_TICKETS:
        return merge(stream, digitainLastTickets);

      case SOCKET_MESSAGE_TYPE.WITHDRAWN:
        return merge(stream, withdrawnTicketSocket);

      case SOCKET_MESSAGE_TYPE.JACKPOT_CHANGE:
        return merge(stream, jackpotChangeSocket);

      case SOCKET_MESSAGE_TYPE.JACKPOT_WON:
        return merge(stream, jackpotWonSocket);

      default:
        return stream;
    }
  }, new Observable<ParsedSocketMessage>());
};

const runFilters = (msg: ParsedSocketMessage, filters: Filters) => {
  return Object.entries(filters)
    .map(([id, idsToPass]) => (idsToPass ? idsToPass.includes(msg.data[id]) : true))
    .every(Boolean);
};

export const useTicketSocketEffect: UseSocketEffect = (cb, listenFor, filters = {}) => {
  useEffect(() => {
    const stream$ = createMessageStream(listenFor);
    const s = stream$.subscribe(e => {
      cb([e]);
    });

    return () => s.unsubscribe();
  }, [cb, listenFor, filters]);
};

type UseSocketEffect = (cb: Callback, listenFor: SOCKET_MESSAGE_TYPE[], filters?: Filters) => void;
export const useSocketEffect: UseSocketEffect = (cb, listenFor, filters = {}) => {
  useEffect(() => {
    const stream$ = createMessageStream(listenFor);
    const s = stream$
      .pipe(
        filter(d => {
          return !!Object.keys(d.data).length;
        }),
        filter(d => {
          if (listenFor.includes(SOCKET_MESSAGE_TYPE.INVALID)) {
            console.log(d, filters);
          }

          return runFilters(d, filters);
        }),
        bufferTime(1000),
        filter(v => {
          return !!v.length;
        }),
      )
      .subscribe(cb);

    return () => s.unsubscribe();
  }, [cb, listenFor, filters]);
};

const subjects = {
  [SubjectType.GENERAL_UPDATES]: socketUpdateSubject,
  [SubjectType.RESULT_UPDATES]: eventResultsSubject,
  [SubjectType.NEW_EVENT_UPDATES]: newEventSubject,
};
type Messages = OddsChangeMessage | EventResultMessage | EventChangeMessage;

export const useSocketUpdatesEffect: (
  cb: Callback,
  subjectType: SubjectType,
  filters?: Filters,
  separateResultUpdate?: boolean,
) => void = (cb, subjectType, filters = {}, separateResultUpdate) => {
  const subject = subjects[subjectType] as Subject<OddsChangeMessage[] | EventResultMessage[] | EventChangeMessage[]>;
  useEffect(() => {
    const s = subject
      .pipe(
        map((d: Messages[]) => {
          return d.filter(m => !!m && !!m.id && !!Object.keys(m.data).length);
        }),
        map(d => {
          return d.filter(m => {
            if (separateResultUpdate && subjectType !== SubjectType.RESULT_UPDATES) {
              return m.type !== SOCKET_MESSAGE_TYPE.EVENT_RESULT;
            }
            if (separateResultUpdate && subjectType === SubjectType.RESULT_UPDATES) {
              return m.type === SOCKET_MESSAGE_TYPE.EVENT_RESULT;
            }
            return true;
          });
        }),
        map(d => {
          return d.filter(m => runFilters(m, filters));
        }),
      )
      .subscribe(cb);

    return () => s.unsubscribe();
  }, [cb, filters, separateResultUpdate, subject, subjectType]);
};

export type Socket = {
  joinRoom: (identity: string, ...rooms: string[]) => void;
  leaveRoom: (identity: string, ...rooms: string[]) => void;
  disconnect: () => void;
};
const Socket: (
  settings: Observable<Settings | null>,
  makeSocket: (provider: string) => SocketIOClient.Socket,
  listen: SocketListener,
  messages: SOCKET_MESSAGE_TYPE[],
) => Socket = (settings, makeSocket, listen, messages) => {
  let socket;
  const join = new BehaviorSubject<Room | undefined>(undefined);
  const leave = new BehaviorSubject<Room | undefined>(undefined);
  const activeRooms = new BehaviorSubject<Room[]>([]);

  settings
    .pipe(
      filter(settings => !!settings),
      // @ts-ignore
      map(({ provider }) => {
        socket = makeSocket(provider);
        console.log(`Successfull socket connection - provider ${provider}`);

        if (!socket) {
          return;
        }

        if (socket.disconnected) {
          socket.connect();
        }

        messages.map(messageType => listen(socket, messageType, activeRooms, provider));

        join
          .pipe(
            skipWhile(room => !room),
            withLatestFrom(activeRooms),
            map(([room, rooms]) =>
              rooms.some(r => r.identity === room?.identity && r.name === room.name) ? null : room,
            ),
            filter(room => !!room),
            tap(room => room && activeRooms.next([...activeRooms.value, room])),
          )
          .subscribe(room => room && socket.emit(SOCKET_ACTIONS.JOIN, room.name));

        leave
          .pipe(
            skipWhile(room => !room),
            withLatestFrom(activeRooms),
            map(([room, rooms]) => rooms.find(r => r.name === room?.name && r.identity === room.identity)),
            filter(room => !!room),
            tap(
              room =>
                room &&
                activeRooms.next([
                  ...activeRooms.value.filter(r => r.identity !== room.identity || r.name !== room.name),
                ]),
            ),
            skipWhile(room => activeRooms.value.some(r => r.name === room?.name)),
          )
          .subscribe(room => room && socket.emit(SOCKET_ACTIONS.LEAVE, room.name));
      }),
    )
    .subscribe();

  const joinRoom = (identity: string, ...rooms: string[]) => {
    rooms.map(room => join.next({ name: room, identity }));
  };

  const leaveRoom = (identity: string, ...rooms: string[]) => {
    rooms.map(room => leave.next({ name: room, identity }));
  };

  const disconnect = () => {
    socket.disconnect();
  };

  return {
    joinRoom,
    leaveRoom,
    disconnect,
  };
};

export default Socket;
