import {
  SET_VIEW_SELECTOR_FILTER,
  SET_VIEW_SELECTOR_VIEW,
  SelectorFilter,
  SelectorView,
  ViewSelectorReducer,
} from './types';

const initialState = {
  selectedView: SelectorView.Overview,
  selectedFilter: SelectorFilter.All,
};

export const viewSelectorReducer: ViewSelectorReducer = (state, action) => {
  switch (action.type) {
    case SET_VIEW_SELECTOR_VIEW:
      return {
        ...state,
        [action.identity]: {
          ...(state[action.identity] ? state[action.identity] : initialState),
          selectedView: action.payload,
        },
      };
    case SET_VIEW_SELECTOR_FILTER:
      return {
        ...state,
        [action.identity]: {
          ...(state[action.identity] ? state[action.identity] : initialState),
          selectedFilter: action.payload,
        },
      };
    default:
      return state;
  }
};
