
/** ------------------------------------------------------
 * THIS FILE WAS AUTOMATICALLY GENERATED (DO NOT MODIFY)
 * -------------------------------------------------------
 */

/* tslint:disable */
/* eslint-disable */
export class LayoutInput {
    name: string;
}

export class MainInputType {
    visibleInLayouts: string[];
    content: JSON[];
}

export class MainLayoutRowElementContentInputType {
    first: JSON[];
    second: JSON[];
}

export class PageContentInputGraphql {
    leftSidebar: SidebarInputType;
    rightSidebar: SidebarInputType;
    main: MainInputType;
}

export class PageInput {
    name: string;
    slug: string;
    layout: LayoutInput;
    content: JSON;
}

export class PageLayoutMainElementRowInputGraphql {
    id: string;
    type?: string;
    content: JSON;
}

export class SidebarInputType {
    visibleInLayouts: string[];
    content: JSON[];
}

export class UpdateBetshopInput {
    _id: string;
    logo: string;
    name: string;
    colorScheme: JSON;
    header?: JSON;
    footer?: JSON;
    activeTheme: string;
    pages?: PageInput[];
}

export class UpdateBetshopPagesInput {
    _id: string;
    pages: PageInput[];
    header?: JSON;
    footer?: JSON;
}

export class BetshopGraphql {
    _id: string;
    logo: string;
    name: string;
    type: string;
    provider: string;
    colorScheme: JSON;
    header?: JSON;
    footer?: JSON;
    activeTheme: ThemesGraphql;
    availableThemes: ThemesGraphql[];
    pages: PageGraphql[];
}

export class CommonResponse {
    status: number;
    message: string;
}

export class LayoutGraphql {
    name: string;
}

export class MainLayoutRowElementContentGraphql {
    first: WidgetGraphql[];
    second: WidgetGraphql[];
}

export abstract class IMutation {
    abstract updateBetshopPages(betshopPagesData: UpdateBetshopPagesInput): BetshopGraphql | Promise<BetshopGraphql>;

    abstract addPage(name: string, betshopId: string): BetshopGraphql | Promise<BetshopGraphql>;

    abstract removePage(slug: string, betshopId: string): BetshopGraphql | Promise<BetshopGraphql>;

    abstract createBetshop(provider: string, type: string, name: string): BetshopGraphql | Promise<BetshopGraphql>;

    abstract updateBetshop(betshopData: UpdateBetshopInput): BetshopGraphql | Promise<BetshopGraphql>;

    abstract uploadLogo(betshopId: string, logo: Upload): BetshopGraphql | Promise<BetshopGraphql>;

    abstract deleteBetshop(_id: string): CommonResponse | Promise<CommonResponse>;
}

export class PageContentGraphql {
    leftSidebar: PageLayoutElementGraphql;
    rightSidebar: PageLayoutElementGraphql;
    main: PageLayoutMainElementGraphql;
}

export class PageGraphql {
    name: string;
    slug: string;
    layout: LayoutGraphql;
    content: PageContentGraphql;
}

export class PageLayoutElementGraphql {
    visibleInLayouts: string[];
    content: WidgetGraphql[];
}

export class PageLayoutMainElementGraphql {
    visibleInLayouts: string[];
    content: PageLayoutMainElementRowGraphql[];
}

export class PageLayoutMainElementRowGraphql {
    id: string;
    type?: string;
    content: MainLayoutRowElementContentGraphql;
}

export abstract class IQuery {
    abstract themes(): ThemesGraphql[] | Promise<ThemesGraphql[]>;

    abstract themesByBetshopType(betshopType: string): ThemesGraphql[] | Promise<ThemesGraphql[]>;

    abstract me(): UserGraphql | Promise<UserGraphql>;

    abstract getUserBetshops(): BetshopGraphql | Promise<BetshopGraphql>;

    abstract betshops(): BetshopGraphql[] | Promise<BetshopGraphql[]>;

    abstract betshop(_id: string): BetshopGraphql | Promise<BetshopGraphql>;
}

export class ThemesGraphql {
    _id: string;
    name: string;
    layouts: LayoutGraphql[];
    colorScheme: JSON;
    header: JSON;
    availableWidgets: WidgetGraphql[];
}

export class UserGraphql {
    _id: string;
    username: string;
}

export class WidgetGraphql {
    id: string;
    name: string;
    component: string;
    availableIn: string[];
    settings: JSON;
}

export type JSON = any;
export type Upload = any;
