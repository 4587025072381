import { BehaviorSubject } from 'rxjs';

import { LIVE_EVENTS_RELOADED, LiveEventsReloaded } from './types';

export type SidebarLabelReloadLiveStream = BehaviorSubject<LiveEventsReloaded>;
export const SidebarLabelReloadLive: SidebarLabelReloadLiveStream = new BehaviorSubject({
  type: LIVE_EVENTS_RELOADED,
  payload: {},
  identity: '',
});
