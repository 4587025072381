import { BehaviorSubject } from 'rxjs';

import { BROADCAST_MESSAGE } from '../types';
import { DEVICE_TYPE_SET, DeviceTypeSet, initialDeviceState } from './types';

const initialDeviceTypeStreamMessage: DeviceTypeSet = {
  type: DEVICE_TYPE_SET,
  payload: initialDeviceState,
  identity: BROADCAST_MESSAGE,
};

export const DeviceTypeStream = new BehaviorSubject(initialDeviceTypeStreamMessage);

export const DeviceStreams = [DeviceTypeStream];
