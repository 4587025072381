import { ThemesGraphql } from '../../../../graphql';
import { BaseColors, ThemeOption } from '../../../../shared-types/color-scheme';
import { Betshop } from '../../../../shared-types/graphql';
import { Footer, Header, NavigationType } from '../../../../shared-types/header-footer';
import { clone } from '../../../../utils/clone';
import { moveListItem } from '../../../../utils/moveListItem';
import {
  ADD_TO_BETSHOP_NAVIGATION_ITEMS,
  BetshopEditReducer,
  NavigationItemPayload,
  REMOVE_FROM_BETSHOP_NAVIGATION_ITEMS,
  RESORT_NAVIGATION,
  ResortNavigationAction,
  SET_BASE_COLORS,
  SET_BETSHOP_DATA,
  SET_BETSHOP_LOGO,
  SET_BETSHOP_THEME_DATA,
  SET_BETSHOP_TITLE,
  SET_FOOTER_COLORS,
  SET_HEADER_COLORS,
  TOGGLE_BETSHOP_HEADER_ITEM_HIGHLIGHT,
} from './types';

export const initialBetshopEditState: Betshop = {
  _id: '',
  logo: '',
  name: '',
  type: '',
  provider: '',
  colorScheme: {
    theme: ThemeOption.Maxbet,
    colorOrder: [],
    colors: {} as BaseColors,
  },
  activeTheme: new ThemesGraphql(),
  availableThemes: [],
  pages: [],
};

const addPageToHeader = (state: Betshop, action: NavigationItemPayload) => {
  const header: Header = JSON.parse(JSON.stringify(state.header));
  header.navigation[action.level].items.push(action.item);

  return { ...state, header };
};

const removePageFromHeader = (state: Betshop, action: NavigationItemPayload) => {
  const header: Header = JSON.parse(JSON.stringify(state.header));

  const items = header.navigation[action.level].items.filter(i => i.text !== action.item.text);
  header.navigation[action.level].items = items;

  return { ...state, header };
};

const toggleNavigationItemHighlight = (state: Betshop, action: NavigationItemPayload) => {
  const header: Header = JSON.parse(JSON.stringify(state.header));
  const items = header.navigation[action.level].items.map(i => {
    if (i.text === action.item.text) {
      i.isHighlighted = !i.isHighlighted;
    }
    return i;
  });
  header.navigation[action.level].items = items;

  return { ...state, header };
};

const addPageToFooter = (state: Betshop, action: NavigationItemPayload) => {
  const footer: Footer = JSON.parse(JSON.stringify(state.footer));
  footer.navigation[action.level].items.push(action.item);

  return { ...state, footer };
};

const removePageFromFooter = (state: Betshop, action: NavigationItemPayload) => {
  const footer: Footer = JSON.parse(JSON.stringify(state.footer));

  const items = footer.navigation[action.level].items.filter(i => i.text !== action.item.text);
  footer.navigation[action.level].items = items;

  return { ...state, footer };
};

const resortNavigation = (state: Betshop, action: ResortNavigationAction) => {
  const isFooter = action.payload.type === NavigationType.FOOTER;
  const element = isFooter ? clone<Footer>(state.footer as Footer) : clone<Header>(state.header as Header);
  element.navigation[action.payload.level].items = moveListItem(
    element.navigation[action.payload.level].items,
    action.payload.source,
    action.payload.destination,
  );
  return { ...state, [isFooter ? 'footer' : 'header']: element };
};

export const betshopEditReducer: BetshopEditReducer = (state, action) => {
  switch (action.type) {
    case SET_BETSHOP_DATA:
    case SET_BETSHOP_THEME_DATA:
      return { ...state, ...action.payload };
    case SET_BASE_COLORS:
      return {
        ...state,
        colorScheme: {
          ...state.colorScheme,
          colors: action.payload,
          colorOrder: [],
        },
      };
    case SET_HEADER_COLORS:
      if (!state.header) {
        return state;
      }

      const headerColorScheme = state.header.colorScheme;
      return {
        ...state,
        header: {
          ...state.header,
          colorScheme: {
            ...headerColorScheme,
            colors: action.payload,
          },
        },
      };
    case SET_FOOTER_COLORS:
      if (!state.footer) {
        return state;
      }

      const footerColorScheme = state.footer.colorScheme;
      return {
        ...state,
        footer: {
          ...state.footer,
          colorScheme: {
            ...footerColorScheme,
            colors: action.payload,
          },
        },
      };
    case SET_BETSHOP_TITLE:
      return { ...state, name: action.payload };
    case SET_BETSHOP_LOGO:
      return { ...state, logo: action.payload.logo };
    case ADD_TO_BETSHOP_NAVIGATION_ITEMS:
      return action.payload.type === NavigationType.HEADER
        ? addPageToHeader(state, action.payload)
        : addPageToFooter(state, action.payload);
    case REMOVE_FROM_BETSHOP_NAVIGATION_ITEMS:
      return action.payload.type === NavigationType.HEADER
        ? removePageFromHeader(state, action.payload)
        : removePageFromFooter(state, action.payload);
    case TOGGLE_BETSHOP_HEADER_ITEM_HIGHLIGHT:
      return toggleNavigationItemHighlight(state, action.payload);
    case RESORT_NAVIGATION:
      return resortNavigation(state, action);
    default:
      return state;
  }
};
