import { FibonacciSubject } from './fibonacci';
import { CALCULATE_FIBONACCI } from './fibonacci/types';
import { TicketSystemSubject } from './ticket-system';
import { CALCULATE_TICKET_SYSTEM, TicketSystemInput } from './ticket-system/types';
import { WebWorkerAction } from './types';

type RouteWebWorkerMessage = (action: WebWorkerAction) => void;
export const routeWebWorkerMessage: RouteWebWorkerMessage = ({ type, payload }) => {
  switch (type) {
    case CALCULATE_FIBONACCI:
      FibonacciSubject.next(payload as number);
      break;
    case CALCULATE_TICKET_SYSTEM:
      TicketSystemSubject.next(payload as TicketSystemInput);
      break;
  }
};
