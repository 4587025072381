import { TimeFilterValue } from '../store/dependencies/app-messaging/time-filter/types';

export enum NODE_ENV {
  DEVELOPMENT = 'development',
  PRODUCTION = 'production',
}

type Providers = {
  apiUrls: Record<string, string>;
  apiParams: Record<string, string>;
  socketUrls: Record<string, string>;
  ticketSocketUrls: Record<string, string>;
  timeFilter: Record<string, TimeFilterValue>;
  matchStatsUrls: Record<string, string | number>;
  singleColor: Record<string, boolean>;
  multiCashBackMinValue: Record<string, number>;
  toursExpanded: Record<string, number>;
  bonusTypes: Record<string, string>;
  defaultSports: Record<string, string[]>;
  promoImageUrl: Record<string, string>;
  promoDefaultImageUrl: Record<string, string>;
  pids: Record<string, string>;
  bookBet: Record<string, boolean>;
  defaultLanguage: Record<string, string>;
  expandTopOffer: Record<string, boolean>;
  disabledCategories: Record<string, string[]>;
  quickPickValues: Record<string, number[]>;
  betAmountStep: Record<string, number>;
};

type Environment = {
  apiUrl: string;
  graphqlApiUrl: string;
  ssoUrl: string;
  uploadsUrl: string;
  isCms: boolean;
  nodeEnv: NODE_ENV;
  apiUrls: Providers['apiUrls'];
  apiParams: Providers['apiParams'];
  socketUrls: Providers['socketUrls'];
  ticketSocketUrls: Providers['ticketSocketUrls'];
  timeFilter: Providers['timeFilter'];
  idGeolocation: string;
  game: string;
  sessionRefreshInterval: number;
  matchStatsUrls: Providers['matchStatsUrls'];
  singleColor: Providers['singleColor'];
  multiCashBackMinValue: Providers['multiCashBackMinValue'];
  toursExpanded: Providers['toursExpanded'];
  bonusTypes: Providers['bonusTypes'];
  promoImageUrl: Providers['promoImageUrl'];
  promoDefaultImageUrl: Providers['promoDefaultImageUrl'];
  marketsRefreshInterval: number;
  defaultSports: Providers['defaultSports'];
  pids: Providers['pids'];
  widgetsUrl: string;
  bookBet: Providers['bookBet'];
  defaultLanguage: Providers['defaultLanguage'];
  updateInterval: number;
  expandTopOffer: Providers['expandTopOffer'];
  disabledCategories: Providers['disabledCategories'];
  quickPickValues: Providers['quickPickValues'];
  betAmountStep: Providers['betAmountStep'];
};

type GetProviderUrls = <T extends Record<string, string>>(env: T) => Providers;
const getProviderUrls: GetProviderUrls = env =>
  Object.entries(env).reduce(
    (acc, [key, val]) => {
      const apiUrlMatch = key.match(/REACT_APP_(.*)_API_URL/);
      if (apiUrlMatch && apiUrlMatch[1] !== 'GRAPHQL') {
        return { ...acc, apiUrls: { ...acc.apiUrls, [apiUrlMatch[1].toLowerCase()]: val } };
      }

      const apiParamsMatch = key.match(/REACT_APP_(.*)_API_PARAMS/);
      if (apiParamsMatch) {
        return { ...acc, apiParams: { ...acc.apiParams, [apiParamsMatch[1].toLowerCase()]: val } };
      }

      const socketUrlMatch = key.match(/REACT_APP_(.*)_API_SOCKET_URL/);
      if (socketUrlMatch) {
        return { ...acc, socketUrls: { ...acc.socketUrls, [socketUrlMatch[1].toLowerCase()]: val } };
      }

      const ticketSocketUrlMatch = key.match(/REACT_APP_(.*)_TICKET_SOCKET_URL/);
      if (ticketSocketUrlMatch) {
        return { ...acc, ticketSocketUrls: { ...acc.ticketSocketUrls, [ticketSocketUrlMatch[1].toLowerCase()]: val } };
      }

      const providerSettings = key.match(/REACT_APP_(.*)_TIMEFILTER/);
      if (providerSettings) {
        return { ...acc, timeFilter: { ...acc.timeFilter, [providerSettings[1].toLowerCase()]: val } };
      }

      const apiUrlMatchStats = key.match(/REACT_APP_(.*)_MATCH_STATS_URL/);
      if (apiUrlMatchStats) {
        return { ...acc, matchStatsUrls: { ...acc.matchStatsUrls, [apiUrlMatchStats[1].toLowerCase()]: val } };
      }

      const multiCashBackMinValue = key.match(/REACT_APP_(.*)_CASH_BACK_MIN_VALUE/);
      if (multiCashBackMinValue) {
        return {
          ...acc,
          multiCashBackMinValue: { ...acc.multiCashBackMinValue, [multiCashBackMinValue[1].toLowerCase()]: val },
        };
      }

      const singleColor = key.match(/REACT_APP_(.*)_SINGLE_COLOR_UI/);
      if (singleColor) {
        return { ...acc, singleColor: { ...acc.singleColor, [singleColor[1].toLowerCase()]: val === 'true' } };
      }

      const toursExpanded = key.match(/REACT_APP_(.*)_TOURS_EXPANDED_NUMBER/);
      if (toursExpanded) {
        return { ...acc, toursExpanded: { ...acc.toursExpanded, [toursExpanded[1].toLowerCase()]: val } };
      }

      const bonusTypes = key.match(/REACT_APP_(.*)_BONUS_TYPES/);
      if (bonusTypes) {
        return { ...acc, bonusTypes: { ...acc.bonusTypes, [bonusTypes[1].toLowerCase()]: val } };
      }

      const defaultSports = key.match(/REACT_APP_(.*)_DEFAULT_SPORTS/);
      if (defaultSports) {
        return { ...acc, defaultSports: { ...acc.defaultSports, [defaultSports[1].toLowerCase()]: val.split(',') } };
      }

      const promoImageUrl = key.match(/REACT_APP_(.*)_PROMO_IMAGE_URL/);
      if (promoImageUrl) {
        return { ...acc, promoImageUrl: { ...acc.promoImageUrl, [promoImageUrl[1].toLowerCase()]: val } };
      }

      const promoDefaultImageUrl = key.match(/REACT_APP_(.*)_PROMO_IMAGE_DEFAULT_URL/);
      if (promoDefaultImageUrl) {
        return {
          ...acc,
          promoDefaultImageUrl: { ...acc.promoDefaultImageUrl, [promoDefaultImageUrl[1].toLowerCase()]: val },
        };
      }

      const pids = key.match(/REACT_APP_(.*)_PID/);
      if (pids) {
        return {
          ...acc,
          pids: { ...acc.pids, [pids[1].toLowerCase()]: val },
        };
      }

      const bookBet = key.match(/REACT_APP_(.*)_BOOKABET/);
      if (bookBet) {
        return {
          ...acc,
          bookBet: { ...acc.bookBet, [bookBet[1].toLowerCase()]: val === 'true' },
        };
      }

      const defaultLanguage = key.match(/REACT_APP_(.*)_DEFAULT_LANGUAGE/);
      if (defaultLanguage) {
        return { ...acc, defaultLanguage: { ...acc.defaultLanguage, [defaultLanguage[1].toLowerCase()]: val } };
      }

      const expandTopOffer = key.match(/REACT_APP_(.*)_EXPAND_TOP_OFFER/);
      if (expandTopOffer) {
        return { ...acc, expandTopOffer: { ...acc.expandTopOffer, [expandTopOffer[1].toLowerCase()]: val === 'true' } };
      }

      const disabledCategories = key.match(/REACT_APP_(.*)_DISABLED_CATEGORIES/);
      if (disabledCategories) {
        return {
          ...acc,
          disabledCategories: {
            ...acc.disabledCategories,
            [disabledCategories[1].toLowerCase()]: val.split(','),
          },
        };
      }

      const quickPickValues = key.match(/REACT_APP_(.*)_QUICKPICK_VALUES/);
      if (quickPickValues) {
        return {
          ...acc,
          quickPickValues: {
            ...acc.quickPickValues,
            [quickPickValues[1].toLowerCase()]: val.split(',').map(v => +v),
          },
        };
      }

      const betAmountStep = key.match(/REACT_APP_(.*)_BET_AMOUNT_STEP/);
      if (betAmountStep) {
        return {
          ...acc,
          betAmountStep: {
            ...acc.betAmountStep,
            [betAmountStep[1].toLowerCase()]: +val,
          },
        };
      }

      return acc;
    },
    {
      apiUrls: {},
      socketUrls: {},
      ticketSocketUrls: {},
      apiParams: {},
      timeFilter: {},
      matchStatsUrls: {},
      singleColor: {},
      multiCashBackMinValue: {},
      toursExpanded: {},
      bonusTypes: {},
      defaultSports: {},
      promoImageUrl: {},
      promoDefaultImageUrl: {},
      pids: {},
      bookBet: {},
      defaultLanguage: {},
      expandTopOffer: {},
      disabledCategories: {},
      quickPickValues: {},
      betAmountStep: {},
    },
  ) as Providers;

export const environment: Environment = {
  apiUrl: process.env.REACT_APP_API_URL as string,
  graphqlApiUrl: process.env.REACT_APP_GRAPHQL_API_URL as string,
  ssoUrl: process.env.REACT_APP_SSO_URL as string,
  uploadsUrl: process.env.REACT_APP_UPLOADS_URL as string,
  isCms: process.env.REACT_APP_CMS === 'true',
  nodeEnv: process.env.NODE_ENV as NODE_ENV,
  idGeolocation: process.env.REACT_APP_ID_GEOLOCATION as string,
  game: process.env.REACT_APP_GAME as string,
  sessionRefreshInterval: Number(process.env.REACT_APP_SESSION_REFRESH_INTERVAL) as number,
  marketsRefreshInterval: Number(process.env.REACT_APP_MARKETS_REFRESH_INTERVAL) as number,
  ...getProviderUrls(process.env as Record<string, string>),
  widgetsUrl: process.env.REACT_APP_WIDGETS_URL as string,
  updateInterval: Number(process.env.REACT_APP_UPDATE_INTERVAL || 500) as number,
};
