import { mapMarket } from './common';
import { AltOddsChangeMessage, mapSocketMessage } from './map-socket-message';

export function mapOddsChange(message: AltOddsChangeMessage) {
  return {
    ...mapSocketMessage(message),
    data: {
      id_event: message.data.EId,
      id_sport: message.data.SId,
      id_tournament: message.data.TId,
      markets: message.data.M.map(mapMarket),
    },
  };
}
