import { BROADCAST_MESSAGE } from '../types';
import { SET_TICKET_META, SetTicketMeta, TICKET_META, TicketMeta, TicketMetaPayload } from './types';

type Dispatch<Input, Output> = (input: Input) => Output & { identity: string };

type DispatchTicketMeta = Dispatch<TicketMetaPayload, TicketMeta>;
export const dispatchTicketMeta: DispatchTicketMeta = data => ({
  type: TICKET_META,
  payload: data,
  identity: BROADCAST_MESSAGE,
});

type DispatchSetTicketMeta = Dispatch<TicketMetaPayload, SetTicketMeta>;
export const dispatchSetTicketMeta: DispatchSetTicketMeta = data => ({
  type: SET_TICKET_META,
  payload: data,
  identity: BROADCAST_MESSAGE,
});
