import { Matcher } from 'navi';
import React, { FC, createContext, useState } from 'react';
import { Router } from 'react-navi';

import { useConfigContext } from '../features/config/state/context';
import generateRoutes from './routes';

const RouterContext = createContext({});

const RouterContextProvider: FC = ({ children }) => {
  const {
    state: { betshopConfig },
  } = useConfigContext();
  const [activeRoutes, setActiveRoutes] = useState<Matcher<{}>>(() => generateRoutes(betshopConfig));

  return (
    <RouterContext.Provider value={{ setActiveRoutes }}>
      <Router routes={activeRoutes}>{children}</Router>
    </RouterContext.Provider>
  );
};

export default RouterContextProvider;
