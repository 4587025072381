import { BehaviorSubject, Subject } from 'rxjs';

import { TicketItem } from '../../../../shared/types/widget-types/TicketItem';
import { REMOVE_ODD_FROM_TICKET, TOGGLE_ODD } from '../../../../themes/orion-tip/widgets/Ticket/store/types';
import {
  AddToTicket,
  CASHBACK_DISABLE,
  CashBackDisable,
  ClearTicket,
  ENABLE_MAX_BUTTON,
  EnableMaxButton,
  RemoveFromTicket,
  RemoveOddFromTicket,
  SET_BALANCE_TO_BET_AMOUNT,
  SetBalanceToBetAmount,
  TICKET_ITEMS,
  TicketItems,
  TicketSubmitSuccess,
  ToggleOdd,
} from './types';

const initialToggleOddMessage: ToggleOdd = {
  identity: '',
  type: TOGGLE_ODD,
  payload: {} as TicketItem,
};

const disableCashBackMessage: CashBackDisable = {
  identity: '',
  type: CASHBACK_DISABLE,
  payload: null,
};

const initialRemoveOddFromTicketMessage: RemoveOddFromTicket = {
  identity: '',
  type: REMOVE_ODD_FROM_TICKET,
  payload: {} as TicketItem,
};

const initialTicketItemsMessage: TicketItems = {
  identity: '',
  type: TICKET_ITEMS,
  payload: [],
};

const setBalanceToBetAmountMessage: SetBalanceToBetAmount = {
  identity: '',
  type: SET_BALANCE_TO_BET_AMOUNT,
  payload: null,
};

const enableMaxButtonMessage: EnableMaxButton = {
  identity: '',
  type: ENABLE_MAX_BUTTON,
  payload: null,
};

export type TicketItemsSubject = BehaviorSubject<TicketItems>;
export const TicketItemsStream: TicketItemsSubject = new BehaviorSubject<TicketItems>(initialTicketItemsMessage);

export type ToggleOddSubject = BehaviorSubject<ToggleOdd>;
export const ToggleOddStream: ToggleOddSubject = new BehaviorSubject<ToggleOdd>(initialToggleOddMessage);

export type DisableCashBack = BehaviorSubject<CashBackDisable>;
export const DisableCashBackStream: DisableCashBack = new BehaviorSubject<CashBackDisable>(disableCashBackMessage);

export type RemoveOddFromTicketSubject = BehaviorSubject<RemoveOddFromTicket>;
export const RemoveOddFromTicketStream = new BehaviorSubject<RemoveOddFromTicket>(initialRemoveOddFromTicketMessage);

export type SetBalanceToBetAmountSubject = BehaviorSubject<SetBalanceToBetAmount>;
export const SetBalanceToBetAmountStream = new BehaviorSubject<SetBalanceToBetAmount>(setBalanceToBetAmountMessage);

export type EnableMaxButtonSubject = BehaviorSubject<EnableMaxButton>;
export const EnableMaxButtonStream = new BehaviorSubject<EnableMaxButton>(enableMaxButtonMessage);

export type TicketItemAddRemoveSubject = Subject<
  | AddToTicket
  | RemoveFromTicket
  | ClearTicket
  | TicketSubmitSuccess
  | CashBackDisable
  | SetBalanceToBetAmount
  | EnableMaxButton
>;
export const TicketItemAddRemoveStream: TicketItemAddRemoveSubject = new Subject<
  | AddToTicket
  | RemoveFromTicket
  | ClearTicket
  | TicketSubmitSuccess
  | CashBackDisable
  | SetBalanceToBetAmount
  | EnableMaxButton
>();
