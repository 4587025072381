import { ActionWithIdentity } from '../../../../shared/types/ActionWithIdentity';
import { BookmarkedTournaments, SelectedTournaments } from '../../../../shared/types/widget-types/SportFilterSport';
import { SelectorView } from '../view-selector/types';

export const SET_SELECTED_SPORT = 'SET_SELECTED_SPORT';
export const SPORT_SELECTED = 'SPORT_SELECTED';

export const SET_SELECTED_SPORTS = 'SET_SELECTED_SPORTS';
export const SPORTS_SELECTED = 'SPORTS_SELECTED';

export const SET_SELECTED_TOURNAMENTS_BROADCAST = 'SET_SELECTED_TOURNAMENTS_BROADCAST';
export const TOURNAMENTS_SELECTED_BROADCAST = 'TOURNAMENTS_SELECTED_BROADCAST';

export const SET_SELECTED_EVENTS = 'SET_SELECTED_EVENTS';
export const EVENTS_SELECTED = 'EVENTS_SELECTED';

export const SET_BOOKMARKED_TOURNAMENTS = 'SET_BOOKMARKED_TOURNAMENTS';
export const TOURNAMENTS_BOOKMARKED = 'TOURNAMENTS_BOOKMARKED';

export const SET_BOOKMARKED_SPORTS = 'SET_BOOKMARKED_SPORTS';
export const SPORTS_BOOKMARKED = 'SPORTS_BOOKMARKED';

export const SET_SPORTS_ORDER = 'SET_SPORTS_ORDER';
export const SPORTS_ORDERED = 'SPORTS_ORDERED';

export const SET_SPORT_FILTER_LIVE_STATUS = 'SET_SPORT_FILTER_LIVE_STATUS';
export const SPORT_FILTER_LIVE_STATUS_SET = 'SPORT_FILTER_LIVE_STATUS_SET';

export const SET_FAVORITE_EVENTS = 'SET_FAVORITE_EVENTS';
export const FAVORITE_EVENTS_SET = 'FAVORITE_EVENTS_SET';

export type EventWithSport = {
  eventId: string;
  sportId: string;
};

export type SportFilterStreamState = {
  [widgetId: string]: {
    selectedSport: string | null;
    selectedTournaments: SelectedTournaments;
    selectedSports: string[];
    selectedSportsAnnouncement: string[];
    selectedEvents: EventWithSport[];
    bookmarkedTournaments: BookmarkedTournaments;
    bookmarkedSports: string[];
    bookmarkedSportsLive: string[];
    favoriteLiveEvents: EventWithSport[];
    sportsOrder: string[];
    isLive: boolean;
  };
};
type SidebarSportMessage<T> = ActionWithIdentity<T, SportFilterStreamState>;

export type SportSelected = SidebarSportMessage<typeof SPORT_SELECTED>;
export type SetSelectedSport = ActionWithIdentity<typeof SET_SELECTED_SPORT, string | null>;

export type SportsSelected = SidebarSportMessage<typeof SPORTS_SELECTED>;
export type SetSelectedSportsPayload = {
  sportIds: string[];
  selectedView?: SelectorView;
};
export type SetSelectedSports = ActionWithIdentity<typeof SET_SELECTED_SPORTS, SetSelectedSportsPayload>;

export type TournamentsSelectedBroadcast = SidebarSportMessage<typeof TOURNAMENTS_SELECTED_BROADCAST>;
export type SetSelectedTournamentsBroadcast = ActionWithIdentity<
  typeof SET_SELECTED_TOURNAMENTS_BROADCAST,
  SelectedTournaments
>;

export type EventsSelected = SidebarSportMessage<typeof EVENTS_SELECTED>;
export type SetSelectedEvents = ActionWithIdentity<typeof SET_SELECTED_EVENTS, EventWithSport[]>;

export type SportsBookmarked = SidebarSportMessage<typeof SPORTS_BOOKMARKED>;
export type SetBookmarkedSports = ActionWithIdentity<
  typeof SET_BOOKMARKED_SPORTS,
  { isLive: boolean; sportIds: string[] }
>;

export type SportsOrdered = SidebarSportMessage<typeof SPORTS_ORDERED>;
export type SetSportsOrder = ActionWithIdentity<typeof SET_SPORTS_ORDER, string[]>;

export type TournamentsBookmarked = SidebarSportMessage<typeof TOURNAMENTS_BOOKMARKED>;
export type SetBookmarkedTournaments = ActionWithIdentity<typeof SET_BOOKMARKED_TOURNAMENTS, BookmarkedTournaments>;

export type SportFilterLiveStatusSet = SidebarSportMessage<typeof SPORT_FILTER_LIVE_STATUS_SET>;
export type SetSportFilterLiveStatus = ActionWithIdentity<typeof SET_SPORT_FILTER_LIVE_STATUS, boolean>;

export type FavoriteLiveEventsSet = SidebarSportMessage<typeof FAVORITE_EVENTS_SET>;
export type SetFavoriteLiveEvents = ActionWithIdentity<typeof SET_FAVORITE_EVENTS, EventWithSport[]>;

type SportFilterAction =
  | SetSelectedSport
  | SetSelectedSports
  | SetSelectedTournamentsBroadcast
  | SetBookmarkedTournaments
  | SetBookmarkedSports
  | SetSportsOrder
  | SetSportFilterLiveStatus
  | SetFavoriteLiveEvents
  | SetSelectedEvents;

export type SportFilterReducer = (state: SportFilterStreamState, action: SportFilterAction) => SportFilterStreamState;
