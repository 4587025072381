import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

import {
  TICKET_SYSTEM_CALCULATED,
  TicketSystemCalculatedAction,
  TicketSystemInput,
  TicketSystemResponse,
} from './types';
import { TicketSystem } from './worker';

const worker = new TicketSystem();
export const TicketSystemSubject = new Subject<TicketSystemInput>();
export const TicketSystemResultSubject = new Subject<TicketSystemCalculatedAction>();

TicketSystemSubject.pipe(debounceTime(1000)).subscribe(data => {
  worker.postMessage(data);
});

worker.addEventListener('message', ({ data }) => {
  TicketSystemResultSubject.next({
    type: TICKET_SYSTEM_CALCULATED,
    payload: data as TicketSystemResponse,
  });
});
