import { BehaviorSubject } from 'rxjs';

import {
  VIEW_SELECTOR_FILTER_SELECTED,
  VIEW_SELECTOR_VIEW_SELECTED,
  ViewSelectorFilterSelected,
  ViewSelectorViewSelected,
} from './types';

export type ViewSelectedSubject = BehaviorSubject<ViewSelectorViewSelected>;
export const ViewSelectedStream: ViewSelectedSubject = new BehaviorSubject({
  type: VIEW_SELECTOR_VIEW_SELECTED,
  payload: {},
  identity: '',
});

export type FilterSelectedSubject = BehaviorSubject<ViewSelectorFilterSelected>;
export const FilterSelectedStream: FilterSelectedSubject = new BehaviorSubject({
  type: VIEW_SELECTOR_FILTER_SELECTED,
  payload: {},
  identity: '',
});

export const ViewSelectorStreams = [ViewSelectedStream, FilterSelectedStream];
