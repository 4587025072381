import { useCallback, useEffect, useMemo } from 'react';
import { Dispatch } from 'react';
import { useLazyQuery } from 'react-apollo';
import { useCurrentRoute, useNavigation } from 'react-navi';

import { cmsDashboardRoute } from '../../../../routing/routes/cms';
import { DEFAULT_SLUGS } from '../../../../routing/routes/shared';
import { Betshop } from '../../../../shared-types/graphql';
import { ReFetchQuery } from '../../../../shared/types/RefetchQuery';
import { useBetshopPagesGraphQL } from '../../betshop-pages/graphql/useBetshopPagesGraphQL';
import { UpdatePages } from '../../betshop-pages/types';
import { useBetshopPageEditActions } from '../state/actions';
import {
  BetshopPageEditAction,
  SetBetshopNaviItemsText,
  SetBetshopPageName,
  SetBetshopPagesData,
} from '../state/types';
import { GET_BETSHOP_PAGES } from './queries';

export type BetshopPageEditActions = {
  setBetshopPagesData: SetBetshopPagesData;
  setBetshopPageName: SetBetshopPageName;
  setBetshopNaviItemsText: SetBetshopNaviItemsText;
  updatePagesAndRefetch: UpdatePages;
  refetchBetshop: ReFetchQuery;
  refetchBetshopPages: () => void;
};

type GetBetshopPages = {
  betshop: Betshop;
};
type UseBetshopPageEditGraphQL = (state: Betshop, dispatch: Dispatch<BetshopPageEditAction>) => BetshopPageEditActions;
export const useBetshopPageEditGraphQL: UseBetshopPageEditGraphQL = (state, dispatch) => {
  const {
    lastChunk: { request },
  } = useCurrentRoute();
  const { navigate } = useNavigation();

  const { setBetshopPagesData, setBetshopPageName, setBetshopNaviItemsText } = useBetshopPageEditActions(
    state,
    dispatch,
  );
  const [getBetshopPages, { data, refetch, called }] = useLazyQuery<GetBetshopPages>(GET_BETSHOP_PAGES, {
    fetchPolicy: 'network-only',
  });
  const betshopId = useMemo(() => request && request.mountpath.includes(DEFAULT_SLUGS.PAGE_EDIT) && request.params.id, [
    request,
  ]);
  const refetchBetshop: ReFetchQuery = useMemo(
    () => ({
      refetchQueries: [{ query: GET_BETSHOP_PAGES, variables: { _id: betshopId } }],
      awaitRefetchQueries: true,
    }),
    [betshopId],
  );
  const refetchBetshopPages = useCallback(
    () => (called ? refetch({ _id: betshopId }) : getBetshopPages({ variables: { _id: betshopId } })),
    [betshopId, called, getBetshopPages, refetch],
  );

  const { updatePages: updatePagesAndRefetch } = useBetshopPagesGraphQL({ updatePagesOptions: refetchBetshop });

  useEffect(() => {
    betshopId && getBetshopPages({ variables: { _id: betshopId } });
  }, [betshopId, getBetshopPages]);

  useEffect(() => {
    if (data) {
      data.betshop ? setBetshopPagesData(data.betshop) : navigate(cmsDashboardRoute.path);
    }
  }, [data, setBetshopPagesData, navigate]);

  return {
    refetchBetshop,
    setBetshopPagesData,
    setBetshopPageName,
    setBetshopNaviItemsText,
    updatePagesAndRefetch,
    refetchBetshopPages,
  };
};
