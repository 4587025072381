import { Observable } from 'rxjs';

export const CALCULATE_TICKET_SYSTEM = 'CALCULATE_TICKET_SYSTEM';
export const CALCULATE_MB_TICKET_SYSTEM = 'CALCULATE_MB_TICKET_SYSTEM';
export const TICKET_SYSTEM_CALCULATED = 'TICKET_SYSTEM_CALCULATED';

export type OTTicketSystemData = {
  odds: number[];
  systemMin: number;
};

type EventOdds = number[]; // Each event can have multiple oddtypes selected
export type MBTicketSystemsData = {
  systems: {
    odds: EventOdds[];
    systemMins: number[];
  }[];
};

export type OTTicketSystemInput = {
  type: 'OT';
  data: OTTicketSystemData;
};
export type MBTicketSystemInput = {
  type: 'MB';
  data: MBTicketSystemsData;
};
export type TicketSystemInput = OTTicketSystemInput | MBTicketSystemInput;

export type CalculateTicketSystemAction = {
  type: typeof CALCULATE_TICKET_SYSTEM;
  payload: TicketSystemInput;
};

export type TicketSystemResponse = {
  numberOfCombinations: number;
  minPossibleOddValue: number;
  maxPossibleOddValue: number;
};
export type TicketSystemCalculatedAction = {
  type: typeof TICKET_SYSTEM_CALCULATED;
  payload: TicketSystemResponse;
};

export type CalculateTicketSystem = (ticketSystemInput: TicketSystemInput) => Observable<TicketSystemCalculatedAction>;
