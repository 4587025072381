import { useEffect } from 'react';
import { BehaviorSubject } from 'rxjs';

import { BetshopType } from '../../../shared-types/BetshopType';
import { DeviceType } from '../app-messaging/ticket-params/types';

export type Settings = {
  betshopType: BetshopType;
  provider: string;
  deviceType?: DeviceType;
  isDeviceBlocked?: boolean;
};
const SettingsStream = new BehaviorSubject<Settings | null>(null);
const settings$ = SettingsStream.asObservable();

export const useSettingsEffect = (cb: (settings: Settings | null) => void) => {
  useEffect(() => {
    const settingsSub$ = settings$.subscribe(cb);

    return () => settingsSub$.unsubscribe();
  }, [cb]);
};

export const updateSettings = (settings: Settings) => {
  SettingsStream.next(settings);
};

export default settings$;
