import { BehaviorSubject } from 'rxjs';

import { MATCH_TRACKER_DATA_SET, MatchTrackerDataSet, MatchTrackerPayload } from './types';

const initialMatchTracker: MatchTrackerPayload = { eventId: '', hasLiveTracker: false };

export type MatchTrackerSubject = BehaviorSubject<MatchTrackerDataSet>;
export const MatchTrackerStream: MatchTrackerSubject = new BehaviorSubject<MatchTrackerDataSet>({
  identity: '',
  type: MATCH_TRACKER_DATA_SET,
  payload: initialMatchTracker,
});
