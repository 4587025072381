import {
  SET_VIEW_SELECTOR_FILTER,
  SET_VIEW_SELECTOR_VIEW,
  SelectorFilter,
  SelectorView,
  SetViewSelectorFilter,
  SetViewSelectorView,
  VIEW_SELECTOR_FILTER_SELECTED,
  VIEW_SELECTOR_VIEW_SELECTED,
  ViewSelectorFilterSelected,
  ViewSelectorState,
  ViewSelectorViewSelected,
} from './types';

export const dispatchSetViewSelectorView: (identity: string, view: SelectorView) => SetViewSelectorView = (
  identity,
  view,
) => ({
  type: SET_VIEW_SELECTOR_VIEW,
  payload: view,
  identity,
});

export const dispatchViewSelectorViewSelected: (
  identity: string,
  viewSelectorStreamState: ViewSelectorState,
) => ViewSelectorViewSelected = (identity, viewSelectorStreamState) => ({
  type: VIEW_SELECTOR_VIEW_SELECTED,
  payload: viewSelectorStreamState,
  identity,
});

export const dispatchSetViewSelectorFilter: (identity: string, filter: SelectorFilter) => SetViewSelectorFilter = (
  identity,
  filter,
) => ({
  type: SET_VIEW_SELECTOR_FILTER,
  payload: filter,
  identity,
});

export const dispatchViewSelectorFilterSelected: (
  identity: string,
  viewSelectorStreamState: ViewSelectorState,
) => ViewSelectorFilterSelected = (identity, viewSelectorStreamState) => ({
  type: VIEW_SELECTOR_FILTER_SELECTED,
  payload: viewSelectorStreamState,
  identity,
});
