import { Dispatch, useCallback } from 'react';

import { Betshop } from '../../../shared-types/graphql';
import { ConfigAction, ConfigState, SET_BETSHOP_CONFIG } from '../types';

export type SetBetshopConfig = (config: Betshop) => void;
type ConfigActions = {
  setBetshopConfig: SetBetshopConfig;
};
type UseConfigActions = (state: ConfigState, dispatch: Dispatch<ConfigAction>) => ConfigActions;

export const useConfigActions: UseConfigActions = (_state, dispatch) => {
  const setBetshopConfig: SetBetshopConfig = useCallback(
    config => {
      dispatch({
        type: SET_BETSHOP_CONFIG,
        payload: config,
      });
    },
    [dispatch],
  );

  return { setBetshopConfig };
};
