import { BROADCAST_MESSAGE } from '../types';
import {
  HIDE_REGISTRATION_MODAL,
  HideRegistrationModal,
  REGISTRATION_MODAL_STATE_SET,
  RegistrationModalState,
  RegistrationModalStateSet,
  SHOW_REGISTRATION_MODAL,
  ShowRegistrationModal,
} from './types';

export type DispatchShowRegistrationModal = () => ShowRegistrationModal;
export const dispatchShowRegistrationModal: DispatchShowRegistrationModal = () => ({
  type: SHOW_REGISTRATION_MODAL,
  payload: null,
  identity: BROADCAST_MESSAGE,
});

export type DispatchHideRegistrationModal = () => HideRegistrationModal;
export const dispatchHideRegistrationModal: DispatchHideRegistrationModal = () => ({
  type: HIDE_REGISTRATION_MODAL,
  payload: null,
  identity: BROADCAST_MESSAGE,
});

export type dispatchRegistrationModalStateSet = (state: RegistrationModalState) => RegistrationModalStateSet;
export const dispatchRegistrationModalStateSet: dispatchRegistrationModalStateSet = state => ({
  type: REGISTRATION_MODAL_STATE_SET,
  payload: state,
  identity: BROADCAST_MESSAGE,
});
