import { BehaviorSubject } from 'rxjs';

import { BROADCAST_MESSAGE } from '../types';
import {
  EVENTS_SELECTED,
  EventsSelected,
  FAVORITE_EVENTS_SET,
  FavoriteLiveEventsSet,
  SPORTS_BOOKMARKED,
  SPORTS_ORDERED,
  SPORTS_SELECTED,
  SPORT_FILTER_LIVE_STATUS_SET,
  SPORT_SELECTED,
  SportFilterLiveStatusSet,
  SportSelected,
  SportsBookmarked,
  SportsOrdered,
  SportsSelected,
  TOURNAMENTS_BOOKMARKED,
  TOURNAMENTS_SELECTED_BROADCAST,
  TournamentsBookmarked,
  TournamentsSelectedBroadcast,
} from './types';

export type SportSelectedSubject = BehaviorSubject<SportSelected>;
export type SportsSelectedSubject = BehaviorSubject<SportsSelected>;
export type TournamentsSelectedBroadcastSubject = BehaviorSubject<TournamentsSelectedBroadcast>;
export type TournamentsBookmarkedSubject = BehaviorSubject<TournamentsBookmarked>;
export type SportsBookmarkedSubject = BehaviorSubject<SportsBookmarked>;
export type SportsOrderedSubject = BehaviorSubject<SportsOrdered>;
export type SportFilterLiveStatusSetSubject = BehaviorSubject<SportFilterLiveStatusSet>;
export type FavoriteLiveEventsSetSubject = BehaviorSubject<FavoriteLiveEventsSet>;
export type EventsSelectedSubject = BehaviorSubject<EventsSelected>;

const initialSidebarSportStreamMessage: SportSelected = {
  identity: '',
  type: SPORT_SELECTED,
  payload: {},
};

const initialSidebarSportsSelectedMessage: SportsSelected = {
  identity: '',
  type: SPORTS_SELECTED,
  payload: {},
};

const initialTournamentsSelectedBroadcastMessage: TournamentsSelectedBroadcast = {
  identity: BROADCAST_MESSAGE,
  type: TOURNAMENTS_SELECTED_BROADCAST,
  payload: {},
};

const initialTournamentsBookmarkedMessage: TournamentsBookmarked = {
  identity: '',
  type: TOURNAMENTS_BOOKMARKED,
  payload: {},
};

const initialSportsBookmarkedMessage: SportsBookmarked = {
  identity: '',
  type: SPORTS_BOOKMARKED,
  payload: {},
};

const initialSportsOrderedMessage: SportsOrdered = {
  identity: '',
  type: SPORTS_ORDERED,
  payload: {},
};

const initialSportFilterLiveStatus: SportFilterLiveStatusSet = {
  identity: '',
  type: SPORT_FILTER_LIVE_STATUS_SET,
  payload: {},
};

const initialFavoriteLiveEvents: FavoriteLiveEventsSet = {
  identity: '',
  type: FAVORITE_EVENTS_SET,
  payload: {},
};

const initialEventsSelectedMessage: EventsSelected = {
  identity: '',
  type: EVENTS_SELECTED,
  payload: {},
};

export const SportSelectedStream: SportSelectedSubject = new BehaviorSubject(initialSidebarSportStreamMessage);

export const SportsSelectedStream: SportsSelectedSubject = new BehaviorSubject(initialSidebarSportsSelectedMessage);

export const TournamentsSelectedBroadcastStream: TournamentsSelectedBroadcastSubject = new BehaviorSubject(
  initialTournamentsSelectedBroadcastMessage,
);

export const TournamentsBookmarkedStream: TournamentsBookmarkedSubject = new BehaviorSubject(
  initialTournamentsBookmarkedMessage,
);

export const SportsBookmarkedStream: SportsBookmarkedSubject = new BehaviorSubject(initialSportsBookmarkedMessage);

export const SportsOrderedStream: SportsOrderedSubject = new BehaviorSubject(initialSportsOrderedMessage);

export const SportFilterLiveStatusSetStream: SportFilterLiveStatusSetSubject = new BehaviorSubject(
  initialSportFilterLiveStatus,
);
export const FavoriteLiveEventsSetStream: FavoriteLiveEventsSetSubject = new BehaviorSubject(initialFavoriteLiveEvents);

export const EventsSelectedStream: EventsSelectedSubject = new BehaviorSubject(initialEventsSelectedMessage);

export const SportFilterStreams = [
  SportSelectedStream,
  SportsSelectedStream,
  TournamentsSelectedBroadcastStream,
  TournamentsBookmarkedStream,
  SportsBookmarkedStream,
  SportsOrderedStream,
  SportFilterLiveStatusSetStream,
  FavoriteLiveEventsSetStream,
  EventsSelectedStream,
];
