import { Dispatch, useCallback } from 'react';

import { DropZoneType } from '../../../shared-types/drop-zone-type';
import {
  DRAG_FINISHED,
  DnDAction,
  DnDContextState,
  DragFinishResult,
  SET_AVAILABLE_ZONES,
  SET_DROP_RESULT,
  SET_IS_DRAG_ALLOWED,
  SET_SELECTED_DROP_ZONE,
} from '../types';

type SetIsDragAllowed = (isAllowed: boolean) => void;
type SetAvailableZones = (zones: DropZoneType[]) => void;
type FinishDrag = () => void;
type SetDropResult = (dropResult: DragFinishResult | null) => void;
type SetSelectedDropZone = (zone: string | null) => void;
export type DnDActions = {
  setIsDragAllowed: SetIsDragAllowed;
  setAvailableZones: SetAvailableZones;
  finishDrag: FinishDrag;
  setDropResult: SetDropResult;
  setSelectedDropZone: SetSelectedDropZone;
};
export type UseDnDActions = (state: DnDContextState, dispatch: Dispatch<DnDAction>) => DnDActions;

export const useDnDActions: UseDnDActions = (_state, dispatch) => {
  const setIsDragAllowed: SetIsDragAllowed = useCallback(
    isAllowed => {
      dispatch({
        type: SET_IS_DRAG_ALLOWED,
        payload: isAllowed,
      });
    },
    [dispatch],
  );

  const setAvailableZones: SetAvailableZones = useCallback(
    zones => {
      dispatch({
        type: SET_AVAILABLE_ZONES,
        payload: zones,
      });
    },
    [dispatch],
  );

  const finishDrag = useCallback(() => {
    dispatch({
      type: DRAG_FINISHED,
      payload: null,
    });
  }, [dispatch]);

  const setDropResult: SetDropResult = useCallback(
    dropResult => {
      dispatch({
        type: SET_DROP_RESULT,
        payload: dropResult,
      });
    },
    [dispatch],
  );

  const setSelectedDropZone: SetSelectedDropZone = useCallback(
    zone => {
      dispatch({
        type: SET_SELECTED_DROP_ZONE,
        payload: zone,
      });
    },
    [dispatch],
  );

  return {
    setIsDragAllowed,
    setAvailableZones,
    finishDrag,
    setDropResult,
    setSelectedDropZone,
  };
};
