import { ActionWithIdentity } from '../../../../shared/types/ActionWithIdentity';

export const SET_TIME_FILTER_VALUE = 'SET_TIME_FILTER_VALUE';

export type SetTimeFilterValue = ActionWithIdentity<typeof SET_TIME_FILTER_VALUE, TimeFilterValue>;

export type TimeFilterState = {
  [timeFilterId: string]: {
    timeFilterValue: TimeFilterValue;
  };
};

export enum TimeFilterValue {
  All = '',
  ThreeHours = '3H',
  FiveHours = '5H',
  SixHours = '6H',
  TwelveHours = '12H',
  TwentyFourHours = '24H',
  OneDay = '1D',
  TwoDays = '2D',
  ThreeeDays = '3D',
  FourDays = '4D',
  FiveDays = '5D',
  SixDays = '6D',
  SevenDays = '7D',
  Today = 0,
  DateInOneDay = 1,
  DateInTwoDays = 2,
  DateInThreeeDays = 3,
  DateInFourDays = 4,
  DateInFiveDays = 5,
  DateInSixDays = 6,
}

export type TimeFilterReducer = (state: TimeFilterState, action: SetTimeFilterValue) => TimeFilterState;
