import { BehaviorSubject } from 'rxjs';

import { TICKET_META, TicketMeta } from './types';

export type TicketMetaSubject = BehaviorSubject<TicketMeta>;
export const TicketMetaStream: TicketMetaSubject = new BehaviorSubject<TicketMeta>({
  identity: '',
  type: TICKET_META,
  payload: null,
});
