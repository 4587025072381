import { lazy } from 'navi';

import { Route } from '../types/Route';
import { RouteWithView } from '../types/RouteWithView';
import { DEFAULT_SLUGS } from './shared';

export const cmsDashboardRoute: Route = {
  path: '/',
  title: 'Dashboard',
};

export const cmsBetshopPreviewRoute: Route = {
  path: '/preview/:id',
  title: 'Preview Betshop',
};

export const cmsBetshopPreviewActivationRoute: Route = {
  path: '/preview/:id/activate',
  title: 'User Activation',
};

export const cmsBetshopPagePreviewRoute: Route = {
  path: '/preview/:id/:pageSlug',
  title: 'Preview Route',
};

export const cmsBetshopEditRoute: Route = {
  path: '/betshop-edit/:id',
  title: 'Edit Betshop',
};

export const cmsBetshopEditPagePreviewRoute: Route = {
  path: '/betshop-edit/:id/:pageSlug',
  title: 'Edit Betshop',
};

export const cmsBetshopPageEditRoute: Route = {
  path: `/betshop/:id/${DEFAULT_SLUGS.PAGE_EDIT}/:pageSlug`,
  title: 'Edit Betshop Page',
};

export const cmsBetshopPageEditRootRoute: Route = {
  path: `/betshop/:id/${DEFAULT_SLUGS.PAGE_EDIT}`,
  title: 'Edit Betshop Page',
};

export const cmsRoutes: RouteWithView[] = [
  {
    path: cmsDashboardRoute.path,
    component: () => lazy(() => import('../../pages/Home/Home')),
    title: cmsDashboardRoute.title,
  },
  {
    path: cmsBetshopPreviewRoute.path,
    component: () => lazy(() => import('../../pages/BetshopView/BetshopView')),
    title: cmsBetshopPreviewRoute.title,
  },
  {
    path: cmsBetshopPreviewActivationRoute.path,
    component: () => lazy(() => import('../../themes/maxbet/pages/Activation')),
    title: cmsBetshopPreviewActivationRoute.title,
  },
  {
    path: cmsBetshopPagePreviewRoute.path,
    component: () => lazy(() => import('../../pages/BetshopView/BetshopView')),
    title: cmsBetshopPreviewRoute.title,
  },
  {
    path: cmsBetshopEditRoute.path,
    component: () => lazy(() => import('../../pages/BetshopEdit/BetshopEdit')),
    title: cmsBetshopEditRoute.title,
  },
  {
    path: cmsBetshopEditPagePreviewRoute.path,
    component: () => lazy(() => import('../../pages/BetshopEdit/BetshopEdit')),
    title: cmsBetshopEditRoute.title,
  },
  {
    path: cmsBetshopPageEditRoute.path,
    component: () => lazy(() => import('../../pages/BetshopPageEdit/BetshopPageEdit')),
    title: cmsBetshopPageEditRoute.title,
  },
  {
    path: cmsBetshopPageEditRootRoute.path,
    component: () => lazy(() => import('../../pages/BetshopPageEdit/BetshopPageEdit')),
    title: cmsBetshopPageEditRoute.title,
  },
];
