import { useEffect, useState } from 'react';
import { ReplaySubject } from 'rxjs';

const LocaleStream = new ReplaySubject<string>(1);
const locale$ = LocaleStream.asObservable();

export const updateLocale = (locale: string) => {
  LocaleStream.next(locale);
};

export const useLocale = () => {
  const [locale, setLocale] = useState<string>('');

  useEffect(() => {
    const localeSub$ = locale$.subscribe(setLocale);

    return () => localeSub$.unsubscribe();
  }, []);

  return { locale };
};

export default locale$;
