import gql from 'graphql-tag';

const BETSHOP_FULL_SELECTION = `{
  _id
  name
  logo
  type
  provider
  colorScheme
  header
  footer
  availableThemes {
    _id
    name
    colorScheme
    layouts {
      name
    }
  }
  activeTheme {
    _id
    name
    colorScheme
    layouts {
      name
    }
    availableWidgets{
      name
      availableIn
      component
      settings
    }
  }
  pages {
    name
    slug
    layout {
      name
    }
   content {
     leftSidebar{
       visibleInLayouts
       content {
         id
         name
         availableIn
         component
         settings
       }
     }
     rightSidebar{
       visibleInLayouts
       content {
         id
         name
         availableIn
         component
         settings
       }
     }
     main {
       visibleInLayouts
       content {
         id
         type
         content {
             first {
               id
               name
               availableIn
               component
               settings
             }
             second {
               id
               name
               availableIn
               component
               settings
             }
         }
      }
    }
  }
}
}
`;

export const GET_BETSHOP = gql`
  query betshop($_id: String!) {
    betshop(_id: $_id) ${BETSHOP_FULL_SELECTION}
  }
`;

export const UPDATE_BETSHOP = gql`
  mutation updateBetshop($betshopData: UpdateBetshopInput!) {
    updateBetshop(betshopData: $betshopData) ${BETSHOP_FULL_SELECTION}
  }
`;

export const UPLOAD_LOGO = gql`
  mutation uploadLogo($file: Upload!, $betshopId: String!) {
    uploadLogo(logo: $file, betshopId: $betshopId) {
      logo
    }
  }
`;
