import { AlertModalStream } from './alert-modal';
import { dispatchAlertModalStateSet } from './alert-modal/messages';
import { alertModalReducer } from './alert-modal/reducer';
import { ACTIVATE_ALERT_MODAL, DEACTIVATE_ALERT_MODAL } from './alert-modal/types';
import { DeviceTypeStream } from './event-presenter';
import { dispatchDeviceTypeSet } from './event-presenter/messages';
import { deviceReducer } from './event-presenter/reducer';
import { SET_DEVICE_TYPE } from './event-presenter/types';
import { MarketsSelectedStream } from './markets';
import { dispatchMarketsSelected } from './markets/messages';
import { selectedMarketsReducer } from './markets/reducer';
import { SET_SELECTED_MARKETS } from './markets/types';
import {
  IsDeviceBlockedStream,
  IsNavigationBlockedStream,
  PreviousPageStream,
  SelectedEventStream,
} from './navigation';
import {
  dispatchIsDeviceBlockedSet,
  dispatchIsNavigationBlockedSet,
  dispatchPreviousPageSet,
  dispatchSelectedEventSet,
} from './navigation/messages';
import { navigationReducer } from './navigation/reducer';
import {
  SET_IS_DEVICE_BLOCKED,
  SET_IS_NAVIGATION_BLOCKED,
  SET_PREVIOUS_PAGE,
  SET_SELECTED_EVENT,
} from './navigation/types';
import { RegistrationModalStream } from './registration';
import { dispatchRegistrationModalStateSet } from './registration/messages';
import { registrationModalReducer } from './registration/reducer';
import { HIDE_REGISTRATION_MODAL, SHOW_REGISTRATION_MODAL } from './registration/types';
import { SidebarLabelReloadLive } from './sidebar-label';
import { liveEventsReloaded } from './sidebar-label/messages';
import { reloadLiveEventsReducer } from './sidebar-label/reducer';
import { RELOAD_LIVE_EVENTS } from './sidebar-label/types';
import { SpinnerStream } from './spinner';
import { dispatchSpinnerStateSet } from './spinner/messages';
import { spinnerReducer } from './spinner/reducer';
import { ACTIVATE_SPINNER, DEACTIVATE_SPINNER, RESET_SPINNER } from './spinner/types';
import {
  EventsSelectedStream,
  FavoriteLiveEventsSetStream,
  SportFilterLiveStatusSetStream,
  SportSelectedStream,
  SportsBookmarkedStream,
  SportsOrderedStream,
  SportsSelectedStream,
  TournamentsBookmarkedStream,
  TournamentsSelectedBroadcastStream,
} from './sport-filter';
import {
  dispatchEventsSelected,
  dispatchFavoriteLiveEventsSet,
  dispatchSportFilterLiveStatusSet,
  dispatchSportSelected,
  dispatchSportsBookmarked,
  dispatchSportsOrdered,
  dispatchSportsSelected,
  dispatchTournamentsBookmarked,
  dispatchTournamentsSelectedBroadcast,
} from './sport-filter/messages';
import { sportFilterReducer } from './sport-filter/reducer';
import {
  SET_BOOKMARKED_SPORTS,
  SET_BOOKMARKED_TOURNAMENTS,
  SET_FAVORITE_EVENTS,
  SET_SELECTED_EVENTS,
  SET_SELECTED_SPORT,
  SET_SELECTED_SPORTS,
  SET_SELECTED_TOURNAMENTS_BROADCAST,
  SET_SPORTS_ORDER,
  SET_SPORT_FILTER_LIVE_STATUS,
} from './sport-filter/types';
import {
  EnableMaxButtonStream,
  RemoveOddFromTicketStream,
  SetBalanceToBetAmountStream,
  TicketItemAddRemoveStream,
  TicketItemsStream,
} from './ticket-items';
import {
  dispatchAddToTicket,
  dispatchCashBackDisabled,
  dispatchClearTicket,
  dispatchEnableMaxButton,
  dispatchRemoveFromTicket,
  dispatchRemoveOddFromTicket,
  dispatchSetBalanceToBetAmount,
  dispatchTicketItems,
  dispatchTicketSubmitSuccess,
} from './ticket-items/messages';
import { ticketItemsReducer } from './ticket-items/reducer';
import {
  ADD_TO_TICKET,
  CASHBACK_DISABLE,
  CLEAR_MARGIN_CHANGES,
  CLEAR_TICKET,
  ENABLE_MAX_BUTTON,
  REMOVE_FROM_TICKET,
  REMOVE_ODD_FROM_TICKET,
  SET_BALANCE_TO_BET_AMOUNT,
  SET_TICKET_ITEMS,
  TICKET_SUBMIT_SUCCESS,
  TOGGLE_ODD_TYPE,
} from './ticket-items/types';
import { MatchTrackerStream } from './ticket-match-tracker';
import { dispatchMatchTrackerDataSet } from './ticket-match-tracker/messages';
import { matchTrackerReducer } from './ticket-match-tracker/reducer';
import { SET_MATCH_TRACKER_DATA } from './ticket-match-tracker/types';
import { TicketMetaStream } from './ticket-meta';
import { dispatchTicketMeta } from './ticket-meta/messages';
import { ticketMetaReducer } from './ticket-meta/reducer';
import { SET_TICKET_META } from './ticket-meta/types';
import { TicketParamsStream } from './ticket-params';
import { dispatchTicketParamsStateSet } from './ticket-params/messages';
import { ticketParamsReducer } from './ticket-params/reducer';
import { SET_TICKET_PARAMS } from './ticket-params/types';
import { TimeFilterStream } from './time-filter';
import { dispatchTimeFilterStreamMessage } from './time-filter/messages';
import { timeFilterReducer } from './time-filter/reducer';
import { SET_TIME_FILTER_VALUE } from './time-filter/types';
import { PublisherStreamMessage } from './types';
import { FilterSelectedStream, ViewSelectedStream } from './view-selector';
import { dispatchViewSelectorFilterSelected, dispatchViewSelectorViewSelected } from './view-selector/messages';
import { viewSelectorReducer } from './view-selector/reducer';
import { SET_VIEW_SELECTOR_FILTER, SET_VIEW_SELECTOR_VIEW } from './view-selector/types';
import { WebTicketItemsStream } from './web-ticket-items';
import { dispatchWebTicketItems } from './web-ticket-items/messages';
import { webTicketItemsReducer } from './web-ticket-items/reducer';
import { SET_WEB_TICKET_ITEMS } from './web-ticket-items/types';

type MessageRouter = (message: PublisherStreamMessage) => void;
export const routeMessage: MessageRouter = message => {
  switch (message.type) {
    case ADD_TO_TICKET:
      TicketItemAddRemoveStream.next(dispatchAddToTicket(message.identity, message.payload));
      return TicketItemsStream.next(
        dispatchTicketItems(message.identity, ticketItemsReducer(TicketItemsStream.value.payload, message)),
      );
    case REMOVE_FROM_TICKET:
      TicketItemAddRemoveStream.next(dispatchRemoveFromTicket(message.identity, message.payload));
      return TicketItemsStream.next(
        dispatchTicketItems(message.identity, ticketItemsReducer(TicketItemsStream.value.payload, message)),
      );
    case CLEAR_TICKET:
      TicketItemAddRemoveStream.next(dispatchClearTicket(message.identity, null));
      return TicketItemsStream.next(
        dispatchTicketItems(message.identity, ticketItemsReducer(TicketItemsStream.value.payload, message)),
      );
    case TICKET_SUBMIT_SUCCESS:
      return TicketItemAddRemoveStream.next(dispatchTicketSubmitSuccess(message.identity, null));
    case CASHBACK_DISABLE:
      return TicketItemAddRemoveStream.next(dispatchCashBackDisabled(message.identity, null));
    case SET_TICKET_ITEMS:
      const currentStream = TicketItemsStream.value.payload;
      const ticketItems = ticketItemsReducer(currentStream, message);

      return TicketItemsStream.next(dispatchTicketItems(message.identity, ticketItems));
    case SET_WEB_TICKET_ITEMS:
      return WebTicketItemsStream.next(
        dispatchWebTicketItems(webTicketItemsReducer(WebTicketItemsStream.value.payload, message)),
      );
    case CLEAR_MARGIN_CHANGES:
      const clearedItems = TicketItemsStream.value.payload.map(item => ({ ...item, hasMarginChanged: false }));
      return TicketItemsStream.next(dispatchTicketItems(message.identity, clearedItems));
    case SET_TICKET_META:
      return TicketMetaStream.next(dispatchTicketMeta(ticketMetaReducer(TicketMetaStream.value.payload, message)));
    case TOGGLE_ODD_TYPE:
      return TicketItemsStream.next(
        dispatchTicketItems(message.identity, ticketItemsReducer(TicketItemsStream.value.payload, message)),
      );
    case REMOVE_ODD_FROM_TICKET:
      return RemoveOddFromTicketStream.next(dispatchRemoveOddFromTicket(message.identity, message.payload));
    case SET_SELECTED_SPORT:
      return SportSelectedStream.next(
        dispatchSportSelected(sportFilterReducer(SportSelectedStream.value.payload, message)),
      );
    case SET_SELECTED_SPORTS:
      return SportsSelectedStream.next(
        dispatchSportsSelected(message.identity, sportFilterReducer(SportsSelectedStream.value.payload, message)),
      );
    case SET_SELECTED_TOURNAMENTS_BROADCAST:
      return TournamentsSelectedBroadcastStream.next(
        dispatchTournamentsSelectedBroadcast(
          message.identity,
          sportFilterReducer(TournamentsSelectedBroadcastStream.value.payload, message),
        ),
      );
    case SET_BOOKMARKED_TOURNAMENTS:
      return TournamentsBookmarkedStream.next(
        dispatchTournamentsBookmarked(
          message.identity,
          sportFilterReducer(TournamentsBookmarkedStream.value.payload, message),
        ),
      );
    case SET_BOOKMARKED_SPORTS:
      return SportsBookmarkedStream.next(
        dispatchSportsBookmarked(message.identity, sportFilterReducer(SportsBookmarkedStream.value.payload, message)),
      );
    case SET_SPORTS_ORDER:
      return SportsOrderedStream.next(
        dispatchSportsOrdered(message.identity, sportFilterReducer(SportsOrderedStream.value.payload, message)),
      );
    case SET_SPORT_FILTER_LIVE_STATUS:
      return SportFilterLiveStatusSetStream.next(
        dispatchSportFilterLiveStatusSet(
          message.identity,
          sportFilterReducer(SportFilterLiveStatusSetStream.value.payload, message),
        ),
      );
    case SET_FAVORITE_EVENTS:
      return FavoriteLiveEventsSetStream.next(
        dispatchFavoriteLiveEventsSet(
          message.identity,
          sportFilterReducer(FavoriteLiveEventsSetStream.value.payload, message),
        ),
      );
    case SET_TIME_FILTER_VALUE:
      return TimeFilterStream.next(
        dispatchTimeFilterStreamMessage(message.identity, timeFilterReducer(TimeFilterStream.value.payload, message)),
      );
    case SET_SELECTED_MARKETS:
      return MarketsSelectedStream.next(
        dispatchMarketsSelected(selectedMarketsReducer(MarketsSelectedStream.value.payload, message)),
      );
    case RELOAD_LIVE_EVENTS:
      return SidebarLabelReloadLive.next(
        liveEventsReloaded(message.identity, reloadLiveEventsReducer(SidebarLabelReloadLive.value.payload, message)),
      );
    case ACTIVATE_SPINNER:
      return SpinnerStream.next(dispatchSpinnerStateSet(spinnerReducer(SpinnerStream.value.payload, message)));
    case DEACTIVATE_SPINNER:
    case RESET_SPINNER:
      return SpinnerStream.next(dispatchSpinnerStateSet(spinnerReducer(SpinnerStream.value.payload, message)));

    case ACTIVATE_ALERT_MODAL:
    case DEACTIVATE_ALERT_MODAL:
      return AlertModalStream.next(
        dispatchAlertModalStateSet(alertModalReducer(AlertModalStream.value.payload, message)),
      );
    case SET_PREVIOUS_PAGE:
      return PreviousPageStream.next(
        dispatchPreviousPageSet(navigationReducer(PreviousPageStream.value.payload, message)),
      );
    case SET_IS_DEVICE_BLOCKED:
      return IsDeviceBlockedStream.next(
        dispatchIsDeviceBlockedSet(navigationReducer(IsDeviceBlockedStream.value.payload, message)),
      );
    case SET_SELECTED_EVENT:
      return SelectedEventStream.next(
        dispatchSelectedEventSet(navigationReducer(SelectedEventStream.value.payload, message)),
      );
    case SET_IS_NAVIGATION_BLOCKED:
      return IsNavigationBlockedStream.next(
        dispatchIsNavigationBlockedSet(navigationReducer(IsNavigationBlockedStream.value.payload, message)),
      );
    case SHOW_REGISTRATION_MODAL:
    case HIDE_REGISTRATION_MODAL:
      return RegistrationModalStream.next(
        dispatchRegistrationModalStateSet(registrationModalReducer(RegistrationModalStream.value.payload, message)),
      );
    case SET_VIEW_SELECTOR_VIEW:
      return ViewSelectedStream.next(
        dispatchViewSelectorViewSelected(
          message.identity,
          viewSelectorReducer(ViewSelectedStream.value.payload, message),
        ),
      );
    case SET_VIEW_SELECTOR_FILTER:
      return FilterSelectedStream.next(
        dispatchViewSelectorFilterSelected(
          message.identity,
          viewSelectorReducer(FilterSelectedStream.value.payload, message),
        ),
      );
    case SET_TICKET_PARAMS:
      return TicketParamsStream.next(
        dispatchTicketParamsStateSet(ticketParamsReducer(TicketParamsStream.value.payload, message)),
      );
    case SET_SELECTED_EVENTS:
      return EventsSelectedStream.next(
        dispatchEventsSelected(message.identity, sportFilterReducer(EventsSelectedStream.value.payload, message)),
      );
    case SET_MATCH_TRACKER_DATA:
      return MatchTrackerStream.next(
        dispatchMatchTrackerDataSet(matchTrackerReducer(MatchTrackerStream.value.payload, message)),
      );
    case SET_BALANCE_TO_BET_AMOUNT:
      return SetBalanceToBetAmountStream.next(dispatchSetBalanceToBetAmount(message.identity, null));
    case ENABLE_MAX_BUTTON:
      return EnableMaxButtonStream.next(dispatchEnableMaxButton(message.identity, null));
    case SET_DEVICE_TYPE:
      return DeviceTypeStream.next(dispatchDeviceTypeSet(deviceReducer(DeviceTypeStream.value.payload, message)));
    default:
      return;
  }
};
