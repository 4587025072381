import { Betshop } from '../../shared-types/graphql';

export const SET_BETSHOP_CONFIG = 'SET_BETSHOP_CONFIG';

export type ConfigState = {
  betshopConfig: Betshop;
};

export type SetBetshopAction = {
  type: typeof SET_BETSHOP_CONFIG;
  payload: Betshop;
};

export type ConfigAction = SetBetshopAction;
export type ConfigReducer = (state: ConfigState, action: ConfigAction) => ConfigState;
