import { BehaviorSubject } from 'rxjs';
import { tap } from 'rxjs/operators';

import { getValueForKeyInSessionStorage, storeInSessionStorage } from '../../../../utils/widget-utils/storage';
import { BROADCAST_MESSAGE } from '../types';
import { MARKETS_SELECTED, MarketsSelected, SelectedMarketsState } from './types';

const initialSelectedMarketsMessage: SelectedMarketsState = {
  selectedMarkets: {
    live: {},
    prematch: {},
  },
};

export type MarketsSelectedStream = BehaviorSubject<MarketsSelected>;
export const MarketsSelectedStream: MarketsSelectedStream = new BehaviorSubject<MarketsSelected>({
  type: MARKETS_SELECTED,
  payload: getValueForKeyInSessionStorage('selectedMarkets') || initialSelectedMarketsMessage,
  identity: BROADCAST_MESSAGE,
});

MarketsSelectedStream.pipe(tap(a => storeInSessionStorage('selectedMarkets', a.payload))).subscribe();
