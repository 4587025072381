import { RELOAD_LIVE_EVENTS, ReloadLiveEventsReducer } from './types';

export const reloadLiveEventsReducer: ReloadLiveEventsReducer = (state = {}, action) => {
  switch (action.type) {
    case RELOAD_LIVE_EVENTS:
      return {
        ...state,
        [action.identity]: {
          lastReload: action.payload,
        },
      };
    default:
      return state;
  }
};
