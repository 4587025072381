import 'simplebar/dist/simplebar.min.css';

import React, { Suspense } from 'react';
import { View } from 'react-navi';
import { Provider } from 'react-redux';

import { AppContextProvider } from './shared/context/AppContext';
import GlobalStyle from './shared/global-style';
import { useStore } from './store';

const IGNORED_ERRORS = ['AjaxError', 'ResizeObserver'];

const App = () => {
  const store = useStore();
  window.onerror = e => {
    if (
      !IGNORED_ERRORS.some(ignoredError => {
        return e.toString().includes(ignoredError);
      })
    ) {
      // TODO: Add some kind of error reporting
      console.log(e);
      window.location.reload();
    }
  };

  return (
    <AppContextProvider>
      <Provider store={store.current}>
        <Suspense fallback={<div>Loading...</div>}>
          <View />
          <GlobalStyle />
        </Suspense>
      </Provider>
    </AppContextProvider>
  );
};

export default App;
