import { Stats } from '../../../../shared/types/widget-types/EventsPresenterSport';
import { PeriodScore } from '../../../../shared/types/widget-types/response/EventsResponse';
import {
  EventChangeLiveMessage,
  EventChangeMessage,
  EventChangePrematchMessage,
  EventResultMessage,
  NewEventMessage,
  OddsChangeMessage,
  ParsedSocketMessage,
  SocketMessageBase,
} from '../types';
import { AltIdNamePairWithLocalisation, AltMarket, AltScore, CommonMessageData } from './common';

type AltSocketMessageBase = {
  id: SocketMessageBase['id'];
  PId: SocketMessageBase['id_provider'];
  ts: number;
  Me: SocketMessageBase['meta'];
};

type AltNewMatchData = {
  A: EventChangeLiveMessage['data']['active'];
  CT: EventChangeLiveMessage['data']['current_time'];
  ReT: EventChangeLiveMessage['data']['remaining_time'];
  TS: EventChangeLiveMessage['data']['time_stopped'];
  StT: EventChangeLiveMessage['data']['stoppage_time'];
  LS: EventChangeLiveMessage['data']['live_status'];
  CP: EventChangeLiveMessage['data']['current_period'];
  BC: EventChangeLiveMessage['data']['bet_count'];
  RBC: EventChangeLiveMessage['data']['result_bet_count'];
  BS: EventChangeLiveMessage['data']['bet_status'];
  S: EventChangeLiveMessage['data']['status'];
  LC: EventChangeLiveMessage['data']['listCode'];
  UTC: EventChangeLiveMessage['data']['utc_scheduled'];
  L: EventChangeLiveMessage['data']['live'];
  AMC: EventChangeLiveMessage['data']['active_market_count'];
  AOC?: EventChangeLiveMessage['data']['active_oddtype_count'];
  MB: EventChangeLiveMessage['data']['manually_blocked'];
  OP: EventChangeLiveMessage['data']['odds_provider'];
  M: AltMarket[];
  Sp: AltIdNamePairWithLocalisation;
  Ca: AltIdNamePairWithLocalisation;
  To: AltIdNamePairWithLocalisation;
  Co: AltIdNamePairWithLocalisation[];
  CS: AltScore;
};

export type AltEventChangeLiveMessage = AltSocketMessageBase & {
  type: EventChangeLiveMessage['type'];
  data: AltNewMatchData & CommonMessageData;
};

export type AltEventChangePrematchMessage = AltSocketMessageBase & {
  type: EventChangePrematchMessage['type'];
  data: AltNewMatchData & CommonMessageData;
};

export type AltEventChangeMessage = AltSocketMessageBase & {
  type: EventChangeMessage['type'];
  data: {
    A: EventChangeMessage['data']['active'];
    CT: EventChangeMessage['data']['current_time'];
    ReT: EventChangeMessage['data']['remaining_time'];
    TS: EventChangeMessage['data']['time_stopped'];
    StT: EventChangeMessage['data']['stoppage_time'];
    CP: EventChangeMessage['data']['current_period'];
    S: EventChangeMessage['data']['status'];
    LS: EventChangeMessage['data']['live_status'];
    AOC: EventChangeMessage['data']['active_oddtype_count'];
    L: EventChangeMessage['data']['live'];
    UTC: EventChangeMessage['data']['utc_scheduled'];
  } & CommonMessageData;
};

export type AltStatScore = {
  HS: number;
  AS: number;
};

export type AltStats = {
  CaR: AltStatScore;
  CaY: AltStatScore;
  Co: AltStatScore;
  Pos: Stats['possession'];
};

export type AltPeriodScore = {
  P: PeriodScore['period'];
  S: AltScore;
};

export type AltEventResultMessage = AltSocketMessageBase & {
  type: EventResultMessage['type'];
  data: {
    CT: EventResultMessage['data']['current_time'];
    ReT: EventResultMessage['data']['remaining_time'];
    TS: EventResultMessage['data']['time_stopped'];
    StT: EventResultMessage['data']['stoppage_time'];
    CP: EventResultMessage['data']['current_period'];
    CS: AltScore;
    St: AltStats;
    PS: {
      P: PeriodScore['period'];
      S: AltScore;
    }[];
    CGS: AltScore;
  } & CommonMessageData;
};

export type AltNewEventMessage = AltSocketMessageBase & {
  type: NewEventMessage['type'];
  data: {
    A: NewEventMessage['data']['active'];
    CT: NewEventMessage['data']['current_time'];
    ReT: NewEventMessage['data']['remaining_time'];
    TS: NewEventMessage['data']['time_stopped'];
    StT: NewEventMessage['data']['stoppage_time'];
    LS: NewEventMessage['data']['live_status'];
    CP: NewEventMessage['data']['current_period'];
    BC: NewEventMessage['data']['bet_count'];
    S: NewEventMessage['data']['status'];
    LC: NewEventMessage['data']['listCode'];
    UTC: NewEventMessage['data']['utc_scheduled'];
    L: NewEventMessage['data']['live'];
    AMC: NewEventMessage['data']['active_market_count'];
    O: NewEventMessage['data']['order'];
    Sp: AltIdNamePairWithLocalisation;
    Ca: AltIdNamePairWithLocalisation;
    To: AltIdNamePairWithLocalisation & { O: number };
    Co: AltIdNamePairWithLocalisation[];
    PD: NewEventMessage['data']['live_provider_data'];
  } & CommonMessageData;
};

export type AltOddsChangeMessage = AltSocketMessageBase & {
  type: OddsChangeMessage['type'];
  data: {
    M: AltMarket[];
  } & CommonMessageData;
};

type AltSocketMessage =
  | AltEventChangeLiveMessage
  | AltEventChangePrematchMessage
  | AltEventChangeMessage
  | AltEventResultMessage
  | AltNewEventMessage
  | AltOddsChangeMessage;

export function mapSocketMessage(message: AltSocketMessage): Omit<ParsedSocketMessage, 'data'> {
  return {
    id: message.id,
    ts: message.ts,
    type: message.type,
    id_provider: message.PId,
    meta: message.Me,
  };
}
