import { TOGGLE_SIDEBAR, UIContextState, UIReducer } from './types';

export const initalUIState: UIContextState = {
  sidebarExpanded: true,
};

export const uiReducer: UIReducer = (state, action) => {
  switch (action.type) {
    case TOGGLE_SIDEBAR:
      return { ...state, sidebarExpanded: !state.sidebarExpanded };
    default:
      return state;
  }
};
