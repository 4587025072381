import { merge } from 'rxjs';
import { filter } from 'rxjs/operators';
import useDeepCompareEffect from 'use-deep-compare-effect';

import { FibonacciResultSubject, FibonacciSubject } from './fibonacci';
import { CalculateFibonacci } from './fibonacci/types';
import { routeWebWorkerMessage } from './router';
import { TicketSystemResultSubject, TicketSystemSubject } from './ticket-system';
import { CalculateTicketSystem } from './ticket-system/types';
import { WebWorkerAction, WebWorkerResult, WebWorkerResultHandler } from './types';

const workerStream$ = merge<WebWorkerResult>(
  ...[FibonacciResultSubject, TicketSystemResultSubject].map(m => m.asObservable()),
);

type UseWebWorker = (
  messageHandler: WebWorkerResultHandler,
  listenFor: WebWorkerResult['type'],
  action?: WebWorkerAction,
) => void;
export const useWebWorker: UseWebWorker = (messageHandler, listenFor, action) => {
  useDeepCompareEffect(() => {
    if (action) {
      routeWebWorkerMessage(action);
    }
    const sub = workerStream$.pipe(filter(res => res.type === listenFor)).subscribe(messageHandler);

    return () => sub.unsubscribe();
  }, [messageHandler, listenFor, action]);
};

const calculateFibonacci: CalculateFibonacci = n => {
  FibonacciSubject.next(n);

  return FibonacciResultSubject.asObservable();
};

const calculateTicketSystem: CalculateTicketSystem = ticketSystemInput => {
  TicketSystemSubject.next(ticketSystemInput);

  return TicketSystemResultSubject.asObservable();
};

export type WorkerDependencies = {
  calculateFibonacci: CalculateFibonacci;
  calculateTicketSystem: CalculateTicketSystem;
};
const Worker: WorkerDependencies = {
  calculateFibonacci,
  calculateTicketSystem,
};

export default Worker;
