import { BehaviorSubject } from 'rxjs';

import { initialTimeFilterMessage } from './reducer';
import { SET_TIME_FILTER_VALUE, TimeFilterState } from './types';

export type TimeFilterStreamMessage = {
  type: typeof SET_TIME_FILTER_VALUE;
  payload: TimeFilterState;
  identity: string;
};

export type TimeFilterStream = BehaviorSubject<TimeFilterStreamMessage>;
export const TimeFilterStream: TimeFilterStream = new BehaviorSubject<TimeFilterStreamMessage>({
  type: SET_TIME_FILTER_VALUE,
  payload: initialTimeFilterMessage,
  identity: '',
});
