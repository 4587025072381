import { DefaultTheme } from 'styled-components/macro';

import { ColorOption, ColorOptions } from '../shared-types/color-scheme';

type C = (theme: DefaultTheme, color: ColorOptions) => string;
export const c: C = (theme, color) => {
  switch (color.kind) {
    case ColorOption.MaxbetBase:
    case ColorOption.OrionTipBase:
      return theme.colors.base[color.color] as string;
    case ColorOption.MaxbetHeader:
      return (theme.colors.header && theme.colors.header[color.color]
        ? theme.colors.header[color.color]
        : theme.colors.base[color.color]) as string;
    case ColorOption.MaxbetFooter:
      return (theme.colors.footer && theme.colors.footer[color.color]
        ? theme.colors.footer[color.color]
        : theme.colors.base[color.color]) as string;
  }
};
