export type OnBuildNotification = (roomName: string, zipname: string) => void;
export type BuildNotification = {
  message: string;
  done?: boolean;
  failed?: boolean;
};

export type BetshopBuildState = {
  openedRooms: string[];
};

export const ADD_TO_OPENED_ROOMS = 'ADD_TO_OPENED_ROOMS';
export const REMOVE_FROM_OPENED_ROOMS = 'REMOVE_FROM_OPENED_ROOMS';

export type AddToOpenedRoomsAction = {
  type: typeof ADD_TO_OPENED_ROOMS;
  payload: string;
};
export type RemoveFromOpenedRoomsAction = {
  type: typeof REMOVE_FROM_OPENED_ROOMS;
  payload: string;
};

export type BetshopBuildAction = AddToOpenedRoomsAction | RemoveFromOpenedRoomsAction;

export type BetshopBuildReducer = (state: BetshopBuildState, action: BetshopBuildAction) => BetshopBuildState;
