import { Subject } from 'rxjs';
import { tap } from 'rxjs/operators';

import { FIBONACCI_CALCULATED, FibonacciCalculatedAction } from './types';
import { Fibonacci } from './worker';

const worker = new Fibonacci();
export const FibonacciSubject = new Subject<number>();
export const FibonacciResultSubject = new Subject<FibonacciCalculatedAction>();

FibonacciSubject.pipe(tap(console.log)).subscribe(data => {
  worker.postMessage(data);
});

worker.addEventListener('message', ({ data }) => {
  FibonacciResultSubject.next({
    type: FIBONACCI_CALCULATED,
    payload: data as number,
  });
});
