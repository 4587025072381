import { mapScore } from './common';
import { AltEventResultMessage, AltPeriodScore, AltStatScore, AltStats, mapSocketMessage } from './map-socket-message';

function mapStatScore(statScore: AltStatScore) {
  return {
    home_score: statScore.HS,
    away_score: statScore.AS,
  };
}

function mapStats(stats: AltStats) {
  if (!stats) {
    return;
  }
  return {
    cards_red: mapStatScore(stats.CaR || {}),
    cards_yellow: mapStatScore(stats.CaY || {}),
    corners: mapStatScore(stats.Co || {}),
    possession: stats.Pos,
  };
}

function mapPeriodScore(periodScore: AltPeriodScore) {
  return {
    period: periodScore.P,
    score: mapScore(periodScore.S),
  };
}

export function mapEventResult(message: AltEventResultMessage) {
  return {
    ...mapSocketMessage(message),
    data: {
      id_sport: message.data.SId,
      id_tournament: message.data.TId,
      id_event: message.data.EId,
      current_time: message.data.CT,
      remaining_time: message.data.ReT,
      time_stopped: message.data.TS,
      stoppage_time: message.data.StT,
      current_period: message.data.CP,
      current_score: mapScore(message.data.CS),
      stats: mapStats(message.data.St),
      period_scores: message.data.PS.map(mapPeriodScore),
      current_gamescore: mapScore(message.data.CGS),
    },
  };
}
