import { FullTicketSystems } from '../../../shared-types/ticket-types';
import { ProviderData, Stats } from '../../../shared/types/widget-types/EventsPresenterSport';
import { IdNamePair } from '../../../shared/types/widget-types/IdNamePair';
import { Score } from '../../../shared/types/widget-types/response/EventsResponse';

type MetaData = {
  firstName?: string;
  lastName?: string;
  nin?: number;
  jackpot?: {
    amount: number;
    imgUrl: string;
  };
};

export type Room = {
  identity: string;
  name: string;
};

export enum SOCKET_ACTIONS {
  JOIN = 'join',
  LEAVE = 'leave',
}

export type Callback = (eventsChanged: ParsedSocketMessage[]) => void;
export type Filters = {
  id_sport?: string[];
  id_tournament?: string[];
  id_event?: string[];
};

export enum SOCKET_MESSAGE_TYPE {
  NEW_EVENT = 'new_event',
  EVENT_CHANGE = 'event_change',
  EVENT_CHANGE_LIVE = 'event_change_live',
  EVENT_CHANGE_PREMATCH = 'event_change_prematch',
  ODDS_CHANGE = 'odds_change',
  BET_ODDS = 'bet_odds',
  EVENT_RESULT = 'event_result',
  INVALID = 'invalid',
  PLACED = 'placed',
  REFUNDED = 'refunded',
  WINNING = 'winning',
  REQUESTED = 'requested',
  LOSING = 'losing',
  CASHOUT = 'cashout',
  DIGITAIN_MAX_WIN_AMOUNT = 'max_win_amount',
  SPORT_FILTER_UPDATE = 'sport_filter_update',
  DIGITAIN_LAST_TICKETS = 'digitain_last_tickets',
  LAST_TICKETS = 'last_tickets',
  STOP_BETTING = 'stop_betting',
  WITHDRAWN = 'withdrawn',
  JACKPOT_CHANGE = 'jackpot_change',
  JACKPOT_WON = 'jackpot_won',
  DIGITAIN_JACKPOT_CHANGE = 'digitain_jackpot_change',
  DIGITAIN_JACKPOT_WON = 'digitain_jackpot_won',
}

export enum EVENT_STATUS {
  NOT_RUNNING = 'NOT_RUNNING',
  RUNNING = 'RUNNING',
  FINISHED = 'FINISHED',
  STOPPED = 'STOPPED',
  NOT_FINISHED = 'NOT_FINISHED',
  INITIAL = 'INITIAL',
}
export enum EVENT_LIVE_STATUS {
  ANNOUNCED = 'ANNOUNCED',
  LIVE = 'LIVE',
  DISABLED = 'DISABLED',
}
export enum Period {
  UNSPECIFIED = 'UNSPECIFIED',
  FIRST_HALF = 'FIRST_HALF',
  SECOND_HALF = 'SECOND_HALF',
  OVERTIME = 'OVERTIME',
  PENALTIES = 'PENALTIES',
  FIRST_QUARTER = 'FIRST_QUARTER',
  SECOND_QUARTER = 'SECOND_QUARTER',
  THIRD_QUARTER = 'THIRD_QUARTER',
  FOURTH_QUARTER = 'FOURTH_QUARTER',
  FIRST_PERIOD = 'FIRST_PERIOD',
  SECOND_PERIOD = 'SECOND_PERIOD',
  THIRD_PERIOD = 'THIRD_PERIOD',
  FINISHED = 'FINISHED',
}

export enum MARKET_STATUS {
  UNDEFINED = 'UNDEFINED',
  CANCELED = 'CANCELED',
  TEMPORARY_REMOVED = 'TEMPORARY_REMOVED',
  ACTIVE = 'ACTIVE',
  REMOVED = 'REMOVED',
  CLOSED = 'CLOSED',
  RESET = 'RESET',
}
export enum ODD_TYPE_STATUS {
  ACTIVE = 1,
  DISABLED = 0,
  FINISHED = -1,
}

export enum TICKET_STATUS {
  REQUESTED = 0,
  PLACED = 1,
  REFUNDED = 2,
  WINNING = 3,
  LOSING = 4,
  INVALID = 5,
  NEED_PLAYER_DETAILS = 8,
}

export const DIGITAIN_TICKET_STATUS = {
  NEW: 0,
  PENDING: 1,
  FINISHED: 2,
  PAID: 3,
  PAID_BY_OFFICE: 4,
  INVALID: 5,
  REFUNDED: 6,
};

export const DIGITAIN_TICKET_STATUSES: (keyof typeof DIGITAIN_TICKET_STATUS)[] = [
  'NEW',
  'PENDING',
  'FINISHED',
  'PAID',
  'PAID_BY_OFFICE',
  'INVALID',
  'REFUNDED',
];

export enum DEVICE_TYPE {
  PRESENTATION = 0,
  POS = 1,
  TERMINAL = 2,
  E_TERMINAL = 3,
}

export enum BARCODE_TYPE {
  UPCA = 1,
  EAN13 = 2,
  EAN8 = 3,
  CODE128 = 4,
  CODEBAR = 5,
}

export enum TICKET_AUTH_STATUS_TYPE {
  REQUESTED = 1,
  REJECTED = 2,
  ACCEPTED = 3,
}

type PeriodScore = {
  period: number;
  score: {
    home_score: number;
    away_score: number;
  };
};

export type MarketOddType = {
  id_oddtype: string;
  status: ODD_TYPE_STATUS;
  manual_status: MARKET_STATUS | null;
  odd: number;
  special_value: number;
};

export type MarketOdd = {
  id_market: string;
  status: MARKET_STATUS;
  oddtypes: MarketOddType[];
};

export type SocketMessageBase = {
  id: string;
  type: SOCKET_MESSAGE_TYPE;
  id_provider: string;
  ts: number;
  meta: MetaData;
};

export type IdNamePairWithLocalisation = IdNamePair & {
  localisation: {
    [key: string]: string;
  };
  description: {
    web: string;
    list: string;
    mobile: string;
    terminal: string;
  };
  order?: number;
};
export type NewEventMessage = {
  type: SOCKET_MESSAGE_TYPE.NEW_EVENT;
  data: {
    id_sport: string;
    id_tournament: string;
    id_event: string;
    sport: IdNamePairWithLocalisation;
    category: IdNamePairWithLocalisation;
    tournament: IdNamePairWithLocalisation;
    competitors: IdNamePairWithLocalisation[];
    active: boolean;
    order: number;
    current_time: string;
    remaining_time: string;
    time_stopped: boolean;
    stoppage_time: string;
    live_status: EVENT_LIVE_STATUS;
    current_period: Period;
    bet_count: number;
    status: EVENT_STATUS;
    listCode: number;
    utc_scheduled: string;
    live: boolean;
    active_market_count: number;
    live_provider_data: ProviderData;
  };
} & SocketMessageBase;

export type EventChangeMessage = {
  type: SOCKET_MESSAGE_TYPE.EVENT_CHANGE;
  data: {
    id_event: string;
    id_sport: string;
    id_tournament: string;
    active: boolean;
    current_time: string;
    remaining_time: string;
    time_stopped: boolean;
    stoppage_time: string;
    current_period: Period;
    status: EVENT_STATUS;
    live_status: EVENT_LIVE_STATUS;
    active_oddtype_count: number;
    live: boolean | null;
    utc_scheduled: string;
  };
} & SocketMessageBase;

export type SocketMessageMarkets = {
  id_market: string;
  meta: {};
  status: MARKET_STATUS;
  oddtypes: MarketOddType[];
}[];

export type NewMatchData = {
  id_sport: string;
  id_tournament: string;
  id_event: string;
  sport: IdNamePairWithLocalisation;
  category: IdNamePairWithLocalisation;
  tournament: IdNamePairWithLocalisation;
  competitors: IdNamePairWithLocalisation[];
  active: boolean;
  current_time: string | null;
  remaining_time: string | null;
  time_stopped: boolean | null;
  stoppage_time: string | null;
  live_status: EVENT_LIVE_STATUS;
  current_period: Period;
  bet_count: number;
  result_bet_count: number;
  bet_status: null;
  status: EVENT_STATUS;
  listCode: number;
  utc_scheduled: string;
  live: boolean | null;
  active_market_count: number;
  active_oddtype_count: number;
  manually_blocked: boolean;
  odds_provider: IdNamePair;
  markets: SocketMessageMarkets;
  current_score: Score;
  provider_data: ProviderData;
};

export type EventChangeLiveMessage = {
  type: SOCKET_MESSAGE_TYPE.EVENT_CHANGE_LIVE;
  data: NewMatchData;
} & SocketMessageBase;

export type EventChangePrematchMessage = {
  type: SOCKET_MESSAGE_TYPE.EVENT_CHANGE_PREMATCH;
  data: NewMatchData;
} & SocketMessageBase;

export type EventResultMessage = {
  type: SOCKET_MESSAGE_TYPE.EVENT_RESULT;
  data: {
    id_event: string;
    id_sport: string;
    id_tournament: string;
    current_time: string;
    remaining_time: string;
    time_stopped: boolean;
    stoppage_time: string;
    current_period: number;
    current_score: Score;
    stats?: Stats | null;
    period_scores: PeriodScore[];
    current_gamescore: Score;
  };
} & SocketMessageBase;

export type OddsChangeMessage = {
  // this is intentional
  // don't change to SOCKET_MESSAGE_TYPE.ODDS_CHANGE
  // we receive the following format from socket:
  // ["odds_change", {type: "bet_odds", ...rest}]
  type: SOCKET_MESSAGE_TYPE.BET_ODDS;
  data: {
    id_event: string;
    id_sport: string;
    id_tournament: string;
    markets: MarketOdd[];
  };
} & SocketMessageBase;

export type Tip = {
  IdSport: string;
  TipGroup: string;
  EventName: string;
  EventType: string;
  TipFactor: number;
  EventStart: string;
  CurrentScore: string;
  CashoutEnabled: boolean;
  TournamentName: string;
  Description?: string;
};

type TicketDataJackpot = {
  amount: number;
  imgUrl?: string;
};

export type TicketData = {
  id: string;
  Tax: number;
  Tips: Tip[];
  isCopy: boolean;
  status: number;
  Barcode: string;
  BetType: 'Single' | 'Multi' | 'System';
  StakeAT: number;
  StakeBT: number;
  BrandKey: string;
  Currency: string;
  DateTime: string;
  TotalOdd: string;
  BonusType: number;
  BonusValue: number;
  PossibleWin: number;
  SystemIndex: number;
  orderStatus: number;
  PrintConfirm: boolean;
  PartnerOrderId: number;
  PossibleWinLowest: number;
  MaxPayOutPerTicket: number;
  SystemTotalCombinations: number;
  ticketTaxGuid?: string;
  jackpot?: TicketDataJackpot;
};

export type TicketMessage = {
  data: TicketData & {
    system_message: string | null;
  };
} & SocketMessageBase;

export type FullTicketMessage = {
  data: {
    id: string;
    id_location: string;
    status: TICKET_STATUS;
    auth_status: TICKET_AUTH_STATUS_TYPE;
    id_user: number;
    manual_authorization: false;
    min_quota: number | null;
    max_quota: number | null;
    max_gain: number | null;
    combinations: null;
    amount: number;
    system_message: string;
    device_info: {
      token: string;
      currency: string;
      id_location: string;
      id_device: string;
      device_type: DEVICE_TYPE;
      barcode_type: BARCODE_TYPE;
    };
    systems: FullTicketSystems[];
  };
} & SocketMessageBase;

export enum TicketScanTips {
  'NEW' = 1,
  'WIN' = 2,
  'LOST' = 3,
  'RETURN' = 4,
  'HALFLOST' = 5,
  'HALFWIN' = 6,
}

export type TicketScanEvent = {
  EventName: string;
  TournamentName: string;
  EventType: string;
  TipGroup: string;
  TipFactor: number;
  CashoutEnabled: boolean;
  EventStart: string;
  CurrentScore: string;
  ScoreAtBettingTime: string;
  StatusName: 'New' | 'Winner' | 'Lost' | 'Return' | 'Half-return' | 'Half-winner' | 'Rejected';
  StatusId: TicketScanTips;
  IdEvent: string;
  IdSport: string;
  OddKey: string;
};

export type TicketScanOTData = {
  id: string;
  Barcode: string;
  DateTime: string;
  PayOutTime: string;
  BetType: 'Single' | 'Multi' | 'System';
  TotalOdd: number;
  StakeAT: number;
  StakeBT: number;
  Tax: number;
  BonusType: number;
  BonusValue: number;
  PossibleWin: number;
  PartnerOrderId: number;
  Tips: TicketScanEvent[];
  isCopy: boolean;
  BrandKey: string;
  WonAmount: string;
  CashoutAmount: string;
  PrintConfirm: boolean;
  StatusName: string;
  StatusId: number;
  status: TICKET_STATUS;
  orderStatus: number;
  system_message?: string;
  ticketTaxGuid?: string;
};

export type TicketScanMessage = {
  data: TicketScanOTData;
} & SocketMessageBase;

export type TicketCashoutOTData = {
  id: string;
  Barcode: string;
  amount: string;
  status: TICKET_STATUS;
  system_message: string;
};

export type JackpotUpdateMessageData = {
  amount: number;
  id: number;
  id_level: number;
  round_id: number;
  win: boolean;
  id_location?: string;
  level_name?: string;
  location_name?: string;
};

export type JackpotWonMessageData = {
  amount: number;
  id: number;
  id_level: number;
  id_location: string;
  level_name?: string;
  location_name: string;
  round_id: number;
  win: boolean;
  barcode: string;
};

export type TicketCashoutMessage = {
  data: TicketCashoutOTData;
} & SocketMessageBase;

export type DigitainMaxBetAmountMessageData = {
  IdTicket: string;
  MaxWinAmount: number;
};

export type DigitainMaxBetAmountMessage = {
  data: DigitainMaxBetAmountMessageData;
} & SocketMessageBase;

export type DigitainLastTicketsMessageData = {
  Lang: string;
  RequestId: string;
  Total: number;
  tickets: TicketData[];
};

export type DigitainLastTicketsMessage = {
  data: DigitainLastTicketsMessageData;
} & SocketMessageBase;

export type JackpotUpdateMessage = {
  type: SOCKET_MESSAGE_TYPE.DIGITAIN_JACKPOT_CHANGE;
  data: JackpotUpdateMessageData;
} & SocketMessageBase;

export type JackpotWonMessage = {
  type: SOCKET_MESSAGE_TYPE.DIGITAIN_JACKPOT_WON;
  data: JackpotWonMessageData;
} & SocketMessageBase;

export type ParsedSocketMessage =
  | NewEventMessage
  | EventChangeMessage
  | EventResultMessage
  | OddsChangeMessage
  | TicketMessage
  | FullTicketMessage
  | TicketScanMessage
  | TicketCashoutMessage
  | DigitainMaxBetAmountMessage
  | DigitainLastTicketsMessage
  | JackpotUpdateMessage
  | JackpotWonMessage;

export type OriginalSocketMessage = string;

export enum SubjectType {
  GENERAL_UPDATES = 'general_updates',
  RESULT_UPDATES = 'result_updates',
  NEW_EVENT_UPDATES = 'new_event_updates',
}
