import { mapIdNamePairWithLocalisation, mapMarket, mapScore } from './common';
import { AltEventChangePrematchMessage, mapSocketMessage } from './map-socket-message';

export function mapEventChangePrematch(message: AltEventChangePrematchMessage) {
  return {
    ...mapSocketMessage(message),
    data: {
      id_sport: message.data.SId,
      id_tournament: message.data.TId,
      id_event: message.data.EId,
      active: message.data.A,
      current_time: message.data.CT,
      remaining_time: message.data.ReT,
      time_stopped: message.data.TS,
      stoppage_time: message.data.StT,
      live_status: message.data.LS,
      current_period: message.data.CP,
      bet_count: message.data.BC,
      result_bet_count: message.data.RBC,
      bet_status: message.data.BS,
      status: message.data.S,
      listCode: message.data.LC,
      utc_scheduled: message.data.UTC,
      live: message.data.L,
      active_market_count: message.data.AMC,
      active_oddtype_count: message.data.AOC,
      manually_blocked: message.data.MB,
      odds_provider: message.data.OP,
      markets: message.data.M.map(mapMarket),
      current_score: mapScore(message.data.CS),
      sport: mapIdNamePairWithLocalisation(message.data.Sp),
      category: mapIdNamePairWithLocalisation(message.data.Ca),
      tournament: mapIdNamePairWithLocalisation(message.data.To),
      competitors: message.data.Co.map(mapIdNamePairWithLocalisation),
    },
  };
}
