import { Dispatch } from 'react';

import { User } from '../../../../shared/types/User';

export const SET_USER = 'SET_USER';
export const CLEAR_USER = 'CLEAR_USER';

export type SetUser = (user: User) => void;
export type ClearUser = () => void;

type UserActions = {
  setUser: SetUser;
  clearUser: ClearUser;
};

export type ClearUserAction = {
  type: typeof CLEAR_USER;
};
export type SetUserAction = {
  type: typeof SET_USER;
  payload: User;
};

export type UserState = {
  user: User;
};

export type UserAction = SetUserAction | ClearUserAction;
export type UserReducer = (state: UserState, action: UserAction) => UserState;
export type UseUserActions = (state: UserState, dispatch: Dispatch<UserAction>) => UserActions;
