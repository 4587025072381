import { BehaviorSubject } from 'rxjs';

import { BROADCAST_MESSAGE } from '../types';
import { ALERT_MODAL_STATE_SET, AlertModalState, AlertModalStateSet } from './types';

export const initialAlertModalMessage: AlertModalState = { message: null, options: {} };

export type AlertModalStream = BehaviorSubject<AlertModalStateSet>;
export const AlertModalStream: AlertModalStream = new BehaviorSubject<AlertModalStateSet>({
  type: ALERT_MODAL_STATE_SET,
  payload: initialAlertModalMessage,
  identity: BROADCAST_MESSAGE,
});
