import { DropZoneType } from '../../shared-types/drop-zone-type';
import { ContextAction } from '../../shared/types/ContextAction';
import { ContextReducer } from '../../shared/types/ContextReducer';

export const SET_IS_DRAG_ALLOWED = 'SET_IS_DRAG_ALLOWED';
export const SET_AVAILABLE_ZONES = 'SET_AVAILABLE_ZONES';
export const DRAG_FINISHED = 'DRAG_FINISHED';
export const SET_DROP_RESULT = 'SET_DROP_RESULT';
export const SET_SELECTED_DROP_ZONE = 'SET_SELECTED_DROP_ZONE';

export type DragFinishResult = {
  type: string;
  source: number;
  movedItemId: string;
  movedFrom: string;
  movedTo: string;
  destination: number;
};
export type DnDContextState = {
  isDragAllowed: boolean;
  availableZones: DropZoneType[];
  dropResult: DragFinishResult | null;
  selectedDropZone: string | null;
};

export type SetDraggedItemAction = ContextAction<typeof SET_IS_DRAG_ALLOWED, boolean>;
export type SetAvailableZonesAction = ContextAction<typeof SET_AVAILABLE_ZONES, DropZoneType[]>;
export type SetDragFinishedAction = ContextAction<typeof DRAG_FINISHED, null>;
export type SetDropResult = ContextAction<typeof SET_DROP_RESULT, DragFinishResult | null>;
export type SetSelectedDropZone = ContextAction<typeof SET_SELECTED_DROP_ZONE, string | null>;

export type DnDAction =
  | SetDraggedItemAction
  | SetAvailableZonesAction
  | SetDragFinishedAction
  | SetDropResult
  | SetSelectedDropZone;

export type DnDReducer = ContextReducer<DnDContextState, DnDAction>;
