import React, { FC, useContext, useEffect, useReducer } from 'react';
import { useCurrentRoute, useNavigation } from 'react-navi';

import { cmsDashboardRoute } from '../../../../routing/routes/cms';
import { useAuthActions } from './actions';
import { authInitialState, authReducer } from './reducer';
import { AuthState } from './types';

type AuthContext = {
  state: AuthState;
  signOut: () => void;
};

const AuthContext = React.createContext({} as AuthContext);

export const useAuthContext = () => useContext(AuthContext);
export const AuthContextProvider: FC = ({ children }) => {
  const [state, dispatch] = useReducer(authReducer, authInitialState);

  const { setAccessToken } = useAuthActions(state, dispatch);
  const { navigate } = useNavigation();
  const {
    url: { query },
  } = useCurrentRoute();

  useEffect(() => {
    if (query.access_token) {
      setAccessToken(query.access_token);
      navigate(cmsDashboardRoute.path);
    }
  }, [query.access_token, setAccessToken, navigate]);

  const signOut = () => {
    setAccessToken('');
  };

  return <AuthContext.Provider value={{ state, signOut }}>{children}</AuthContext.Provider>;
};
