import { useEffect, useMemo } from 'react';
import { useLazyQuery } from 'react-apollo';
import { useCurrentRoute } from 'react-navi';

import { Betshop } from '../../../../shared-types/graphql';
import { useConfigContext } from '../../../config/state/context';
import { GET_BETSHOP } from './queries';

type GetBetshop = {
  betshop: Betshop;
};

export const useGetBetshopGraphql = () => {
  const [getBetshop, { data }] = useLazyQuery<GetBetshop>(GET_BETSHOP, { fetchPolicy: 'network-only' });
  const { setBetshopConfig } = useConfigContext();
  const {
    lastChunk: { request },
  } = useCurrentRoute();

  const betshopId = useMemo(() => request && request.params.id, [request]);

  useEffect(() => {
    betshopId && getBetshop({ variables: { _id: betshopId } });
  }, [betshopId, getBetshop]);

  useEffect(() => {
    if (data && data.betshop) {
      setBetshopConfig(data.betshop);
    }
  }, [data, setBetshopConfig]);

  return { data };
};
