import { ActionWithIdentity } from '../../../../shared/types/ActionWithIdentity';
import { ContextAction } from '../../../../shared/types/ContextAction';
import { EventPresenterMarket } from '../../../../shared/types/widget-types/Markets';

export const SET_SELECTED_MARKETS = 'SET_SELECTED_MARKETS';
export const MARKETS_SELECTED = 'MARKETS_SELECTED';

export type SelectedMarkets = {
  [sportId: string]: EventPresenterMarket[];
};

export type SelectedMarketsState = {
  selectedMarkets: {
    live: SelectedMarkets;
    prematch: SelectedMarkets;
  };
};

export type SetSelectedMarkets = ContextAction<
  typeof SET_SELECTED_MARKETS,
  { isLive: boolean; selectedMarkets: SelectedMarkets }
>;

export type MarketsSelected = ActionWithIdentity<typeof MARKETS_SELECTED, SelectedMarketsState>;

export type SelectedMarketsReducer = (state: SelectedMarketsState, action: SetSelectedMarkets) => SelectedMarketsState;
