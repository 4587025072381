import { ActionWithIdentity } from '../../../../shared/types/ActionWithIdentity';

export const SHOW_REGISTRATION_MODAL = 'SHOW_REGISTRATION_MODAL';
export const HIDE_REGISTRATION_MODAL = 'HIDE_REGISTRATION_MODAL';
export const REGISTRATION_MODAL_STATE_SET = 'REGISTRATION_MODAL_STATE_SET';

export type RegistrationModalState = {
  isShown: boolean;
};

export type ShowRegistrationModal = ActionWithIdentity<typeof SHOW_REGISTRATION_MODAL, null>;
export type HideRegistrationModal = ActionWithIdentity<typeof HIDE_REGISTRATION_MODAL, null>;
export type RegistrationModalStateSet = ActionWithIdentity<typeof REGISTRATION_MODAL_STATE_SET, RegistrationModalState>;

type RegistrationModalAction = ShowRegistrationModal | HideRegistrationModal;

export type RegistrationModalReducer = (
  state: RegistrationModalState,
  action: RegistrationModalAction,
) => RegistrationModalState;
