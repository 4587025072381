import { ActionWithIdentity } from '../../../../shared/types/ActionWithIdentity';

export const MATCH_TRACKER_DATA_SET = 'MATCH_TRACKER_DATA_SET';
export const SET_MATCH_TRACKER_DATA = 'SET_MATCH_TRACKER_DATA';

export type MatchTrackerPayload = {
  eventId: string;
  hasLiveTracker: boolean;
};
export type MatchTrackerDataSet = ActionWithIdentity<typeof MATCH_TRACKER_DATA_SET, MatchTrackerPayload>;
export type SetMatchTrackerData = ActionWithIdentity<typeof SET_MATCH_TRACKER_DATA, MatchTrackerPayload>;

type MatchTrackerAction = SetMatchTrackerData;

export type MatchTrackerReducer = (state: MatchTrackerPayload, action: MatchTrackerAction) => MatchTrackerPayload;
